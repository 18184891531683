<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Botones </span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" [routerLink]="'crear'">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna nombre  -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre:</span>
        {{ row.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna variante  -->
    <ng-container matColumnDef="variante">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Variante
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Variante:</span>
        {{ row.variante }}
      </mat-cell>
    </ng-container>

    <!-- Columna funcion  -->
    <ng-container matColumnDef="funcion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Función
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Función:</span>
        {{ row.funcion }}
      </mat-cell>
    </ng-container>

    <!-- Columna texto  -->
    <ng-container matColumnDef="texto">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Texto
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Texto:</span>
        {{ row.texto }}
      </mat-cell>
    </ng-container>

    <!-- Columna color  -->
    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Color
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Color:</span>
        {{ row.color }}
      </mat-cell>
    </ng-container>

    <!-- Columna imagen -->
    <ng-container matColumnDef="imagen">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Imagen
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Imagen:</span>

        <img
          #img
          class="boton"
          *ngIf="row.urlImagen"
          [src]="row.urlImagen"
          alt="imagen"
          (mousedown)="img.src = row.urlImagenPush"
          (mouseup)="img.src = row.urlImagen"
          (mouseleave)="img.src = row.urlImagen"
        />

        <button
          mat-raised-button
          *ngIf="!row.urlImagen && row.texto"
          [style.background]="row.color"
          [style.color]="helper.invertColor(row.color)"
        >
          {{ row.texto }}
        </button>
      </mat-cell>
    </ng-container>

    <!-- Columna global  -->
    <ng-container matColumnDef="global">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Global
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Global:</span>
        <mat-icon *ngIf="row.global" [style.color]="'green'">check</mat-icon>
        <mat-icon *ngIf="!row.global" [style.color]="'red'">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna prioridad  -->
    <ng-container matColumnDef="prioridad">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Prioridad
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Global:</span>
        @if (row.prioridad) {
          {{ row.prioridad }}
        } @else {
          <span class="no-info">Sin Prioridad</span>
        }
      </mat-cell>
    </ng-container>

    <!-- Columna sirena  -->
    <ng-container matColumnDef="sirena">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Sirena
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Sirena:</span>
        <mat-icon *ngIf="row.sirena" [style.color]="'green'">check</mat-icon>
        <mat-icon *ngIf="!row.sirena" [style.color]="'red'">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna Seguimiento Automático -->
    <ng-container matColumnDef="seguimiento">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Seguimiento Automático </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Seguimiento:</span>
        <!-- Si tiene trackeo -->
        <div
          class="p-2"
          *ngIf="row.trackeo?.gps || row.trackeo?.audio || row.trackeo?.foto"
        >
          <!-- <p class="m-0">Tiempo: {{ row.trackeo.tiempoMinutos }} min.</p>
          <p class="m-0">Intervalo: {{ row.trackeo.intervaloSegundos }} seg.</p> -->
          <div class="segmento">
            <p class="m-0" *ngIf="row.trackeo?.gps">GPS</p>
            <p class="texto-chico" *ngIf="row.trackeo?.gpsIntervalo">
              Intervalo: {{ row.trackeo?.gpsIntervalo }} seg.
            </p>
            <p class="texto-chico" *ngIf="row.trackeo?.gpsTiempo">
              Duración: {{ row.trackeo?.gpsTiempo }} minutos.
            </p>
          </div>
          <div class="segmento">
            <p class="m-0" *ngIf="row.trackeo?.audio">Audio</p>
            <p class="texto-chico" *ngIf="row.trackeo?.audioTiempo">
              Duración: {{ row.trackeo?.audioTiempo }} minutos.
            </p>
          </div>
          <div class="segmento">
            <p class="m-0" *ngIf="row.trackeo?.foto">Foto</p>
            <p class="texto-chico" *ngIf="row.trackeo?.fotoCantidad">
              Cantidad: {{ row.trackeo?.fotoCantidad }}
            </p>
            <p class="texto-chico" *ngIf="row.trackeo?.fotoIntervalo">
              Intervalo: {{ row.trackeo?.fotoIntervalo }} seg.
            </p>
          </div>
        </div>
        <!-- Si no tiene trackeo -->
        <div
          *ngIf="!row.trackeo?.gps && !row.trackeo?.auido && !row.trackeo?.foto"
        >
          <mat-icon>clear</mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Seguimiento Manual -->
    <ng-container matColumnDef="seguimientoManual">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Seguimiento Manual </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Seguimiento:</span>
        <!-- Si tiene trackeo -->
        @if (
          row.envioMultimedia?.gps ||
          row.envioMultimedia?.audio ||
          row.envioMultimedia?.foto
        ) {
          <div class="p-2 segmento">
            <p class="m-0" *ngIf="row.envioMultimedia?.gps">GPS</p>
            <p class="m-0" *ngIf="row.envioMultimedia?.audio">Audio</p>
            <p class="m-0" *ngIf="row.envioMultimedia?.foto">Foto</p>
          </div>
        } @else {
          <div>
            <mat-icon>clear</mat-icon>
          </div>
        }
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <div>
          <button
            *ngIf="row.editable"
            class="mx-1 no-shadow"
            mat-mini-fab
            color="primary"
            matTooltip="Clonar"
            (click)="clonar(row)"
          >
            <mat-icon>content_copy</mat-icon>
          </button>

          <button
            class="mx-1 no-shadow"
            mat-mini-fab
            color="accent"
            matTooltip="Editar"
            [routerLink]="['editar', row._id]"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="row.editable"
            class="mx-1 no-shadow"
            mat-mini-fab
            color="warn"
            matTooltip="Eliminar"
            (click)="eliminar(row)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
