import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListarCategoriasComponent } from './listar-categorias/listar-categorias.component';
import { CategoriasRoutingModule } from './categorias.routing.module';
import { CrearEditarCategoriasComponent } from './crear-editar-categorias/crear-editar-categorias.component';

@NgModule({
  declarations: [
    ListarCategoriasComponent,
    CrearEditarCategoriasComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    CategoriasRoutingModule,
  ]
})
export class CategoriasModule { }
