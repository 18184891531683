<h2 mat-dialog-title class="modal-header">
  Actualización de firmware por cliente
</h2>

<mat-dialog-content>
  <form
    *ngIf="formulario"
    id="form"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Cliente</mat-label>
      <mat-select formControlName="idCliente" required>
        <mat-option *ngFor="let dato of clientes" [value]="dato._id">{{
          dato.nombre
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Firmware</mat-label>
      <mat-select formControlName="version" required>
        <mat-option *ngFor="let dato of firmwares" [value]="dato.version">{{
          dato.version
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button
    class="botonSpinner"
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!formulario?.valid || loading"
    form="form"
  >
    <div class="loadSpinnerButton" [style.display]="loading ? 'flex' : 'none'">
      <mat-spinner
        class="mx-2"
        [diameter]="25"
        mode="indeterminate"
        color="warn"
      ></mat-spinner>
    </div>
    <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
  </button>

  <button mat-raised-button type="button" (click)="close()">Volver</button>
</mat-dialog-actions>
