/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ICliente, ICoordenadas, IQueryParam, Rol } from 'modelos/src';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';
import { AuthService } from 'src/app/entidades/login/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSize = localStorage.getItem('pageSize') || 25;
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
  ) {}

  public rssiToText(rssi?: number) {
    if (rssi) {
      if (rssi >= -73) {
        return {
          label: 'Excelente',
          background: '#00FF00',
        };
      } else if (rssi >= -83) {
        return {
          label: 'Buena',
          background: '#FFFF00',
        };
      } else if (rssi >= -93) {
        return {
          label: 'Regular',
          background: '#FFA500',
        };
      } else {
        return {
          label: 'Mala',
          background: '#FF0000',
        };
      }
    }
    return {
      label: '',
      background: '#FFFFFF',
    };
  }

  public pageEvent($event: any): void {
    localStorage.setItem('pageSize', $event.pageSize);
    this.pageSize = $event.pageSize;
  }

  public pageEvent2(pageSize: number): void {
    localStorage.setItem('pageSize', pageSize.toString());
    this.pageSize = pageSize;
  }

  public getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  public stringToColour(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  static randomStringColor(s: string) {
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
      // tslint:disable-next-line: no-bitwise
      hash = s.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line: no-bitwise
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  //
  private padZero(str: string, len: number = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  public invertColor(hex?: string, bw: boolean = true) {
    if (!hex) {
      return '#000000';
    }
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    const r_str = (255 - r).toString(16);
    const g_str = (255 - g).toString(16);
    const b_str = (255 - b).toString(16);
    // pad each with zeros and return
    return (
      '#' + this.padZero(r_str) + this.padZero(g_str) + this.padZero(b_str)
    );
  }

  public static calcularEdad(fechaNacimiento: string): number {
    if (!fechaNacimiento) {
      return 0;
    }
    const fechaNacimientoMoment = moment(new Date(fechaNacimiento));
    const hoy = moment();

    return Math.trunc(hoy.diff(fechaNacimientoMoment, 'years'));
  }

  // Tiempo
  public msToTime(duration = 0) {
    const portions: string[] = [];

    const msInDay = 1000 * 60 * 60 * 24;
    const days = Math.trunc(duration / msInDay);
    if (days > 0) {
      portions.push(`${days}d`);
      duration = duration - days * msInDay;
    }

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
      portions.push(hours + 'h');
      duration = duration - hours * msInHour;
    }

    if (portions.length < 2) {
      const msInMinute = 1000 * 60;
      const minutes = Math.trunc(duration / msInMinute);
      if (minutes > 0) {
        portions.push(minutes + 'm');
        duration = duration - minutes * msInMinute;
      }
    }

    if (portions.length < 2) {
      const seconds = Math.trunc(duration / 1000);
      if (seconds > 0) {
        portions.push(seconds + 's');
      }
    }

    return portions.join(' ');
  }

  public uptime(tiempoOnline: number, tiempoOffline: number) {
    const uptime = {
      uptime: +((tiempoOnline * 100) / (tiempoOnline + tiempoOffline)).toFixed(
        2,
      ),
      background: '#00ff00', // verde
      color: '#000000', // negro
    };
    if (uptime.uptime < 50) {
      uptime.background = '#ff0000'; // rojo
    } else if (uptime.uptime < 75) {
      uptime.background = '#ffff00'; // amarillo
    }
    return uptime;
  }

  public tiempoTranscurrido(fecha: string): string {
    return moment(fecha).fromNow();
  }

  // Notif
  public async notifError(error: any): Promise<void> {
    if (typeof error === 'string') {
      this.snackBar.open(error, 'Cerrar', { panelClass: ['red-snackbar'] });
      // this.notificationsService.error(error);
    } else {
      const defaultMsg = 'Ha ocurrido un error inesperado';
      const mensaje =
        error?.error?.mensaje ||
        error?.error?.message ||
        error?.mensaje ||
        error?.message ||
        defaultMsg;
      console.error(error);
      this.snackBar.open(mensaje, 'Cerrar', {
        duration: 4000,
        panelClass: ['red-snackbar'],
      });
    }
  }
  public notifSuccess(mensaje: string): void {
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 4000,
      panelClass: ['green-snackbar'],
    });
    // this.notificationsService.success(mensaje);
  }
  public notifWarn(mensaje: string): void {
    // this.notificationsService.warn(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 4000,
      panelClass: ['yellow-snackbar'],
    });
  }

  // Mapa

  public getCentro(coordenadas?: ICoordenadas[]): ICoordenadas | undefined {
    if (coordenadas?.length) {
      const newPolygon: ICoordenadas[] = JSON.parse(
        JSON.stringify(coordenadas),
      );
      const longitudes = newPolygon.map((i) => i.lat);
      const latitudes = newPolygon.map((i) => i.lng);
      latitudes.sort((a, b) => a - b);
      longitudes.sort((a, b) => a - b);
      const lowX = latitudes[0];
      const highX = latitudes[latitudes.length - 1];
      const lowy = longitudes[0];
      const highy = longitudes[latitudes.length - 1];
      const centerX = lowX + (highX - lowX) / 2;
      const centerY = lowy + (highy - lowy) / 2;
      const center: ICoordenadas = {
        lng: centerX,
        lat: centerY,
      };
      return center;
    }
    return undefined;
  }

  // Ubicacion
  public async getCurrentPosition(): Promise<ICoordenadas> {
    return new Promise(async (resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion: ICoordenadas = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            const ubicacion: ICoordenadas = {
              lat: -35.5836812,
              lng: -58.0128784,
            };
            resolve(ubicacion);
          },
        );
      }
    });
  }

  public calcularAreas(polygonos: ICoordenadas[][]): number {
    if (polygonos?.length) {
      let area = 0;
      for (const poly of polygonos) {
        area += this.calcularArea(poly);
      }
      return +area.toFixed(2);
    }
    return 0;
  }

  public calcularArea(coordenadas: ICoordenadas[]): number {
    if (coordenadas?.length > 2) {
      const poly = new google.maps.Polygon({ paths: coordenadas });
      const area = google.maps.geometry.spherical.computeArea(poly.getPath());
      return +(area / 10000).toFixed(2);
    }
    return 0;
  }

  public chipIdValido(chipId?: string): boolean {
    function esHexadecimal(numeroHexadecimal: string): boolean {
      return /^[0-9A-F]+$/gi.test(numeroHexadecimal);
    }
    if (chipId) {
      if (esHexadecimal(chipId)) {
        if (chipId.length === 12) {
          return true;
        }
      }
    }
    return false;
  }

  public getTipoSirenas() {
    const cliente = this.authService.getCliente() as ICliente;
    return cliente.configuracion?.tiposDeSirena;
  }

  // Permisos

  public tieneAlgunRol(roles: Rol[]) {
    const usuario = this.authService.getUsuario();
    if (usuario) {
      return roles.some((rol) => usuario.roles?.includes(rol));
    }
    return false;
  }

  //

  public puedeListarClientes() {
    return this.tieneAlgunRol(['admin', 'Administrador de sirenas']);
  }
}
