import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrearEditarBotonComponent } from './crear-editar-boton/crear-editar-boton.component';
import { ListarBotonesComponent } from './listar-botones/listar-botones.component';

const routes: Routes = [
  { path: '', component: ListarBotonesComponent },
  { path: 'crear', component: CrearEditarBotonComponent, canActivate: [] },
  { path: 'editar/:id', component: CrearEditarBotonComponent, canActivate: [] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BotonesRoutingModule {}
