import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { SirenasRoutingModule } from './sirenas.routing.module';
import { EditarSirenasComponent } from './editar-sirenas/editar-sirenas.component';
import { SirenasComponent } from './sirenas/sirenas.component';
import { ListadoSirenasComponent } from './listado-sirenas/listado-sirenas.component';
import { ListadoFirmwaresComponent } from './listado-firmwares/listado-firmwares.component';
import { ListadoFirmwaresClienteComponent } from './listado-firmwares-cliente/listado-firmwares-cliente.component';
import { CrearFirmwareComponent } from './crear-firmware/crear-firmware.component';
import { CrearFirmwareClienteComponent } from './crear-firmware-cliente/crear-firmware-cliente.component';
import { ListadoSimsComponent } from './listado-sims/listado-sims.component';
import { EditarSimsComponent } from './editar-sims/editar-sims.component';
import { ListadoFirmwaresVersionComponent } from './listado-firmwares-version/listado-firmwares-version.component';
import { CrearFirmwareVersionComponent } from './crear-firmware-version/crear-firmware-version.component';
import { DetallesSirenaComponent } from './detalles-sirena/detalles-sirena.component';
import { ListadoEventosComponent } from './listado-eventos/listado-eventos.component';
import { ListadoLogsComponent } from './listado-logs/listado-logs.component';
import { ImportarSirenasComponent } from './importar-sirenas/importar-sirenas.component';
import { ListadoActualizacionFirmComponent } from './listado-actualizacion-firm/listado-actualizacion-firm.component';
import { GraficosSirenasComponent } from './graficos-sirenas/graficos-sirenas.component';
import { ListadosMantenimientosComponent } from './listado-mantenimientos/mantenimientos.component';
import { ImportarChinasComponent } from './importar-chinas/importar-chinas.component';

@NgModule({
  declarations: [
    SirenasComponent,
    EditarSirenasComponent,
    ListadoSirenasComponent,
    ListadoFirmwaresComponent,
    ListadoFirmwaresClienteComponent,
    CrearFirmwareComponent,
    CrearFirmwareClienteComponent,
    ListadoSimsComponent,
    EditarSimsComponent,
    ListadoFirmwaresVersionComponent,
    CrearFirmwareVersionComponent,
    DetallesSirenaComponent,
    ListadoEventosComponent,
    ListadoLogsComponent,
    ImportarSirenasComponent,
    ListadoActualizacionFirmComponent,
    GraficosSirenasComponent,
    ListadosMantenimientosComponent,
    ImportarChinasComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, SirenasRoutingModule],
})
export class SirenasModule {}
