<div class="center-box">
  <div class="main" *ngIf="!loading">
    <mat-accordion style="width: 75%">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title> Resumen de Entidades </mat-panel-title>
        </mat-expansion-panel-header>
        <h1>Exportar Entidades</h1>
        <mat-form-field>
          <mat-label>Cliente</mat-label>
          <mat-select [(value)]="cliente">
            <mat-option *ngFor="let c of clientes" [value]="c._id">
              {{ c.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Tipo</mat-label>
          <mat-select [(value)]="tipo">
            <mat-option
              *ngFor="let t of tipos"
              [value]="t"
              [disabled]="t === 'Todos'"
            >
              {{ t }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Mes -->
        <div style="width: 100%">
          <app-monthSelect
            (onChange)="this.fecha = $event"
            [mostrar]="true"
          ></app-monthSelect>
        </div>

        <div class="endRow">
          <button mat-flat-button color="primary" (click)="exportar()">
            Exportar
          </button>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Resumen de Clientes </mat-panel-title>
        </mat-expansion-panel-header>
        <h1>Resumen de Clientes</h1>
        <div class="endRow">
          <button mat-flat-button color="primary" (click)="exportarResumen()">
            Exportar
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
</div>
