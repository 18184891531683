import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;

  constructor(
    public matDialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
  }

  ngOnInit() {}
}
