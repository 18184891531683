import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { TextareaComponent } from './textarea/textarea.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [TextareaComponent],
    imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
    providers: [],
    exports: [TextareaComponent]
})
export class TextareaModule {}
