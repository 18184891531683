<ng-container *ngIf="config">
  <div class="lista">
    <div class="titulo-lista">
      {{ config.cliente?.nombre || config.idCliente | titlecase }}
    </div>
    <div class="item-lista">
      <p>Dirección</p>
      <p
        *ngIf="config.direccion?.direccion"
        [matTooltip]="config.direccion?.direccion!"
        (click)="copiar(config.direccion?.direccion || '')"
      >
        🗺️
      </p>
      <p *ngIf="!config.direccion?.direccion" class="no-info">Nada</p>
    </div>
    <div class="item-lista">
      <p>Detalle Dirección</p>
      <p
        *ngIf="config.direccion?.complementoDireccion"
        [matTooltip]="config.direccion?.complementoDireccion!"
      >
        🔍
      </p>
      <p *ngIf="!config.direccion?.complementoDireccion" class="no-info">
        Nada
      </p>
    </div>
    <div class="item-lista">
      <p>Último Acceso</p>
      <p *ngIf="config.ultimoAcceso">
        {{ config.ultimoAcceso | date: "shortDate" }}
      </p>
      <p *ngIf="!config.ultimoAcceso" class="no-info">Nada</p>
    </div>
    <div class="item-lista">
      <p>Categoría</p>
      <p *ngIf="config.categoria?.categoria?.nombre">
        {{ config.categoria?.categoria?.nombre | titlecase }}
      </p>
      <p *ngIf="!config.categoria?.categoria?.nombre" class="no-info">Nada</p>
    </div>
    <div class="item-lista">
      <p>APP</p>
      <mat-icon
        *ngIf="config.appType === 'android'"
        style="color: green"
        [matTooltip]="'Android ' + config.appVersion"
      >
        android
      </mat-icon>

      <mat-icon
        *ngIf="config.appType === 'ios'"
        style="color: #bf37ff"
        [matTooltip]="'iOS ' + config.appVersion"
      >
        phone_iphone
      </mat-icon>

      <mat-icon
        *ngIf="config.appType === 'web'"
        style="color: orange"
        [matTooltip]="'WEB ' + config.appVersion"
      >
        public
      </mat-icon>

      <mat-icon
        *ngIf="config.appType === 'rf'"
        style="color: #3e3eff"
        [matTooltip]="'Control RF'"
      >
        radio
      </mat-icon>
    </div>
    <div class="item-lista">
      <p>App Activada</p>
      <p *ngIf="config.ultimoAcceso">
        <mat-icon
          *ngIf="config.ultimoAcceso"
          style="color: green"
          [matTooltip]="
            'Último Acceso: ' + (config.ultimoAcceso | date: 'short')
          "
        >
          done
        </mat-icon>
        <mat-icon *ngIf="!config.ultimoAcceso" style="color: red"
          >close</mat-icon
        >
      </p>
    </div>
    <div class="item-lista">
      <p>Notificaciones</p>
      <mat-icon
        *ngIf="config.tokenPush"
        style="color: green"
        [matTooltip]="config.tokenPush"
      >
        done
      </mat-icon>
      <mat-icon *ngIf="!config.tokenPush" style="color: red">close</mat-icon>
    </div>
    <div class="item-lista">
      <p>Código de Acceso</p>
      <p *ngIf="config.envioCodigo?.codigo" class="swiper-no-swiping">
        {{ config.envioCodigo?.codigo }}
      </p>
      <p *ngIf="!config.envioCodigo?.codigo" class="no-info">Nada</p>
    </div>
  </div>
</ng-container>
