import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ChartModule } from './chart/chart.module';
import { ColumnSelectModule } from './column-select/column-select.module';
import { DialogModule } from './dialog/dialog.module';
import { FiltroTablaModule } from './filtro-tabla/filtro-tabla.module';
import { MaterialModule } from './material.module';
import { PolygonDrawModule } from './polygon-draw/polygon-draw.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChangelogModule } from './changelog/changelog.module';
import { TextareaModule } from './textarea/module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MarkerLocationModule } from './marker-location/marker-location.module';
import { UploadImageModule } from './upload-image/upload-image.module';
import { AutocompleteDireccionModule } from './autocomplete-direccion/autocomplete-direccion.module';
import { NgxColorsModule } from 'ngx-colors';
import { provideHttpClient } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, SimpleNotificationsModule.forRoot()],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    FlexLayoutModule,
    GoogleMapsModule,
    SimpleNotificationsModule,
    NgSelectModule,
    TooltipModule,
    //
    MaterialModule,
    ColumnSelectModule,
    DialogModule,
    FiltroTablaModule,
    PolygonDrawModule,
    ChartModule,
    ChangelogModule,
    TextareaModule,
    MarkerLocationModule,
    UploadImageModule,
    AutocompleteDireccionModule,
    NgxColorsModule,
  ],
  providers: [provideHttpClient()],
})
export class AuxiliaresModule {}
