import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICliente,
  ICreateMantenimiento,
  IMantenimiento,
  IQueryParam,
  ISirena,
  IUpdateCliente,
  IUpdateMantenimiento,
  IUsuario,
} from 'modelos/src';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ClientesService } from '../../clientes/clientes.service';
import { MantenimientosService } from '../mantenimientos.service';
import { AuthService } from '../../login/auth.service';
import { SirenasService } from '../../sirenas/sirenas.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-crear-editar-mantenimiento',
  templateUrl: './crear-editar-mantenimiento.component.html',
  styleUrls: ['./crear-editar-mantenimiento.component.scss'],
})
export class CrearEditarMantenimientoComponent implements OnInit {
  public formulario?: UntypedFormGroup;
  public loading = true;
  public title?: string;
  public hide = true;

  public tags: string[] = [];
  public cliente?: ICliente;
  public usuario?: IUsuario;
  public sirena?: ISirena;

  public clientes: ICliente[] = [];

  public urlFoto?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IMantenimiento & { idSirenaSeleccionada: string },
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarMantenimientoComponent>,
    private helper: HelperService,
    private clientesService: ClientesService,
    private sirenasService: SirenasService,
    private auth: AuthService,
    private service: MantenimientosService,
  ) {}

  private crearFormulario(): void {
    this.title = this.data?._id
      ? 'Editar Mantenimiento'
      : 'Crear Mantenimiento';
    this.formulario = this.fb.group({
      fecha: [this.data?.fecha || new Date()],
      tags: [this.data?.tags],
      descripcion: [this.data?.descripcion],
      urlFoto: [this.data?.urlFoto],
      chipId: [this.data?.chipId],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getData();
        await this.service
          .update(this.data._id, data)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getData();
        await this.service.create(data).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.loading = false;
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
      this.loading = false;
    }
    this.loading = false;
  }

  private getData() {
    const data: ICreateMantenimiento | IUpdateMantenimiento =
      this.formulario?.value;
    data.idCliente = this.sirena?.idCliente;
    data.chipId = this.sirena?.chipId;
    data.urlFoto = this.urlFoto;
    return data;
  }

  private async listarClientes() {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };
    this.clientes = (
      await firstValueFrom(this.clientesService.listar(query))
    ).datos;
    console.log('Listado de clientes', this.clientes);
  }

  private async listarSirena(): Promise<void> {
    this.sirena = await this.sirenasService
      .listarPorId(this.data?.idSirenaSeleccionada)
      .pipe(take(1))
      .toPromise();
  }

  public clienteValue() {
    const cliente = this.clientes.find(
      (cliente) => cliente._id === this.sirena?.idCliente,
    );
    return cliente?.nombre;
  }

  public async agregarTag(tag: string) {
    if (this.tags.includes(tag)) return;

    this.loading = true;

    this.tags.push(tag);
    const update: IUpdateCliente = {
      configuracion: {
        tagsMantenimiento: this.tags,
      },
    };
    const cliente = await this.clientesService
      .editar(this.cliente?._id!, update)
      .pipe(take(1))
      .toPromise();

    this.auth.setCliente(cliente);

    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    this.usuario = this.auth.getUsuario();
    this.cliente = this.auth.getCliente();

    if (this.cliente?.configuracion?.tagsMantenimiento) {
      const tagsCliente = this.cliente?.configuracion?.tagsMantenimiento;
      tagsCliente.forEach((tag) => {
        this.tags.push(tag);
      });
    }
    Promise.all([this.listarSirena(), this.listarClientes()]);
    this.crearFormulario();

    this.loading = false;
  }
}
