import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { RolesGuard } from '../../auxiliares/rol.guard';
import { ActualizacionesFirmModule } from '../actualizaciones-firm/actualizaciones-fim.module';
import { BotonesModule } from '../botones/botones.module';
import { CategoriasModule } from '../categorias/categorias.module';
import { ClientesModule } from '../clientes/clientes.module';
import { ControlesModule } from '../controles/controles.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { LandingModule } from '../landing/landing.module';
import { LoginGuard } from '../login/login.guard';
import { MapaModule } from '../mapa/mapa.module';
import { MensajesModule } from '../mensajes/mensajes.module';
import { SirenasModule } from '../sirenas/sirenas.module';
import { UsuariosModule } from '../usuarios/usuarios.module';
import { VecinosModule } from '../vecinos/vecinos.module';
import { MenuComponent } from './menu/menu.component';
import { ApiKeysModule } from '../apikeys/apikeys.module';
import { ExportarModule } from 'src/app/auxiliares/exportar/exportar.module';
import { MantenimientosModule } from '../mantenimientos/mantenimientos.module';
import { Rol } from 'modelos/src';
import { PorticosModule } from '../porticos/porticos.module';

type MyRoutes = Route & { children?: MyRoutes[]; data?: { roles?: Rol[] } };

const routes: MyRoutes[] = [
  {
    path: '',
    component: MenuComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard],
    children: [
      { path: '', redirectTo: 'landing', pathMatch: 'full' },
      {
        path: 'landing',
        loadChildren: () => LandingModule,
      },
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'botones',
        loadChildren: () => BotonesModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'categorias',
        loadChildren: () => CategoriasModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'clientes',
        loadChildren: () => ClientesModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'usuarios',
        loadChildren: () => UsuariosModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'vecinos',
        loadChildren: () => VecinosModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'sirenas',
        loadChildren: () => SirenasModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin', 'Administrador de sirenas'] },
      },
      {
        path: 'actualizacionfirmwares',
        loadChildren: () => ActualizacionesFirmModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'controles',
        loadChildren: () => ControlesModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'mapa',
        loadChildren: () => MapaModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'mensajes',
        loadChildren: () => MensajesModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'apikeys',
        loadChildren: () => ApiKeysModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'exportar',
        loadChildren: () => ExportarModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'mantenimiento',
        loadChildren: () => MantenimientosModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'porticos',
        loadChildren: () => PorticosModule,
        canActivate: [RolesGuard],
        canActivateChild: [RolesGuard],
        data: { roles: ['admin'] },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MenuRoutingModule { }
