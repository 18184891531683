<div class="table-container mat-elevation-z2 mx-1 mt-4">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[baneado, vecino, cliente]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna etiqueta  -->
    <ng-container matColumnDef="etiqueta">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Etiqueta
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Etiqueta:</span>
        {{ row.etiqueta }}
      </mat-cell>
    </ng-container>

    <!-- Columna chipId  -->
    <ng-container matColumnDef="chipId">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">ID:</span>
        {{ row.chipId }}
      </mat-cell>
    </ng-container>

    <!-- Columna baneado  -->
    <ng-container matColumnDef="baneado">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Baneado
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Baneado:</span>

        <mat-icon [style.color]="row.baneado ? 'red' : 'green'">
          {{ row.baneado ? "check_circle" : "cancel" }}
        </mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna fechaCreacion  -->
    <ng-container matColumnDef="fechaCreacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha Creación
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha Creación:</span>
        {{ row.fechaCreacion | date: "shortDate" }}
      </mat-cell>
    </ng-container>

    <!-- Columna vecino  -->
    <!-- <ng-container matColumnDef="vecino">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Vecino
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Vecino:</span>
        {{ row.vecino?.nombre }}
      </mat-cell>
    </ng-container> -->

    <!-- Columna config vecino  -->
    <ng-container matColumnDef="idConfigVecino">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Vecino
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Vecino:</span>
        {{ row.configVecino?.datosPersonales?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna cliente  -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        {{ row.cliente?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Acciones:</span>
        <button
          class="mx-1 no-shadow"
          mat-mini-fab
          color="accent"
          matTooltip="Editar"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          class="mx-1 no-shadow"
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
