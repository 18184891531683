/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import {
  IFilter,
  ISirena,
  IQueryParam,
  IListado,
  IUsuario,
  ICliente,
} from 'modelos/src';
import { OpenLayersService } from 'src/app/auxiliares/servicios/open-layers.service';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';
import { Subscription } from 'rxjs';
import { Feature, Map, View } from 'ol';
import { Geometry, Point, Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { AuthService } from '../../login/auth.service';
import { Coordinate } from 'ol/coordinate';

@Component({
  selector: 'app-mapa-ol',
  templateUrl: './mapa-ol.component.html',
  styleUrl: './mapa-ol.component.scss',
})
export class MapaOlComponent implements OnInit, OnDestroy {
  public loading = signal(true);
  //Sirenas
  public sirenas: ISirena[] = [];
  public sirena?: (ISirena & { estado: string }) | null;
  public sirenas$?: Subscription;
  public estado?: boolean;
  //Clientes
  public clientes: ICliente[] = [];

  private usuario?: IUsuario;

  //OpenLayers
  public map?: Map;
  private sirenasLayer = OpenLayersService.sirenasVectorLayer();
  private clientesLayer = OpenLayersService.polygonsVectorLayer();
  private pinsLayer = OpenLayersService.pinsVectorlayer();

  constructor(
    private listadosService: ListadosService,
    private authService: AuthService,
  ) {}

  private async listarSirenas(): Promise<void> {
    const filter: IFilter<ISirena> = {
      geojson: {
        $exists: true,
      },
    };
    if (this.clientes?.length > 0) {
      filter.idCliente = {
        $in: this.clientes.map((c) => c._id),
      } as any;
    }

    if (this.estado !== undefined) {
      filter.online = this.estado;
    }

    const queryParams: IQueryParam = {
      select:
        'chipId actualizando online sonidoEncendido luzEncendida ubicacionGps rssi fechaOnline fechaOffline versionFirmware tipo geojson idCliente',
      filter: JSON.stringify(filter),
    };
    // Query
    this.sirenas$?.unsubscribe();
    this.sirenas$ = this.listadosService
      .subscribe<IListado<ISirena>>('sirenas', queryParams)
      .subscribe((data) => {
        this.sirenas = data.datos;
        this.addSirenas();
      });
    await this.listadosService.getLastValue('sirenas', queryParams);
  }

  public estadoSirena(sirena: ISirena): string {
    if (sirena.online) {
      if (sirena.sonidoEncendido && sirena.luzEncendida) {
        return 'Online - Sirena y Reflector Encendidos';
      }
      if (sirena.sonidoEncendido) {
        return 'Online - Sirena Encendida';
      }
      if (sirena.luzEncendida) {
        return 'Online - Reflector Encendido';
      }
      return 'Online - Apagada';
    } else {
      if (sirena.actualizando) {
        return 'Offline - Actualizando Firmware';
      } else {
        return 'Offline';
      }
    }
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [
        OpenLayersService.mapTile(),
        this.clientesLayer,
        this.sirenasLayer,
        this.pinsLayer,
      ],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    this.handleClick();
  }

  private addSirenas() {
    // Sirenas
    const source = this.sirenasLayer.getSource();
    if (!source) return;
    source.clear();
    if (!this.sirenas?.length) return;
    // Agrego las sirenas dependiendo el estado
    for (const s of this.sirenas) {
      if (!s.geojson?.coordinates) continue;
      const coord = OpenLayersService.lonLatToCoordinate(
        s.geojson?.coordinates[0],
        s.geojson?.coordinates[1],
      );
      const estado = this.estadoSirena(s);
      const feature: Feature<Geometry> = new Feature({
        geometry: new Point(coord),
        data: s,
        id: s._id,
        estado,
        tipo: 'sirena',
      });

      // Sonando
      if (
        estado === 'Online - Sirena y Reflector Encendidos' ||
        estado === 'Online - Sirena Encendida' ||
        estado === 'Online - Reflector Encendido'
      ) {
        feature.setStyle(OpenLayersService.sirenaStyleAzul());
      }
      // Apagada
      if (estado === 'Online - Apagada') {
        feature.setStyle(OpenLayersService.sirenaStyleVerde());
      }
      // Actualizando
      if (estado === 'Offline - Actualizando Firmware') {
        if (this.usuario?.cliente?.configuracion?.verActualizaciones) {
          feature.setStyle(OpenLayersService.sirenaStyleAmarilla());
        } else {
          feature.setStyle(OpenLayersService.sirenaStyleVerde());
        }
      }
      // Offline
      if (estado === 'Offline') {
        feature.setStyle(OpenLayersService.sirenaStyleRoja());
      }

      source.addFeature(feature);
    }
  }

  private async addClientes() {
    // Polygons
    const source = this.clientesLayer.getSource();
    if (!source) return;
    source.clear();
    if (!this.clientes?.length) return;
    // Agrego los clientes
    for (const c of this.clientes) {
      if (!c.coordenadas?.[0]) continue;
      const coords = OpenLayersService.coordenadasToCoordinates(
        c.coordenadas[0],
      );
      const feature: Feature<Geometry> = new Feature({
        geometry: new Polygon([coords]),
        data: c,
        id: c._id,
      });
      source.addFeature(feature);
    }
    this.setBounds(this.clientesLayer.getSource());
  }

  public async addMarker(c: Coordinate) {
    const source = this.pinsLayer.getSource();
    if (!source) return;
    source.clear();
    const feature: Feature<Geometry> = new Feature({
      geometry: new Point(c),
    });
    source.addFeature(feature);
    this.panTo(c);
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
  }

  private panTo(coordinate: Coordinate) {
    if (!this.map) return;
    this.map.getView().animate({
      center: coordinate,
      duration: 1000,
      zoom: 11,
    });
  }

  public async clientesChange(clientes: ICliente[]) {
    this.clientes = clientes;
    this.addClientes();
    await this.listar();
  }

  public estadoChange(estado: boolean) {
    this.estado = estado;
    this.listar();
  }

  public mostrarClientesChange(e: boolean) {
    this.clientesLayer.setVisible(e);
  }

  public mostrarSirenasChange(e: boolean) {
    this.sirenasLayer.setVisible(e);
  }

  private handleClick() {
    if (!this.map) return;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'sirena') {
              return feature;
            }
          }
          return null;
        },
      );

      if (!feature) return;
      const data = feature.get('data') as ISirena;
      this.sirena = { ...data, estado: feature.get('estado') };
    });
  }

  public cerrar(b: boolean) {
    if (b) {
      this.sirena = null;
    }
  }

  private async listar() {
    this.loading.set(true);
    await Promise.all([this.listarSirenas()]);
    this.loading.set(false);
  }
  ///// HOOKS
  async ngOnInit(): Promise<void> {
    await this.initMap();
    this.usuario = this.authService.getUsuario();
    await this.listar();
  }

  ngOnDestroy(): void {
    this.sirenas$?.unsubscribe();
  }
}
