import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICrearSirena } from 'modelos/src';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { SirenasService } from '../sirenas.service';

@Component({
  selector: 'app-importar-sirenas',
  templateUrl: './importar-sirenas.component.html',
  styleUrls: ['./importar-sirenas.component.scss'],
})
export class ImportarSirenasComponent implements OnInit {
  loading = false;

  public textArea = '';

  constructor(
    private service: SirenasService,
    public helper: HelperService,
    private route: ActivatedRoute
  ) {}

  public volver(): void {
    window.history.back();
  }

  //

  private getData(): ICrearSirena[] {
    const lineas = this.parseTextarea(this.textArea);
    const data: ICrearSirena[] = [];
    if (lineas?.length) {
      for (const linea of lineas) {
        const parseada = this.parseLinea(linea);
        if (parseada) {
          const valido = this.validarLinea(parseada);
          if (valido) {
            data.push(valido);
          }
        }
      }
    }
    return data;
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      await this.service.bulkCrear(data).pipe(take(1)).toPromise();
      this.helper.notifSuccess('Creadas correctamente');
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  private parseTextarea(input: string): string[] | undefined {
    input = input.trim();
    const returnArray: string[] = [];
    if (input) {
      let inputArray: string[] = input.split('\n');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim
      );
      for (const linea of inputArray) {
        if (linea) {
          returnArray.push(linea);
        }
      }
      return returnArray;
    }
    return;
  }

  private parseLinea(
    input: string
  ): [string, string, string, string, string] | undefined {
    input = input.trim();
    if (input) {
      let inputArray: string[] = input.split(',');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim
      );
      return inputArray as any;
    }
    return;
  }

  public formularioValido(): boolean {
    const lineas = this.parseTextarea(this.textArea);
    if (lineas?.length) {
      for (const linea of lineas) {
        const parseada = this.parseLinea(linea);
        if (parseada) {
          const valido = this.validarLinea(parseada);
          if (!valido) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private validarLinea(
    linea: [string, string, string, string, string]
  ): false | ICrearSirena {
    const numeroNac = linea[0];
    const chipId = linea[1];
    const fechaDeFabricacion = linea[2];
    const version = linea[3];
    const fuenteExterna = linea[4];
    if (!numeroNac) {
      return false;
    }
    if (!this.helper.chipIdValido(chipId)) {
      return false;
    }
    if (!moment(fechaDeFabricacion, 'DD/MM/YYYY', true).isValid()) {
      return false;
    }
    if (!version) {
      return false;
    }
    if (
      fuenteExterna?.toLowerCase() !== 'si' &&
      fuenteExterna?.toLocaleLowerCase() !== 'no'
    ) {
      return false;
    }
    const fuenteExternaBoolean =
      fuenteExterna.toLocaleLowerCase() === 'si' ? true : false;

    const sirena: ICrearSirena = {
      chipId,
      datosHw: {
        numeroNac,
        fechaDeFabricacion,
        version,
        fuenteExterna: fuenteExternaBoolean,
      },
    };
    return sirena;
  }

  public errorFormulario(): string {
    return '';
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.loading = false;
  }
}
