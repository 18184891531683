import { Injectable } from '@angular/core';
import { HttpApiAdminService } from '../servicios/http-api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ExportarService {
  constructor(private http: HttpApiAdminService) {}

  public async vecinos(fecha: string, idCliente: string) {
    let params = new HttpParams();
    params = params.set('fecha', fecha);
    params = params.set('idCliente', idCliente);

    return await this.http.getFile(
      `/exportaciones/vecinos`,
      'vecinos.xlsx',
      params
    );
  }

  public async eventos(fecha: string, idCliente: string) {
    let params = new HttpParams();
    params = params.set('fecha', fecha);
    params = params.set('idCliente', idCliente);

    return await this.http.getFile(
      `/exportaciones/eventos`,
      'eventos.xlsx',
      params
    );
  }

  public async sirenas(fecha: string, idCliente: string) {
    let params = new HttpParams();
    params = params.set('fecha', fecha);
    params = params.set('idCliente', idCliente);

    return await this.http.getFile(
      `/exportaciones/sirenas`,
      'sirenas.xlsx',
      params
    );
  }

  public async mantenimientos(fecha: string, idCliente: string) {
    let params = new HttpParams();
    params = params.set('fecha', fecha);
    params = params.set('idCliente', idCliente);

    return await this.http.getFile(
      `/exportaciones/mantenimientos`,
      'mantenimientos.xlsx',
      params
    );
  }

  public async resumen() {
    return await this.http.getFile(
      `/exportaciones/resumenClientes`,
      'resumen.xlsx'
    );
  }
}
