<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Usuarios </span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[estado, roles, clientes]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna telefono  -->
    <ng-container matColumnDef="telefono">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Teléfono </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Teléfono:</span>
        {{ row.telefono }}
      </mat-cell>
    </ng-container>

    <!-- Columna nombre  -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre:</span>
        {{ row.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna usuario  -->
    <ng-container matColumnDef="usuario">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Usuario </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Usuario:</span>
        {{ row.usuario }}
      </mat-cell>
    </ng-container>

    <!-- Columna email  -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Email </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Email:</span>
        {{ row.email }}
      </mat-cell>
    </ng-container>

    <!-- Columna cliente.nombre  -->
    <ng-container matColumnDef="cliente.nombre">
      <mat-header-cell *matHeaderCellDef> Cliente </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        {{ row.cliente?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna roles  -->
    <ng-container matColumnDef="roles">
      <mat-header-cell *matHeaderCellDef> Roles </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Roles:</span>
        <div>
          <ng-container *ngFor="let rol of row.roles">
            <p class="m-0">{{ rol | titlecase }}</p>
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row" class="nowrap">
        <span class="mobile-label">Acciones:</span>
        <button
          mat-mini-fab
          color="accent"
          matTooltip="Editar"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="row.activo && row._id !== usuarioActual?._id"
          class="mx-1"
          mat-mini-fab
          matTooltip="Desactivar"
          color="warn"
          (click)="desactivar(row)"
        >
          <mat-icon>cancel_schedule_send</mat-icon>
        </button>
        <button
          *ngIf="!row.activo && row._id !== usuarioActual?._id"
          class="mx-1"
          mat-mini-fab
          matTooltip="Activar"
          color="primary"
          (click)="activar(row)"
        >
          <mat-icon>send</mat-icon>
        </button>
        <button
          *ngIf="row._id !== usuarioActual?._id"
          class="mx-1"
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
