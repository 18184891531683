import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapaComponent } from './mapa/mapa.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { MapaRoutingModule } from './mapa.routing.module';
import { MapaOlComponent } from './mapa-ol/mapa-ol.component';
import { FlotanteComponent } from './flotante/flotante.component';
import { IslaComponent } from './isla/isla.component';

@NgModule({
  declarations: [
    MapaComponent,
    MapaOlComponent,
    FlotanteComponent,
    IslaComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, MapaRoutingModule],
})
export class MapaModule {}
