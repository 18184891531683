import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VERSION } from 'src/environments/environment';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
  public noMostrar = true;

  constructor(public dialogRef: MatDialogRef<ChangelogComponent>) {}

  ngOnInit(): void {}

  public cerrar() {
    if (this.noMostrar) {
      localStorage.setItem(`changelog-${VERSION}`, '1');
    }
    this.dialogRef.close();
  }
}
