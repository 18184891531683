<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()">Sirenas</span>
    > Importar Sirenas
  </span>
</h2>

<div class="table-container mat-elevation-z8 p-2">
  <form id="form" (ngSubmit)="enviar()" autocomplete="off">
    <mat-form-field>
      <mat-label
        >Número NAC, chipId, fecha de fabricación, versión hw, fuente
        externa</mat-label
      >
      <textarea
        matInput
        [(ngModel)]="textArea"
        placeholder="Número NAC, chipId, fecha de fabricación, versión hw, fuente externa"
        rows="10"
        required
      ></textarea>
      <mat-hint> NAC-0000, 58BF25EFD7D8, 1/11/2222, v3, NO </mat-hint>
      <mat-error *ngIf="!formularioValido()">
        {{ errorFormulario() }}
      </mat-error>
    </mat-form-field>
  </form>

  <div fxLayoutGap="10px" style="text-align: end">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formularioValido() || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="volver()">Volver</button>
  </div>
</div>
