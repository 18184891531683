import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IFilter,
  IListado,
  IQueryParam,
  ISim,
  ISirena,
  ISocketMessage,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { EditarSimsComponent } from '../editar-sims/editar-sims.component';
import { SimsService } from '../sims.service';
import { SirenasService } from '../sirenas.service';

declare type mainType = ISim;

@Component({
  selector: 'app-listado-sims',
  templateUrl: './listado-sims.component.html',
  styleUrls: ['./listado-sims.component.scss'],
})
export class ListadoSimsComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['iccid', 'numero'],
  };

  @Input() loading?: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public columnas = [
    'iccid',
    'imsi',
    'chipId',
    'operador',
    'apn',
    'numero',
    'acciones',
  ];
  public nombreColumnas = [
    'ICC ID',
    'IMSI',
    'Chip ID',
    'Operador',
    'Apn',
    'Número',
    'Acciones',
  ];
  public saveColumnas = 'tabla-sims';
  public dataSource = new MatTableDataSource<mainType>([]);
  public sirenas?: ISirena[];
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'numero',
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: SimsService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  private onLoadingChange(loading: boolean): void {
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  private actualizarTabla(datos: mainType[]): void {
    this.dataSource.data = datos;
  }

  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }

  public async actualizar(): Promise<void> {
    this.onLoadingChange(true);
    await this.listar();
    await this.listarSirenas();
    this.onLoadingChange(false);
  }

  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  public async cambioFiltro(filters: IFilter<any>): Promise<void> {
    this.onLoadingChange(true);
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.onLoadingChange(false);
  }

  // ACCIONES
  public async editar(data: mainType): Promise<void> {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(EditarSimsComponent, config);
  }

  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el firmware?`
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }

  public async exportar(): Promise<void> {
    const mensaje = `¿Desea exportar las ${this.totalCount} sims ?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje
    );
    if (confirm) {
      try {
        this.loading = true;
        this.queryParams.limit = 0;
        delete this.queryParams.page;
        await this.service.exportar(this.queryParams);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  // Listar
  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ISim>>('sims', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(new Date().toLocaleString(), `listado de sims`, data);
      });
    await this.listadosService.getLastValue('sims', this.queryParams);
  }

  private async listarSirenas(): Promise<void> {
    const queryParams: IQueryParam = {
      page: 0,
      limit: 0,
    };
    const simIds = this.dataSource.data.map((s) => {
      return s.iccid!;
    });
    const filtro: IFilter<ISirena> = { iccidSim: { $in: simIds } };

    queryParams.filter = JSON.stringify(filtro);
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ISirena>>('sirenas', queryParams)
      .subscribe((data) => {
        this.sirenas = data.datos;
        console.debug(new Date().toLocaleString(), `listado de sirenas`, data);
      });
    await this.listadosService.getLastValue('sirenas', queryParams);
  }

  public chipId(iccid: string): string {
    const sirena = this.sirenas?.find((s) => {
      return s.iccidSim === iccid;
    });

    if (sirena?.chipId) {
      return sirena?.chipId;
    } else {
      return 'Sirena no encontrada';
    }
  }

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
