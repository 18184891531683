import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListarVecinosComponent } from './listar-vecinos/listar-vecinos.component';

const routes: Routes = [
  { path: '', component: ListarVecinosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VecinosRoutingModule { }
