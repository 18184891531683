/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ICrearSirena } from 'modelos/src';
import { Moment } from 'moment';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { SirenasService } from '../sirenas.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-importar-chinas',
  templateUrl: './importar-chinas.component.html',
  styleUrl: './importar-chinas.component.scss',
})
export class ImportarChinasComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ImportarChinasComponent>);
  public chipIds = signal(0);
  public loading = signal(false);
  public form?: FormGroup;

  constructor(
    private helper: HelperService,
    private dialog: DialogService,
    private sirenas: SirenasService,
  ) {}

  public onClose(): void {
    this.dialogRef.close();
  }

  /// FORM

  private createForm(): void {
    this.form = new FormGroup({
      chipIds: new FormControl('', Validators.required),
      fechaDeFabricacion: new FormControl('', Validators.required),
      version: new FormControl('', Validators.required),
      fuenteExterna: new FormControl(false, Validators.required),
    });
  }

  /// CSV
  private separarEnLineas(input: string): string[] {
    input = input.trim();
    const returnArray = [];
    if (input) {
      let inputArray: string[] = input.split('\n');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim,
      );
      for (const linea of inputArray) {
        if (linea) {
          returnArray.push(linea);
        }
      }
      if (returnArray.length) {
        return returnArray;
      }
    }
    return [];
  }

  async cargarCsv(event: any) {
    this.loading.set(true);
    const file = event.target.files[0];
    const texto = await this.readFileContent(file);
    const lineas = this.separarEnLineas(texto);
    this.chipIds.set(lineas.length);
    this.helper.notifSuccess(`${this.chipIds()} chipIds cargados.`);
    this.form?.patchValue({
      chipIds: lineas,
    });
    this.loading.set(false);
  }

  async readFileContent(file: any): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target?.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  /// FEcha edl orto
  public onDateChange(event: any): void {
    const date = event.value as Moment;
    this.form?.patchValue({
      fechaDeFabricacion: date.toISOString(),
    });
  }

  public async onSubmit() {
    console.log(this.form?.value);
    const resp = await this.dialog.confirm(
      'Importación',
      `¿Desea importar ${this.chipIds()} sirenas?`,
    );
    if (resp) {
      const datos = this.parseDatos();
      if (!datos.length) {
        return;
      }
      try {
        this.loading.set(true);
        await firstValueFrom(this.sirenas.upsertMany(datos));
        this.helper.notifSuccess(
          `#${this.chipIds()} Sirenas importadas correctamente.`,
        );
        this.onClose();
      } catch (error) {
        console.error(error);
        this.helper.notifError('Error al importar sirenas.');
      } finally {
        this.loading.set(false);
      }
    }
  }

  private parseDatos() {
    const chipIds = this.form?.get('chipIds')?.value;
    const creates: ICrearSirena[] = [];
    for (const c of chipIds) {
      const create: ICrearSirena = {
        chipId: c,
        datosHw: {
          fechaDeFabricacion: this.form?.get('fechaDeFabricacion')?.value,
          version: this.form?.get('version')?.value,
          fuenteExterna: this.form?.get('fuenteExterna')?.value,
        },
      };
      creates.push(create);
    }
    return creates;
  }

  ngOnInit(): void {
    this.createForm();
  }
}
