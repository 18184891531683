import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ClientesService } from 'src/app/entidades/clientes/clientes.service';

@Component({
  selector: 'app-upload-image-formless',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageFormlessComponent implements OnInit, OnDestroy {
  @Input() nombreCliente?: string;
  @Input() nombreImagen?: string;
  @Input() url?: string;
  @Output() urlChange = new EventEmitter<string>();

  constructor(private service: ClientesService) {}

  public async setFile(event: Event): Promise<void> {
    const file = (event as any).target?.files?.item(0);
    const res = await firstValueFrom(
      this.service.subirImagen(
        file,
        this.nombreCliente!,
        this.nombreImagen ? this.nombreImagen : `${new Date().getTime()}`
      )
    );

    this.url = res.url;
    this.urlChange.emit(this.url);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
