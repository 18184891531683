import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IEvento,
  IEventoSirena,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';

@Component({
  selector: 'app-listado-eventos',
  templateUrl: './listado-eventos.component.html',
  styleUrls: ['./listado-eventos.component.scss'],
})
export class ListadoEventosComponent implements OnInit {
  public loading = false;
  @Input() chipId?: string | null;

  public columnas = ['fechaEncendido', 'vecino', 'tipo', 'origen', 'motivo'];
  public nombreColumnas = ['Fecha', 'Vecino', 'Tipo', 'Origen', 'Motivo'];
  public saveColumnas = 'tabla-eventos';
  public dataSource = new MatTableDataSource<IEventoSirena>([]);
  // FILTRO BUSQUEDA Y PAGINACION
  private populate = {
    path: 'vecino',
    select: 'nombre',
  };
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fechaEncendido',
    populate: JSON.stringify(this.populate),
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    // private dialogService: DialogService,
    // private service: EventosBalizasService,
    // public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }

  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    // Update Filtro
    const filter: IFilter<IEventoSirena> = {
      chipId: this.chipId,
    };
    this.queryParams.filter = JSON.stringify(filter);

    // Query
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IEventoSirena>>('eventosSirenas', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de eventosSirenas`,
          data
        );
      });
    await this.listadosService.getLastValue('eventosSirenas', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
