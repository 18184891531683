/* eslint-disable @typescript-eslint/no-explicit-any */
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { ClientesService } from '../clientes.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CrearEditarClienteComponent } from '../crear-editar-cliente/crear-editar-cliente.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';

declare type mainType = ICliente;

@Component({
  selector: 'app-listar-clientes',
  templateUrl: './listar-clientes.component.html',
  styleUrls: ['./listar-clientes.component.scss'],
})
export class ListarClientesComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public estado: IFiltroTabla = {
    elementos: [
      { nombre: 'Activos', _id: 'true' },
      { nombre: 'Inactivos', _id: 'false' },
    ],
    filter: {
      field: 'activo',
      value: true,
    },
    label: 'Estado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public tipo: IFiltroTabla = {
    elementos: [
      { label: 'Barrio Privado' },
      { label: 'Municipio' },
      { label: 'Provincia' },
    ],
    filter: {
      field: 'tipo',
    },
    label: 'Tipo',
    selectLabel: 'label',
    selectValue: 'label',
    tipo: 'select',
  };

  public usuarioActual?: IUsuario;

  public loading = true;
  public columnas = [
    'icono',
    'nombre',
    'sirenas',
    'configBoton',
    'configMonitoreo',
    'categorias',
    'acciones',
  ];
  public nombreColumnas = [
    'Icono',
    'Nombre',
    'Sirenas',
    'Configuración Botón',
    'Configuración Monitoreo',
    'Categorías',
    'Acciones',
  ];
  public saveColumnas = 'tabla-clientes';
  public dataSource = new MatTableDataSource<mainType>([]);
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  private initialFilters: IFilter<ICliente> = {
    activo: true,
  };
  private populate: IPopulate = [
    {
      path: 'categorias.categoria',
      select: 'nombre variante',
    },
    {
      path: 'categoriaDefault',
      select: 'nombre variante',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify(this.initialFilters),
    select: '-coordenadas',
  };
  public totalCount = 0;
  // Listado Continuo
  public listarContinuo = true;
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: ClientesService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearEditarClienteComponent, config);
  }
  public async editar(data: mainType) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarClienteComponent, config);
  }
  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el cliente ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async desactivar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desactivar el cliente ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.desactivar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('El cliente se ha desactivado');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async activar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Activar el cliente ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.activar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('El cliente se ha activado');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', this.queryParams);
  }

  //

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
  }
}
