<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()">Pórticos</span>
    > Lecturas
  </span>
</h2>

<div class="table-container mat-elevation-z2 mx-1 mt-4">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
  </div>

  <!-- Paginator -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Patente  -->
    <ng-container matColumnDef="patente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Patente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Patente:</span>
        {{ row.patente }}
      </mat-cell>
    </ng-container>

    <!-- Columna velocidad  -->
    <ng-container matColumnDef="velocidad">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Velocidad
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Velocidad:</span>
        {{ row.velocidad }}
      </mat-cell>
    </ng-container>

    <!-- Columna fecha  -->
    <ng-container matColumnDef="fechaCreacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha Lectura
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha Lectura:</span>
        {{ row.fechaCreacion | date: "short" }}
      </mat-cell>
    </ng-container>

    <!-- Columna portico  -->
    <ng-container matColumnDef="portico">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Pórtico
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Pórtico:</span>
        {{ row.portico?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna cliente  -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente Lectura
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente Lectura:</span>
        {{ row.cliente?.nombre }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
