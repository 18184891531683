import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrearEditarBotonComponent } from './crear-editar-boton/crear-editar-boton.component';
import { BotonesRoutingModule } from './botones.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListarBotonesComponent } from './listar-botones/listar-botones.component';

@NgModule({
  declarations: [
    ListarBotonesComponent,
    CrearEditarBotonComponent
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    BotonesRoutingModule,
  ]
})
export class BotonesModule { }
