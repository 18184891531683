import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ActualizacionesFirmRoutingModule } from './actualizaciones-fim.routing.module';
import { ListarActualizacionesFirmComponent } from './listar-actualizaciones-firm/listar-actualizaciones-fim.component';

@NgModule({
  declarations: [ListarActualizacionesFirmComponent],
  imports: [CommonModule, AuxiliaresModule, ActualizacionesFirmRoutingModule],
})
export class ActualizacionesFirmModule {}
