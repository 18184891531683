import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListarApiKeysComponent } from './listar-apikeys/listar-apikeys.component';
import { CrearEditarApiKeyComponent } from './crear-editar-apikeys/crear-editar-apikeys.component';
import { ApiKeysRoutingModule } from './apikeys.routing.module';

@NgModule({
  declarations: [ListarApiKeysComponent, CrearEditarApiKeyComponent],
  imports: [CommonModule, AuxiliaresModule, ApiKeysRoutingModule],
})
export class ApiKeysModule {}
