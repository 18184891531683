import { Injectable } from '@angular/core';
import {
  IConfigVecino,
  INuevoVecino,
  IQueryParam,
  IUpdateConfigVecino,
  IUpdateVecino,
  IVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class VecinosService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<{ totalCount: number; datos: IVecino[] }> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/vecinos`, { params });
  }

  public listarPorId(id: string): Observable<IVecino> {
    return this.http.get(`/vecinos/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = this.helper.getQueryParams(queryParams);

      // return this.httpClient.get(api + ruta, { headers, params, responseType: 'blob' }).pipe(catchError(this.parseErrorBlob));

      this.http
        .get(`/configvecinos/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response) => {
            try {
              const file = new File([response], 'export.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error) => {
            console.error(error);
            resolve();
          },
        );
    });
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/vecinos/${id}`);
  }

  public desloguear(telefono: string): Observable<boolean> {
    return this.http.delete(`/vecinos/logout/${telefono}`);
  }

  public crear(dato: INuevoVecino): Observable<IVecino> {
    return this.http.post(`/vecinos`, dato);
  }

  public editar(id: string, dato: IUpdateVecino): Observable<IVecino> {
    return this.http.put(`/vecinos/${id}`, dato);
  }

  // configs

  public eliminarConfig(id: string): Observable<void> {
    return this.http.delete(`/configvecinos/${id}`);
  }

  public editarConfig(
    id: string,
    dato: IUpdateConfigVecino,
  ): Observable<IConfigVecino> {
    return this.http.put(`/configvecinos/${id}`, dato);
  }
}
