<mat-card *ngIf="config">
  <mat-card-header>
    <mat-card-title>{{ config.datosPersonales?.nombre }}</mat-card-title>
    <mat-card-subtitle>
      {{ config.datosPersonales?.dni }} -
      {{ config.datosPersonales?.fechaNacimiento | date: "shortDate" }} -
      <span [class]="config.datosPersonales?.sexo ? 'hombre' : 'mujer'">{{
        config.datosPersonales?.sexo ? "♂️" : "♀️"
      }}</span></mat-card-subtitle
    >
  </mat-card-header>

  <mat-card-content>
    <!-- Contenido del Vecino -->
    <ul class="datos-vecino">
      <li style="padding-bottom: 5px" matTooltip="Email">
        📧 {{ config.datosPersonales?.email }}
      </li>
      <li matTooltip="Teléfono" style="padding-bottom: 5px">
        ☎️ {{ config.datosPersonales?.telefono }}
      </li>
      <li style="padding-bottom: 5px">✍️ {{ getModoRegistro(config) }}</li>
      <li matTooltip="Creado" style="padding-bottom: 5px">
        🗓️ {{ config.fechaCreacion | date: "shortDate" }}
      </li>
    </ul>

    <!-- Contenido de las Configs -->

    <app-config-vecino [config]="config"></app-config-vecino>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-icon-button
      color="primary"
      matTooltip="Editar"
      (click)="editar(config)"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      color="warn"
      matTooltip="Eliminar"
      (click)="eliminar(config)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      matTooltip="Desloguear"
      (click)="desloguear(config)"
    >
      <mat-icon>logout</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
