<mat-card>
  <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    Importar GeoJSON
  </mat-card-title>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
    <input
      #input
      style="position: fixed; top: -20000px"
      type="file"
      accept=".txt"
      (change)="cargarInput($event)"
      class="input"
    />
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="input.click()"
    >
      <span>Cargar GeoJSON</span>
      <mat-icon>upload_file</mat-icon>
    </button>
  </div>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="formImportar"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- CSV -->
      <app-textarea
        #textarea
        [form]="form"
        [controlName]="'geojson'"
      ></app-textarea>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formImportar"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
