import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrearEditarUsuarioComponent } from './crear-editar-usuario/crear-editar-usuario.component';
import { ListarUsuariosComponent } from './listar-usuarios/listar-usuarios.component';

const routes: Routes = [
  { path: '', component: ListarUsuariosComponent },
  { path: 'crear', component: CrearEditarUsuarioComponent, canActivate: [] },
  { path: 'editar/:id', component: CrearEditarUsuarioComponent, canActivate: [] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuariosRoutingModule { }
