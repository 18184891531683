import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IFilter,
  IFirmwareCliente,
  IListado,
  IQueryParam,
  ISocketMessage,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CrearFirmwareClienteComponent } from '../crear-firmware-cliente/crear-firmware-cliente.component';
import { FirmwareClientesService } from '../firmware-clientes.service';

declare type mainType = IFirmwareCliente;

@Component({
  selector: 'app-listado-firmwares-cliente',
  templateUrl: './listado-firmwares-cliente.component.html',
  styleUrls: ['./listado-firmwares-cliente.component.scss'],
})
export class ListadoFirmwaresClienteComponent implements OnInit {
  public search: IRegExpSearch = {
    fields: ['version', 'url'],
  };

  @Input() loading?: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public columnas = ['fecha', 'version', 'cliente', 'acciones'];
  public nombreColumnas = ['Fecha', 'Version', 'Cliente', 'Acciones'];
  public saveColumnas = 'tabla-firmware-clientes';
  public dataSource = new MatTableDataSource<mainType>([]);
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  private populate = {
    path: 'cliente',
    select: 'nombre',
  };
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    populate: JSON.stringify(this.populate),
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: FirmwareClientesService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  private onLoadingChange(loading: boolean): void {
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  private actualizarTabla(datos: mainType[]): void {
    this.dataSource.data = datos;
  }

  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }

  public async actualizar(): Promise<void> {
    this.onLoadingChange(true);
    await this.listar();
    this.onLoadingChange(false);
  }

  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  public async cambioFiltro(filters: IFilter<any>) {
    this.onLoadingChange(true);
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.onLoadingChange(false);
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearFirmwareClienteComponent, config);
  }

  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la aplicacion de firmware para el cliente?`
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }

  // Listar
  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IFirmwareCliente>>(
        'firmwareClientes',
        this.queryParams
      )
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de firmwareClientes`,
          data
        );
      });
    await this.listadosService.getLastValue(
      'firmwareClientes',
      this.queryParams
    );
  }

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
