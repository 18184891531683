import { TipoCliente } from 'modelos/src';

export const APPEARANCE: 'outline' | 'fill' = 'outline';

export const PAIS = 'ar';

export const TIPOS_CLIENTES: TipoCliente[] = [
  'Barrio Privado',
  'Municipio',
  'Provincia',
];
