<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" [routerLink]="['../']">Sirenas</span>
    >
    {{ sirena?.chipId }}
  </span>
</h2>

<div
  *ngIf="sirena"
  class="table-container mat-elevation-z8 p-2"
  fxLayout="column"
  fxLayoutGap="2em"
>
  <!-- Botones -->
  <div class="btn-container">
    <!-- Editar -->
    <button
      class="mx-1"
      mat-fab
      color="accent"
      matTooltip="Editar"
      [routerLink]="['../editar', sirena._id]"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <!-- Actualizar Firmware -->
    <button
      class="mx-1"
      mat-fab
      matTooltip="Actualizar Firmware"
      color="accent"
      (click)="actualizarFirmware(sirena)"
    >
      <mat-icon>memory</mat-icon>
    </button>
    <!-- Reset -->
    <button
      class="mx-1"
      mat-fab
      matTooltip="Reset"
      color="accent"
      (click)="reset(sirena)"
    >
      <mat-icon>restart_alt</mat-icon>
    </button>
    <!-- Eliminar -->
    <button
      *ngIf="!sirena.online"
      class="mx-1"
      mat-fab
      color="warn"
      matTooltip="Eliminar"
      (click)="eliminar(sirena)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <!-- Detalles -->
  <!-- Estado Actual -->
  <div>
    <h2 class="text-center">Estado Actual</h2>

    <div fxLayout="row" fxLayoutAlign="space-around center" fxFill>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Online</span>
        <mat-icon *ngIf="sirena.online" [style.color]="'green'">wifi</mat-icon>
        <mat-icon *ngIf="!sirena.online" [style.color]="'red'"
          >wifi_off</mat-icon
        >
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Activa</span>
        <mat-icon *ngIf="sirena.activa" [style.color]="'green'">check</mat-icon>
        <mat-icon
          *ngIf="!sirena.activa"
          [style.color]="sirena.actualizando ? 'yellow' : 'red'"
          >clear</mat-icon
        >
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Sirena Encendida</span>
        <mat-icon *ngIf="sirena.sonidoEncendido" [style.color]="'green'"
          >volume_up</mat-icon
        >
        <mat-icon *ngIf="!sirena.sonidoEncendido" [style.color]="'red'"
          >volume_off</mat-icon
        >
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Reflector Encendido</span>
        <mat-icon *ngIf="sirena.luzEncendida" [style.color]="'green'"
          >highlight</mat-icon
        >
        <mat-icon *ngIf="!sirena.luzEncendida" [style.color]="'red'"
          >filter_alt_off</mat-icon
        >
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Firmware</span>
        <span>{{ sirena.versionFirmware }}</span>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Uptime</span>
        <span
          *ngIf="sirena.acumuladoOnline && sirena.acumuladoOffline"
          [style.background]="
            helper.uptime(sirena.acumuladoOnline, sirena.acumuladoOffline)
              .background
          "
          [style.color]="
            helper.uptime(sirena.acumuladoOnline, sirena.acumuladoOffline).color
          "
          style="padding: 0.5em; border-radius: 15px"
        >
          {{
            helper.uptime(sirena.acumuladoOnline, sirena.acumuladoOffline)
              .uptime
          }}
          %
        </span>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Señal</span>
        <span
          [style.background]="helper.rssiToText(sirena.rssi).background"
          [style.color]="
            helper.invertColor(helper.rssiToText(sirena.rssi).background)
          "
          style="padding: 0.5em; border-radius: 15px"
          [matTooltip]="sirena.rssi?.toString() || ''"
        >
          {{ helper.rssiToText(sirena.rssi).label }}
        </span>
      </div>
      <!--  -->
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- Estado Actual -->
  <div>
    <h2 class="text-center">Detalles de HW</h2>
    <div fxLayout="row" fxLayoutAlign="space-around center" fxFill>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Version de HW</span>
        <span>{{ sirena.datosHw?.version || "Sin definir" }}</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Fuente de Alimentación Externa</span>
        <span>{{
          sirena.datosHw?.fuenteExterna === true
            ? "Sí"
            : sirena.datosHw?.fuenteExterna === false
            ? "No"
            : "Sin definir"
        }}</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <span>Fecha de Fabricación</span>
        <span>{{
          (sirena.datosHw?.fechaDeFabricacion | date : "shortDate") ||
            "Sin definir"
        }}</span>
      </div>

      <!--  -->
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutAlign="space-around start">
    <!-- Grafico -->
    <app-chart
      *ngIf="chartOptions"
      [(options)]="chartOptions"
      style="height: 400px; width: 100%; display: block; margin: auto"
    ></app-chart>

    <!-- Mapa -->
    <div
      [style.padding]="'0 1em'"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="15px"
      fxFlex
    >
      <google-map
        width="400px"
        height="350px"
        [options]="mapOptions"
        [center]="sirena.ubicacionGps!"
      >
        <map-marker [position]="sirena.ubicacionGps!"></map-marker>
      </google-map>
      <div>
        <span>{{ sirena.direccionGps }}</span>
      </div>
    </div>
  </div>

  <!-- Eventos -->
  <mat-tab-group class="mt-4">
    <mat-tab label="Logs">
      <ng-template matTabContent>
        <app-listado-logs [chipId]="sirena.chipId"></app-listado-logs>
      </ng-template>
    </mat-tab>

    <mat-tab label="Eventos">
      <ng-template matTabContent>
        <app-listado-eventos [chipId]="sirena.chipId"></app-listado-eventos>
      </ng-template>
    </mat-tab>

    <mat-tab label="Actualizaciones de Firmware">
      <ng-template matTabContent>
        <app-listado-actualizacion-firm
          [chipId]="sirena.chipId"
        ></app-listado-actualizacion-firm>
      </ng-template>
    </mat-tab>
    <mat-tab label="Mantenimientos">
      <ng-template matTabContent>
        <app-listados-mantenimientos
          [chipId]="sirena.chipId"
        ></app-listados-mantenimientos>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
