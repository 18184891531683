import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICrearSim, ISim, IUpdateSim } from 'modelos/src';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { SimsService } from '../sims.service';

@Component({
  selector: 'app-editar-sims',
  templateUrl: './editar-sims.component.html',
  styleUrls: ['./editar-sims.component.scss'],
})
export class EditarSimsComponent implements OnInit {
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISim,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditarSimsComponent>,
    private service: SimsService,
    private helper: HelperService
  ) {}

  private crearFormulario(): void {
    this.title = this.data?._id ? 'Editar Sim' : 'Crear Sim';
    this.formulario = this.fb.group({
      numero: [this.data?.numero, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      if (this.data) {
        const data: IUpdateSim = {
          numero: this.formulario?.get('numero')?.value,
        };
        await this.service
          .editar(this.data._id!, data)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data: ICrearSim = {
          numero: this.formulario?.get('numero')?.value,
        };
        await this.service.crear(data).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.crearFormulario();
  }
}
