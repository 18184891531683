<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">API Keys</span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="end center" style="padding: 1em">
    <!-- Filtros -->
    <!-- <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla> -->
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna apiName  -->
    <ng-container matColumnDef="apiName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre de la API </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre de la API:</span>
        {{ row.apiName }}
      </mat-cell>
    </ng-container>

    <!-- Columna fechaCreacion  -->
    <ng-container matColumnDef="fechaCreacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha de Creación </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha de Creación:</span>
        {{ row.fechaCreacion | date : "shortDate" }}
      </mat-cell>
    </ng-container>

    <!-- Columna fechaExpiracion  -->
    <ng-container matColumnDef="fechaExpiracion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha de Expiración </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha de Expiración:</span>
        {{ row.fechaExpiracion | date : "shortDate" }}
      </mat-cell>
    </ng-container>

    <!-- Columna key  -->
    <ng-container matColumnDef="key">
      <mat-header-cell *matHeaderCellDef> API Key </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">API Key:</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span> {{ row.key }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna roles  -->
    <ng-container matColumnDef="roles">
      <mat-header-cell *matHeaderCellDef> Roles </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span>{{ row.roles.join(", ") | titlecase }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna cliente  -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef> Cliente </mat-header-cell>>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span> {{ clienteNombre(row.idCliente) | titlecase }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row" style="overflow: visible">
        <span class="mobile-label">Acciones:</span>
        <!-- Eliminar -->
        <button
          mat-icon-button
          (click)="eliminar(row)"
          color="warn"
          matTooltip="Eliminar"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <!-- Editar -->
        <button
          mat-icon-button
          (click)="editar(row)"
          color="primary"
          matTooltip="Editar"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <!-- Copiar -->
        <button
          mat-icon-button
          (click)="copyToClip(row.key)"
          color="primary"
          matTooltip="Copiar al portapapeles"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
