/* eslint-disable @typescript-eslint/no-explicit-any */
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  IPortico,
  IFilter,
  IListado,
  IQueryParam,
  ICliente,
  IPopulate,
} from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { PorticosService } from '../porticos.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CrearEditarPorticoComponent } from '../crear-editar-portico/crear-editar-portico.component';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';

declare type mainType = IPortico;

@Component({
  selector: 'app-listar-porticos',
  templateUrl: './listar-porticos.component.html',
  styleUrls: ['./listar-porticos.component.scss'],
})
export class ListarPorticosComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public loading = true;
  public columnas = [
    'macAddress',
    'nombre',
    'online',
    'cliente',
    'fechaColocacion',
    'acciones',
  ];
  public nombreColumnas = [
    'MAC Address',
    'Nombre',
    'Online',
    'Cliente',
    'Fecha de Colocación',
    'Acciones',
  ];
  public saveColumnas = 'tabla-porticos';
  public dataSource = new MatTableDataSource<mainType>([]);
  // FILTRO BUSQUEDA Y PAGINACION
  public totalCount = 0;
  public clientes$?: Subscription;
  public clientesDatos?: ICliente[];
  private initialFilters: IFilter<IPortico> = {};

  private populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };

  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'fechaColocacion',
    filter: JSON.stringify(this.initialFilters),
    select: '',
    populate: JSON.stringify(this.populate),
  };

  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: PorticosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearEditarPorticoComponent, config);
  }

  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el botón ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IPortico>>('porticos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(new Date().toLocaleString(), `listado de porticos`, data);
      });
    await this.listadosService.getLastValue('porticos', this.queryParams);
  }

  //

  async ngOnInit(): Promise<void> {
    await Promise.all([this.actualizar()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
  }
}
