import { Injectable } from '@angular/core';
import {
  IControl,
  ICrearControl,
  IListado,
  IQueryParam,
  IUpdateControl,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ControlesService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IControl>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/controles`, { params });
  }

  public listarPorId(id: string): Observable<IControl> {
    return this.http.get(`/controles/${id}`);
  }

  public crear(dato: ICrearControl): Observable<IControl> {
    return this.http.post(`/controles`, dato);
  }

  public editar(id: string, dato: IUpdateControl): Observable<IControl> {
    return this.http.put(`/controles/${id}`, dato);
  }

  public eliminar(id: string): Observable<IControl> {
    return this.http.delete(`/controles/${id}`);
  }
}
