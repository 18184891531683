import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadImageFormlessComponent } from './upload-image/upload-image.component';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [UploadImageFormlessComponent],
  imports: [CommonModule, MaterialModule],
  exports: [UploadImageFormlessComponent],
})
export class UploadImageModule {}
