import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetallesSirenaComponent } from './detalles-sirena/detalles-sirena.component';
import { EditarSirenasComponent } from './editar-sirenas/editar-sirenas.component';
import { ImportarSirenasComponent } from './importar-sirenas/importar-sirenas.component';
import { SirenasComponent } from './sirenas/sirenas.component';

const routes: Routes = [
  { path: '', component: SirenasComponent },
  {
    path: 'importar',
    component: ImportarSirenasComponent,
    canActivate: [],
  },
  { path: ':id', component: DetallesSirenaComponent },
  {
    path: 'editar/:id',
    component: EditarSirenasComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SirenasRoutingModule {}
