/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import {
  IListado,
  IUsuario,
  ISocketMessage,
  ICliente,
  IBoton,
  ICategoria,
  IVecino,
  ISirena,
  IFirmwareSirena,
  IFirmwareCliente,
  IFirmwareVersion,
  IControl,
  IControlSirena,
  ISim,
  ILogSirena,
  IEventoSirena,
  IEstadoSirena,
  IQueryParam,
  IActualizacionFirmware,
  IApiKey,
  IMantenimiento,
  IConfigVecino,
  IPortico,
  ILecturaPortico,
} from 'modelos/src';
import { WebSocketService } from './websocket';
import { ClientesService } from '../../entidades/clientes/clientes.service';
import { UsuariosService } from '../../entidades/usuarios/usuarios.service';
import { BotonesService } from '../../entidades/botones/botones.service';
import { CategoriasService } from '../../entidades/categorias/categorias.service';
import { VecinosService } from '../../entidades/vecinos/vecinos.service';
import { SirenasService } from '../../entidades/sirenas/sirenas.service';
import { FirmwareSirenasService } from '../../entidades/sirenas/firmware-sirenas.service';
import { FirmwareClientesService } from '../../entidades/sirenas/firmware-clientes.service';
import { FirmwareVersionesService } from '../../entidades/sirenas/firmware-versiones.service';
import { ControlesService } from '../../entidades/controles/controles.service';
import { ControlesSirenasService } from '../../entidades/controles/controles-sirenas.service copy';
import { SimsService } from '../../entidades/sirenas/sims.service';
import { LogsSirenasService } from '../../entidades/sirenas/logs-sirenas.service';
import { EventosSirenasService } from '../../entidades/sirenas/eventos-sirena.service';
import { EstadosSirenasService } from '../../entidades/sirenas/estados-sirena.service';
import { ActualizarFirmwareService } from 'src/app/entidades/sirenas/firmware-actualizar.service';
import { ApiKeysService } from 'src/app/entidades/apikeys/apikeys.service';
import { MantenimientosService } from 'src/app/entidades/mantenimientos/mantenimientos.service';
import { HelperService } from './helper.service';
import { ConfigVecinosService } from 'src/app/entidades/vecinos/config-vecinos.service';
import { IdleService } from './idle.service';
import { PorticosService } from 'src/app/entidades/porticos/porticos.service';
import { LecturaPorticoService } from 'src/app/entidades/porticos/lectura-portico.service';

type Method = 'post' | 'put' | 'delete';

type Tipo =
  | ICliente
  | IListado<ICliente>
  | IUsuario
  | IListado<IUsuario>
  | IBoton
  | IListado<IBoton>
  | ICategoria
  | IListado<ICategoria>
  | IVecino
  | IListado<IVecino>
  | ISirena
  | IListado<ISirena>
  | IFirmwareSirena
  | IListado<IFirmwareSirena>
  | IFirmwareCliente
  | IListado<IFirmwareCliente>
  | IFirmwareVersion
  | IListado<IFirmwareVersion>
  | IActualizacionFirmware
  | IListado<IActualizacionFirmware>
  | IControl
  | IListado<IControl>
  | IControlSirena
  | IListado<IControlSirena>
  | ISim
  | IListado<ISim>
  | ILogSirena
  | IListado<ILogSirena>
  | IEventoSirena
  | IListado<IEventoSirena>
  | IEstadoSirena
  | IListado<IEstadoSirena>
  | IApiKey
  | IListado<IApiKey>
  | IMantenimiento
  | IListado<IMantenimiento>
  | IConfigVecino
  | IListado<IConfigVecino>
  | IPortico
  | IListado<IPortico>
  | ILecturaPortico
  | IListado<ILecturaPortico>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  cache?: Tipo;
  updateOn?: Method[];

  constructor(updateOn?: Method[]) {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
    this.updateOn = updateOn;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryParam) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  botonesPorCliente: IRequestId;
  ///
  cliente: IRequestId;
  clientes: IRequestQuery;
  usuario: IRequestId;
  usuarios: IRequestQuery;
  boton: IRequestId;
  botones: IRequestQuery;
  categoria: IRequestId;
  categorias: IRequestQuery;
  vecino: IRequestId;
  vecinos: IRequestQuery;
  sirena: IRequestId;
  sirenas: IRequestQuery;
  firmwareSirena: IRequestId;
  firmwareSirenas: IRequestQuery;
  firmwareCliente: IRequestId;
  firmwareClientes: IRequestQuery;
  firmwareVersion: IRequestId;
  firmwareVersiones: IRequestQuery;
  actualizacionFirmware: IRequestId;
  actualizacionFirmwares: IRequestQuery;
  control: IRequestId;
  controles: IRequestQuery;
  controlSirena: IRequestId;
  controlesSirenas: IRequestQuery;
  sim: IRequestId;
  sims: IRequestQuery;
  logSirena: IRequestId;
  logsSirenas: IRequestQuery;
  eventoSirena: IRequestId;
  eventosSirenas: IRequestQuery;
  estadoSirena: IRequestId;
  estadosSirenas: IRequestQuery;
  apikey: IRequestId;
  apikeys: IRequestQuery;
  mantenimiento: IRequestId;
  mantenimientos: IRequestQuery;
  configVecino: IRequestId;
  configVecinos: IRequestQuery;
  portico: IRequestId;
  porticos: IRequestQuery;
  lecturaPortico: IRequestId;
  lecturasPorticos: IRequestQuery;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  private entidades: IEntidades = this.getInitCache();
  private pending: {
    entidad: keyof IEntidades;
    method?: Method;
    id?: string;
    query: 'query' | 'id';
  }[] = [];

  constructor(
    private helper: HelperService,
    private idleService: IdleService,
    private webSocketService: WebSocketService,
    private clientesService: ClientesService,
    private usuariosService: UsuariosService,
    private botonesService: BotonesService,
    private categoriasService: CategoriasService,
    private vecinosService: VecinosService,
    private sirenasService: SirenasService,
    private firmwareSirenasService: FirmwareSirenasService,
    private firmwareClientesService: FirmwareClientesService,
    private firmwareVersionesService: FirmwareVersionesService,
    private actualizarFirmService: ActualizarFirmwareService,
    private controlesService: ControlesService,
    private controlesSirenasService: ControlesSirenasService,
    private simsService: SimsService,
    private logsSirenasService: LogsSirenasService,
    private eventosSirenasService: EventosSirenasService,
    private estadosSirenasService: EstadosSirenasService,
    private apikeysService: ApiKeysService,
    private mantenimientoService: MantenimientosService,
    private configVecinosService: ConfigVecinosService,
    private porticosService: PorticosService,
    private lecturaPorticoService: LecturaPorticoService,
  ) {
    this.subscribeWsUpdates();
    this.resumePending();
  }

  // Subscribe

  public subscribe<Tipo>(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
    updateOn?: Method[],
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue(updateOn);
        // console.log(`${entidad} Nueva key ${key}`);
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(entidad, query, (ent as IRequestId).fn);
      } else {
        await this.listarQuery(entidad, query, (ent as IRequestQuery).fn);
      }
    }
  }

  // Listados Entidades
  private async listarCliente(id: string): Promise<ICliente> {
    const response = await firstValueFrom(this.clientesService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarClientes(
    query: IQueryParam,
  ): Promise<IListado<ICliente>> {
    // if (!this.helper.puedeListarClientes()) return;

    const response = await firstValueFrom(this.clientesService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuario(id: string): Promise<IUsuario> {
    const response = await firstValueFrom(this.usuariosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarUsuarios(
    query: IQueryParam,
  ): Promise<IListado<IUsuario>> {
    const response = await firstValueFrom(this.usuariosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarBoton(id: string): Promise<IBoton> {
    const response = await firstValueFrom(this.botonesService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarBotonPorIdCliente(
    idCliente: string,
  ): Promise<IListado<IBoton>> {
    const response = await firstValueFrom(
      this.botonesService.listarPoridCliente(idCliente),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarBotons(query: IQueryParam): Promise<IListado<IBoton>> {
    const response = await firstValueFrom(this.botonesService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCategoria(id: string): Promise<ICategoria> {
    const response = await firstValueFrom(
      this.categoriasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarCategorias(
    query: IQueryParam,
  ): Promise<IListado<ICategoria>> {
    const response = await firstValueFrom(this.categoriasService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVecino(id: string): Promise<IVecino> {
    const response = await firstValueFrom(this.vecinosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarVecinos(query: IQueryParam): Promise<IListado<IVecino>> {
    const response = await firstValueFrom(this.vecinosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }
  public listarVecinos$(queryParams?: IQueryParam): Observable<IVecino[]> {
    return new Observable((observer) => {
      this.vecinosService.listar(queryParams).subscribe((vecinos) => {
        observer.next(vecinos.datos);
      });
    });
  }

  private async listarSirena(id: string): Promise<ISirena> {
    const response = await firstValueFrom(this.sirenasService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSirenas(query: IQueryParam): Promise<IListado<ISirena>> {
    const response = await firstValueFrom(this.sirenasService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }
  public listarSirenas$(queryParams?: IQueryParam): Observable<ISirena[]> {
    return new Observable((observer) => {
      this.sirenasService.listar(queryParams).subscribe((sirenas) => {
        observer.next(sirenas.datos);
      });
    });
  }

  private async listarFirmwareSirena(id: string): Promise<IFirmwareSirena> {
    const response = await firstValueFrom(
      this.firmwareSirenasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarFirmwareSirenas(
    query: IQueryParam,
  ): Promise<IListado<IFirmwareSirena>> {
    const response = await firstValueFrom(
      this.firmwareSirenasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarActualizacionFirmware(
    id: string,
  ): Promise<IActualizacionFirmware> {
    const response = await firstValueFrom(
      this.actualizarFirmService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarActualizacionFirmwares(
    query: IQueryParam,
  ): Promise<IListado<IActualizacionFirmware>> {
    const response = await firstValueFrom(
      this.actualizarFirmService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarFirmwareCliente(id: string): Promise<IFirmwareCliente> {
    const response = await firstValueFrom(
      this.firmwareClientesService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarFirmwareClientes(
    query: IQueryParam,
  ): Promise<IListado<IFirmwareCliente>> {
    const response = await firstValueFrom(
      this.firmwareClientesService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarFirmwareVersion(id: string): Promise<IFirmwareVersion> {
    const response = await firstValueFrom(
      this.firmwareVersionesService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarFirmwareVersions(
    query: IQueryParam,
  ): Promise<IListado<IFirmwareVersion>> {
    const response = await firstValueFrom(
      this.firmwareVersionesService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarControl(id: string): Promise<IControl> {
    const response = await firstValueFrom(
      this.controlesService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarControls(
    query: IQueryParam,
  ): Promise<IListado<IControl>> {
    const response = await firstValueFrom(this.controlesService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarControlSirena(id: string): Promise<IControlSirena> {
    const response = await firstValueFrom(
      this.controlesSirenasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarControlSirenas(
    query: IQueryParam,
  ): Promise<IListado<IControlSirena>> {
    const response = await firstValueFrom(
      this.controlesSirenasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSim(id: string): Promise<ISim> {
    const response = await firstValueFrom(this.simsService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarSims(query: IQueryParam): Promise<IListado<ISim>> {
    const response = await firstValueFrom(this.simsService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogSirena(id: string): Promise<ILogSirena> {
    const response = await firstValueFrom(
      this.logsSirenasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarLogSirenas(
    query: IQueryParam,
  ): Promise<IListado<ILogSirena>> {
    const response = await firstValueFrom(
      this.logsSirenasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventoSirena(id: string): Promise<IEventoSirena> {
    const response = await firstValueFrom(
      this.eventosSirenasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarEventoSirenas(
    query: IQueryParam,
  ): Promise<IListado<IEventoSirena>> {
    const response = await firstValueFrom(
      this.eventosSirenasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEstadoSirena(id: string): Promise<IEstadoSirena> {
    const response = await firstValueFrom(
      this.estadosSirenasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarEstadoSirenas(
    query: IQueryParam,
  ): Promise<IListado<IEstadoSirena>> {
    const response = await firstValueFrom(
      this.estadosSirenasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarApiKey(id: string): Promise<IApiKey> {
    const response = await firstValueFrom(this.apikeysService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarApiKeys(query: IQueryParam): Promise<IListado<IApiKey>> {
    const response = await firstValueFrom(this.apikeysService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarMantenimiento(id: string): Promise<IMantenimiento> {
    const response = await firstValueFrom(
      this.mantenimientoService.getById(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarMantenimientos(
    query: IQueryParam,
  ): Promise<IListado<IMantenimiento>> {
    const response = await firstValueFrom(
      this.mantenimientoService.getFiltered(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigVecino(id: string): Promise<IConfigVecino> {
    const response = await firstValueFrom(
      this.configVecinosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarConfigVecinos(
    query: IQueryParam,
  ): Promise<IListado<IConfigVecino>> {
    const response = await firstValueFrom(
      this.configVecinosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }
  public listarConfigVecinos$(
    queryParams?: IQueryParam,
  ): Observable<IConfigVecino[]> {
    return new Observable((observer) => {
      this.configVecinosService.listar(queryParams).subscribe((config) => {
        observer.next(config.datos);
      });
    });
  }

  private async listarPortico(id: string): Promise<IPortico> {
    const response = await firstValueFrom(this.porticosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarPorticos(
    query: IQueryParam,
  ): Promise<IListado<IPortico>> {
    const response = await firstValueFrom(this.porticosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLecturaPortico(id: string): Promise<IPortico> {
    const response = await firstValueFrom(
      this.lecturaPorticoService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarLecturasPorticos(
    query: IQueryParam,
  ): Promise<IListado<IPortico>> {
    const response = await firstValueFrom(
      this.lecturaPorticoService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  ///////////////////////////////////////////////////////////////////////////////

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      botonesPorCliente: {
        fn: this.listarBotonPorIdCliente.bind(this),
        keys: {},
      },
      ///
      cliente: { fn: this.listarCliente.bind(this), keys: {} },
      clientes: { fn: this.listarClientes.bind(this), keys: {} },
      usuario: { fn: this.listarUsuario.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      boton: { fn: this.listarBoton.bind(this), keys: {} },
      botones: { fn: this.listarBotons.bind(this), keys: {} },
      categoria: { fn: this.listarCategoria.bind(this), keys: {} },
      categorias: { fn: this.listarCategorias.bind(this), keys: {} },
      vecino: { fn: this.listarVecino.bind(this), keys: {} },
      vecinos: { fn: this.listarVecinos.bind(this), keys: {} },
      sirena: { fn: this.listarSirena.bind(this), keys: {} },
      sirenas: { fn: this.listarSirenas.bind(this), keys: {} },
      firmwareVersion: { fn: this.listarFirmwareVersion.bind(this), keys: {} },
      firmwareVersiones: {
        fn: this.listarFirmwareVersions.bind(this),
        keys: {},
      },
      firmwareCliente: { fn: this.listarFirmwareCliente.bind(this), keys: {} },
      firmwareClientes: {
        fn: this.listarFirmwareClientes.bind(this),
        keys: {},
      },
      firmwareSirena: { fn: this.listarFirmwareSirena.bind(this), keys: {} },
      firmwareSirenas: { fn: this.listarFirmwareSirenas.bind(this), keys: {} },
      actualizacionFirmware: {
        fn: this.listarActualizacionFirmware.bind(this),
        keys: {},
      },
      actualizacionFirmwares: {
        fn: this.listarActualizacionFirmwares.bind(this),
        keys: {},
      },
      control: { fn: this.listarControl.bind(this), keys: {} },
      controles: { fn: this.listarControls.bind(this), keys: {} },
      controlSirena: { fn: this.listarControlSirena.bind(this), keys: {} },
      controlesSirenas: { fn: this.listarControlSirenas.bind(this), keys: {} },
      sim: { fn: this.listarSim.bind(this), keys: {} },
      sims: { fn: this.listarSims.bind(this), keys: {} },
      logSirena: { fn: this.listarLogSirena.bind(this), keys: {} },
      logsSirenas: { fn: this.listarLogSirenas.bind(this), keys: {} },
      eventoSirena: { fn: this.listarEventoSirena.bind(this), keys: {} },
      eventosSirenas: { fn: this.listarEventoSirenas.bind(this), keys: {} },
      estadoSirena: { fn: this.listarEstadoSirena.bind(this), keys: {} },
      estadosSirenas: { fn: this.listarEstadoSirenas.bind(this), keys: {} },
      apikey: { fn: this.listarApiKey.bind(this), keys: {} },
      apikeys: { fn: this.listarApiKeys.bind(this), keys: {} },
      mantenimiento: { fn: this.listarMantenimiento.bind(this), keys: {} },
      mantenimientos: { fn: this.listarMantenimientos.bind(this), keys: {} },
      configVecino: { fn: this.listarConfigVecino.bind(this), keys: {} },
      configVecinos: { fn: this.listarConfigVecinos.bind(this), keys: {} },
      portico: { fn: this.listarPortico.bind(this), keys: {} },
      porticos: { fn: this.listarPorticos.bind(this), keys: {} },
      lecturaPortico: { fn: this.listarLecturaPortico.bind(this), keys: {} },
      lecturasPorticos: {
        fn: this.listarLecturasPorticos.bind(this),
        keys: {},
      },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
  }

  private wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private resumePending() {
    this.idleService.wake$.subscribe(() => {
      console.log('Resume Pending');
      while (this.pending.length) {
        const { entidad, method, query, id } = this.pending.shift()!;
        if (query === 'query') {
          console.log('Query', entidad, method);
          this.actualizarQuery(entidad, method!);
        } else {
          console.log('Id', entidad, id);
          this.actualizarId(entidad, method);
        }
      }
    });
  }

  // Actualizar Entidades
  private async actualizarQuery(
    entidad: keyof IEntidades,
    method: Method,
  ): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    // Recorre todas las querys para la entidad que se quiera actualizar
    for (const key in ent.keys) {
      if (!ent.keys[key].updateOn || ent.keys[key].updateOn?.includes(method)) {
        // Borra el cache y encola las peticiones si el usuario esta inactivo
        if (this.idleService.isIdle) {
          const existe = this.pending.find((p) => p.entidad === entidad);
          if (existe) {
            return;
          }
          console.log('Push', entidad, method, 'Query');
          this.pending.push({ entidad, method, query: 'query' });
          return;
        }

        if (ent.keys[key].requests === 0) {
          if (Object.prototype.hasOwnProperty.call(ent.keys, key)) {
            ent.keys[key].cache = undefined;
            const query = JSON.parse(key);
            if (ent.keys[key].subscribe.observers.length) {
              ent.keys[key].requests++;
              while (ent.keys[key].requests) {
                ent.keys[key].cache = undefined;
                await this.listarQuery(entidad, query, ent.fn);
                if (entidad === 'sirenas') {
                  await this.wait(20000);
                } else {
                  await this.wait(5000);
                }
                ent.keys[key].requests--;
              }
            }
          }
        } else if (ent.keys[key].requests < 2) {
          ent.keys[key].requests++;
        }
      }
    }
  }

  private async actualizarId(
    entidad: keyof IEntidades,
    id?: string,
  ): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        // Borra el cache y encola las peticiones si el usuario esta inactivo
        if (this.idleService.isIdle) {
          const existe = this.pending.find(
            (p) => p.entidad === entidad && p.id === id,
          );
          if (existe) {
            return;
          }
          console.log('Push', entidad, id, 'Id');
          this.pending.push({ entidad, id, query: 'id' });
          return;
        }

        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          if (ent.keys[id].subscribe.observers.length) {
            ent.keys[id].requests++;
            while (ent.keys[id].requests) {
              ent.keys[id].cache = undefined;
              await this.listarId(entidad, id, ent.fn);
              if (entidad === 'sirenas') {
                await this.wait(20000);
              } else {
                await this.wait(5000);
              }
              ent.keys[id].requests--;
            }
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales

  private async listarQuery(
    entidad: keyof IEntidades,
    query: IQueryParam,
    fn: (query: IQueryParam) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (!ent.keys[key].cache) {
      const response = await fn(query);
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
    }
    ent.keys[key].subscribe.next(ent.keys[key].cache!);
  }

  private async listarId(
    entidad: keyof IEntidades,
    id: string,
    fn: (id: string) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      const response = await fn(id);
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
    }
    ent.keys[id].subscribe.next(ent.keys[id].cache!);
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    const paths = message.paths;
    const method = message.method as Method;

    if (paths?.includes('clientes')) {
      this.actualizarQuery('clientes', method);
      this.actualizarId('cliente', message.body?._id);
    }
    if (paths?.includes('usuarios')) {
      this.actualizarQuery('usuarios', method);
      this.actualizarId('usuario', message.body?._id);
    }
    if (paths?.includes('botons') || paths?.includes('botones')) {
      this.actualizarQuery('botones', method);
      this.actualizarId('boton', message.body?._id);
    }
    if (paths?.includes('categorias')) {
      this.actualizarQuery('categorias', method);
      this.actualizarId('categoria', message.body?._id);
    }
    if (paths?.includes('vecinos')) {
      this.actualizarQuery('vecinos', method);
      this.actualizarId('vecino', message.body?._id);
    }
    if (paths?.includes('sirenas')) {
      this.actualizarQuery('sirenas', method);
      this.actualizarId('sirena', message.body?._id);
    }
    if (paths?.includes('firmwareversions')) {
      this.actualizarQuery('firmwareVersiones', method);
      this.actualizarId('firmwareVersion', message.body?._id);
    }
    if (paths?.includes('firmwareclientes')) {
      this.actualizarQuery('firmwareClientes', method);
      this.actualizarId('firmwareCliente', message.body?._id);
    }
    if (paths?.includes('firmwaresirenas')) {
      this.actualizarQuery('firmwareSirenas', method);
      this.actualizarId('firmwareSirena', message.body?._id);
    }
    if (paths?.includes('actualizacionfirmwares')) {
      this.actualizarQuery('actualizacionFirmwares', method);
      this.actualizarId('actualizacionFirmware', message.body?._id);
    }
    if (paths?.includes('controles')) {
      this.actualizarQuery('controles', method);
      this.actualizarId('control', message.body?._id);
    }
    if (paths?.includes('controlsirenas')) {
      this.actualizarQuery('controlesSirenas', method);
      this.actualizarId('controlSirena', message.body?._id);
    }
    if (paths?.includes('logSirenas')) {
      this.actualizarQuery('logsSirenas', method);
      this.actualizarId('logSirena', message.body?._id);
    }
    if (paths?.includes('sims')) {
      this.actualizarQuery('sims', method);
      this.actualizarId('sim', message.body?._id);
    }
    if (paths?.includes('logsirenas')) {
      this.actualizarQuery('logsSirenas', method);
      this.actualizarId('logSirena', message.body?._id);
    }
    if (paths?.includes('eventosirenas')) {
      this.actualizarQuery('eventosSirenas', method);
      this.actualizarId('eventoSirena', message.body?._id);
    }
    if (paths?.includes('estadosirenas')) {
      this.actualizarQuery('estadosSirenas', method);
      this.actualizarId('estadoSirena', message.body?._id);
    }
    if (paths?.includes('apikeys')) {
      this.actualizarQuery('apikeys', method);
      this.actualizarId('apikey', message.body?._id);
    }
    if (paths?.includes('mantenimientos')) {
      this.actualizarQuery('mantenimientos', method);
      this.actualizarId('mantenimiento', message.body?._id);
    }
    if (paths?.includes('configvecinos')) {
      this.actualizarQuery('configVecinos', method);
      this.actualizarId('configVecino', message.body?._id);
    }
    if (paths?.includes('porticos')) {
      this.actualizarQuery('porticos', method);
      this.actualizarId('porticos', message.body?._id);
    }
    if (paths?.includes('lecturasPorticos')) {
      this.actualizarQuery('lecturasPorticos', method);
      this.actualizarId('lecturasPorticos', message.body?._id);
    }
  }
}
