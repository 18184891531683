/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ICliente, IFilter, IListado, IQueryParam } from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { MapaService } from '../mapa.service';
import { OpenLayersService } from 'src/app/auxiliares/servicios/open-layers.service';
import { Coordinate } from 'ol/coordinate';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-flotante',
  templateUrl: './flotante.component.html',
  styleUrl: './flotante.component.scss',
})
export class FlotanteComponent implements OnInit, OnDestroy {
  @Input() sirenas = 0;
  @Input() loading = false;
  @Output() clientesChange = new EventEmitter<ICliente[]>();
  @Output() estadoChange = new EventEmitter<boolean>();
  @Output() mostrarSirenasChange = new EventEmitter<boolean>();
  @Output() mostrarClientesChange = new EventEmitter<boolean>();

  /// Clientes
  public clientes: ICliente[] = [];
  public clientesSeleccionados: ICliente[] = [];
  public clientes$?: Subscription;

  // Mostrar
  public mostrarClientes = true;
  public mostrarSirenas = true;

  public estados = [
    { nombre: 'Online', value: true },
    { nombre: 'Offline', value: false },
  ];

  public estadoSeleccionado?: { nombre: string; value: boolean };

  @Output() geocode = new EventEmitter<Coordinate>();

  constructor(
    private service: MapaService,
    private listados: ListadosService,
  ) {}

  public async selectDireccion(direccion: string) {
    const resp = await firstValueFrom(this.service.geocodeDireccion(direccion));
    const coordinate = OpenLayersService.coordenadaToCoordinate(resp);
    this.geocode.emit(coordinate);
  }

  public changeCliente(clientes: ICliente[]) {
    if (clientes.length === 0) {
      this.clientesChange.emit(this.clientes);
    } else {
      this.clientesChange.emit(clientes);
    }
  }

  public changeEstado(estado: { nombre: string; value: boolean }) {
    if (!estado) {
      this.estadoChange.emit(undefined);
    } else {
      this.estadoChange.emit(estado.value);
    }
  }

  public changeMostrarClientes(event: MatSlideToggleChange) {
    this.mostrarClientesChange.emit(event.checked);
  }

  public changeMostrarSirenas(event: MatSlideToggleChange) {
    this.mostrarSirenasChange.emit(event.checked);
  }

  private async listarClientes(): Promise<void> {
    const filter: IFilter<ICliente> = {
      coordenadas: {
        $exists: true,
      },
    };
    // Filter
    const query: IQueryParam = {
      select: 'coordenadas nombre',
      filter: JSON.stringify(filter),
      sort: 'nombre',
    };

    // Query

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
      });
    await this.listados.getLastValue('clientes', query);
  }

  /// HOOKS
  async ngOnInit(): Promise<void> {
    await this.listarClientes();
    this.clientesChange.emit(this.clientes);
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
