import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IToken, IUsuario } from 'modelos/src';
import { Observable } from 'rxjs';
import {
  NOMBRE_APP,
  VERSION,
  apiAdmin,
} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  public login(username: string, password: string): Observable<IToken> {
    const url = `${apiAdmin}/auth/login`;

    const headers = new HttpHeaders({
      appVersion: VERSION,
      app: NOMBRE_APP,
      appType: 'web',
    });

    const body = {
      username,
      password,
      grant_type: 'password',
    };

    return this.http.post<IToken>(url, body, { headers });
  }

  public getUsuarioPropio(token: string): Observable<IUsuario> {
    const url = `${apiAdmin}/usuarios/propio`;

    const headers = new HttpHeaders({
      appVersion: VERSION,
      app: NOMBRE_APP,
      appType: 'web',
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<IUsuario>(url, { headers });
  }

  public refreshToken(refresh_token: string): Observable<IToken> {
    const url = `${apiAdmin}/auth/login`;

    const headers = new HttpHeaders({
      appVersion: VERSION,
      app: NOMBRE_APP,
      appType: 'web',
    });

    const body = {
      refresh_token,
      grant_type: 'refresh_token',
    };

    return this.http.post<IToken>(url, body, { headers });
  }
}
