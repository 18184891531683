/* eslint-disable @typescript-eslint/no-explicit-any */
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  IBoton,
  ICreateBoton,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { BotonesService } from '../botones.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CrearEditarBotonComponent } from '../crear-editar-boton/crear-editar-boton.component';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';

declare type mainType = IBoton;

@Component({
  selector: 'app-listar-botones',
  templateUrl: './listar-botones.component.html',
  styleUrls: ['./listar-botones.component.scss'],
})
export class ListarBotonesComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['nombre', 'variante', 'funcion'],
  };

  public loading = true;
  public columnas = [
    'nombre',
    'variante',
    'funcion',
    'imagen',
    'sirena',
    'global',
    'prioridad',
    'seguimiento',
    'seguimientoManual',
    'acciones',
  ];
  public nombreColumnas = [
    'Nombre',
    'Variante',
    'Función',
    'Imagen',
    'Sirena',
    'Global',
    'Prioridad',
    'Seguimiento',
    'Seguimiento Manual',
    'Acciones',
  ];
  public saveColumnas = 'tabla-botones';
  public dataSource = new MatTableDataSource<mainType>([]);
  private datos: mainType[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
  };
  public totalCount = 0;

  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: BotonesService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearEditarBotonComponent, config);
  }
  public async editar(data: mainType) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarBotonComponent, config);
  }
  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el botón ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }
  public async clonar(data: mainType) {
    const response = await this.dialogService.prompt(
      'Confirme la acción',
      `¿Clonar el botón <strong>${data.nombre} ${
        data.variante ? data.variante : ''
      }</strong>?`,
      ['Nombre', 'Variante'],
      [data.nombre!, data.variante!],
    );
    const nombre = response ? response[0] : '';
    const variante = response ? response[1] : '';
    if (nombre) {
      const create: ICreateBoton = {
        nombre,
        variante,
        funcion: data.funcion,
        codigoSmartCity: data.codigoSmartCity,
        color: data.color,
        editable: data.editable,
        envioMultimedia: data.envioMultimedia,
        finalizacionAutomatica: data.finalizacionAutomatica,
        global: data.global,
        link: data.link,
        notificacion: data.notificacion,
        sirena: data.sirena,
        texto: data.texto,
        tipoDePunto: data.tipoDePunto,
        trackeo: data.trackeo,
        urlImagen: data.urlImagen,
        urlImagenPush: data.urlImagenPush,
      };

      try {
        await firstValueFrom(this.service.crear(create));
        this.helper.notifSuccess(
          `Botón clonado correctamente con el nombre ${nombre}`,
        );
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
      }
    }
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IBoton>>('botones', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(new Date().toLocaleString(), `listado de botones`, data);
      });
    await this.listadosService.getLastValue('botones', this.queryParams);
  }

  //

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
  }
}
