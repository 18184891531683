import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ISirena, IUpdateSirena, ICliente, IUpdateCliente } from 'modelos/src';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { SirenasService } from '../sirenas.service';
import { AuthService } from '../../login/auth.service';
import { ClientesService } from '../../clientes/clientes.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-editar-sirenas',
  templateUrl: './editar-sirenas.component.html',
  styleUrls: ['./editar-sirenas.component.scss'],
})
export class EditarSirenasComponent implements OnInit {
  public coordenadas?: google.maps.LatLngLiteral;

  get datosHw(): UntypedFormGroup {
    return this.formulario?.get('datosHw') as UntypedFormGroup;
  }

  get configs(): UntypedFormGroup {
    return this.formulario?.get('configs') as UntypedFormGroup;
  }

  public editarUbicacion?: boolean;

  public cliente?: ICliente;
  public tiposDeSirena: string[] = [];

  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;

  public mapOptions: google.maps.MapOptions = {
    zoom: 12,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    gestureHandling: 'cooperative',
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.TOP_LEFT,
    },
  };
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  public id: string | null = null;
  public data?: ISirena;

  constructor(
    private fb: UntypedFormBuilder,
    private service: SirenasService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private clientes: ClientesService,
  ) {}

  // Mapa

  private async initMap(): Promise<void> {
    const lat = this.data?.ubicacionManual?.lat;
    // Setear posicion incial
    if (!lat) {
      const currPos = await this.helper.getCurrentPosition();
      this.coordenadas = currPos;
    }
    // Definir mapa
    this.coordenadas = this.data?.ubicacionManual || this.coordenadas;
  }

  public setLocation(event: google.maps.MapMouseEvent): void {
    const coords = event.latLng?.toJSON();
    this.coordenadas = coords;
  }

  private crearFormulario(): void {
    this.formulario = this.fb.group({
      activa: [this.data?.activa],
      tipo: this.data?.tipo ? [[this.data?.tipo]] : [[]],
      datosHw: this.fb.group({
        numeroNac: [this.data?.datosHw?.numeroNac],
        version: [this.data?.datosHw?.version],
        fuenteExterna: [this.data?.datosHw?.fuenteExterna],
        fechaDeFabricacion: [this.data?.datosHw?.fechaDeFabricacion],
      }),

      configs: this.fb.group({
        distanciaCobertura: [this.data?.configs?.distanciaCobertura],
        puedeEnviarControlesDesconocidos: [
          this.data?.configs?.puedeEnviarControlesDesconocidos,
        ],
      }),
    });
  }

  public volver(): void {
    window.history.back();
  }

  //

  private getData(): IUpdateSirena {
    const tipo = this.formulario?.get('tipo')?.value;
    const datos: IUpdateSirena = {
      activa: this.formulario?.get('activa')?.value,
      tipo: tipo[0],
      datosHw: {
        numeroNac: this.formulario?.get('datosHw')?.get('numeroNac')?.value,
        version: this.formulario?.get('datosHw')?.get('version')?.value,
        fuenteExterna: this.formulario?.get('datosHw')?.get('fuenteExterna')
          ?.value,
        fechaDeFabricacion: this.formulario
          ?.get('datosHw')
          ?.get('fechaDeFabricacion')?.value,
      },
      configs: {
        distanciaCobertura: this.formulario
          ?.get('configs')
          ?.get('distanciaCobertura')?.value,
        puedeEnviarControlesDesconocidos: this.formulario
          ?.get('configs')
          ?.get('puedeEnviarControlesDesconocidos')?.value,
      },
    };
    if (this.editarUbicacion) {
      datos.ubicacionManual = this.coordenadas;
    }
    return datos;
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  private async listar(id: string): Promise<void> {
    this.data = await this.service.listarPorId(id).pipe(take(1)).toPromise();
    console.log('data', this.data);
  }

  public async agregarTipos(tipo: string) {
    if (this.tiposDeSirena.includes(tipo)) return;

    this.loading = true;

    try {
      this.tiposDeSirena.push(tipo);

      const update: IUpdateCliente = {
        configuracion: {
          tiposDeSirena: this.tiposDeSirena,
        },
      };
      const cliente = await firstValueFrom(
        this.clientes.editar(this.cliente?._id!, update),
      );

      this.auth.setCliente(cliente);
      // console.log('update', update);
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
  ///

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.cliente = this.auth.getCliente();
    this.tiposDeSirena = this.cliente?.configuracion?.tiposDeSirena || [];
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    if (this.id) {
      await this.listar(this.id);
    }
    if (this.data?.ubicacionManual) {
      this.editarUbicacion = true;
    }
    this.crearFormulario();
    await this.initMap();
    this.loading = false;
  }
}
