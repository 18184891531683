<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Mantenimientos </span></h2>

<div class="table-container mat-elevation-z2">
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha  -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha:</span>
        {{ row.fecha | date : "short" }}
      </mat-cell>
    </ng-container>

    <!-- Columna tags  -->
    <ng-container matColumnDef="tags">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tags
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Tags:</span>
        <div class="tags">
          <p
            *ngFor="let tag of row.tags"
            [style.background-color]="helper.stringToColour(tag)"
          >
            {{ tag }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna chipId  -->
    <ng-container matColumnDef="chipId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        ChipID
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">ChipID:</span>
        <span>
          {{ row.sirena?.chipId }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna usuario  -->
    <ng-container matColumnDef="usuario">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Usuario
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Usuario:</span>
        {{ row.usuario?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna cliente  -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Cliente:</span>
        {{ row.cliente?.nombre }}
      </mat-cell>
    </ng-container>

    <!-- Columna dirección  -->
    <ng-container matColumnDef="direccion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Dirección
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Dirección:</span>
        {{ row.sirena?.direccionGps }}
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row" class="nowrap">
        <span class="mobile-label">Acciones:</span>
        <button
          [routerLink]="['detalles', row._id]"
          mat-mini-fab
          color="accent"
          matTooltip="Detalles"
        >
          <mat-icon>info</mat-icon>
        </button>
        <!-- <button
          mat-mini-fab
          color="accent"
          matTooltip="Editar"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button> -->
        <!-- <button
          *ngIf="row._id !== usuarioActual?._id"
          class="mx-1"
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button> -->
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
