<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()">Sirenas</span>
    >
    {{ title }}
  </span>
</h2>

<div class="table-container mat-elevation-z8 p-2">
  <form
    id="form"
    *ngIf="formulario"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <!-- <mat-form-field>
      <mat-label>XXX</mat-label>
      <input matInput placeholder="xxx" formControlName="" required />
    </mat-form-field> -->

    <div>
      <mat-checkbox formControlName="activa" style="margin: 1em 0"
        >Activa</mat-checkbox
      >
      <!-- Select tipo -->
      <ng-select
        [items]="tiposDeSirena"
        formControlName="tipo"
        [hideSelected]="true"
        placeholder="Tipos de Sirena"
        [searchable]="true"
        [multiple]="true"
        [maxSelectedItems]="1"
        [addTag]="true"
        addTagText="Agregar un tipo"
        (add)="agregarTipos($event)"
        notFoundText="Agregá un tipo nuevo al escribir en el input"
      >
      </ng-select>

      <!-- Configs -->
      <div [formGroup]="configs">
        <mat-form-field>
          <mat-label>Distancia de Cobertura</mat-label>
          <input
            matInput
            placeholder="100"
            formControlName="distanciaCobertura"
            type="number"
          />
          <mat-hint>En metros</mat-hint>
        </mat-form-field>
      </div>

      <!-- Datos HW -->
      <div [formGroup]="datosHw">
        <mat-form-field>
          <mat-label>Número NAC</mat-label>
          <input matInput placeholder="NAC-1234" formControlName="numeroNac" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Versión de HW</mat-label>
          <input matInput placeholder="v1" formControlName="version" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Fecha de Fabricación</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="fechaDeFabricacion"
          />
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-checkbox formControlName="fuenteExterna" style="margin-top: 1em"
          >Fuente Externa</mat-checkbox
        >
      </div>
      <div [formGroup]="configs">
        <br />
        <br />
        <mat-checkbox formControlName="puedeEnviarControlesDesconocidos"
          >Puede enviar controles desconocidos</mat-checkbox
        >
      </div>
    </div>

    <div *ngIf="coordenadas" style="margin: 1em 0; width: 100%">
      <mat-checkbox
        [(ngModel)]="editarUbicacion"
        [ngModelOptions]="{ standalone: true }"
        style="margin: 1em 0"
        >Editar Ubicación</mat-checkbox
      >

      <div class="upCenter">
        <app-marker-location
          [(coordenadas)]="coordenadas"
        ></app-marker-location>
      </div>

      <google-map width="100%" [center]="coordenadas" [options]="mapOptions">
        <map-marker
          [options]="markerOptions"
          [position]="coordenadas"
          (mapDragend)="setLocation($event)"
        ></map-marker>
      </google-map>
    </div>
  </form>

  <div fxLayoutGap="10px" style="text-align: end">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="volver()">Volver</button>
  </div>
</div>
