import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ENV, VERSION } from '../../../../environments/environment';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { WebSocketService } from '../../../auxiliares/servicios/websocket';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading = false;
  public loginForm?: UntypedFormGroup;
  public loginTitle = 'Bienvenidos a Novit';
  public loginText = 'Seguridad digital.';
  public version = VERSION;
  public env = ENV;
  public hide = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private helperService: HelperService,
    private wss: WebSocketService
  ) {}

  public async onSubmit() {
    try {
      if (this.loginForm?.valid) {
        this.loading = true;
        const username = this.loginForm.get('username')?.value;
        const password = this.loginForm.get('password')?.value;
        await this.authService.login(username, password);
        this.wss.initWs();
        this.router.navigateByUrl('main');
        this.loading = false;
      } else {
        this.helperService.notifWarn(
          'Debe ingresar un usuario y una contraseña'
        );
      }
    } catch (error) {
      console.error(error);
      this.helperService.notifError(error);
      this.loading = false;
    }
  }

  ngOnInit(): void {
    this.authService.logout();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
}
