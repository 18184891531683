<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="formulario"
      id="form"
      [formGroup]="formulario"
      (ngSubmit)="enviar()"
      autocomplete="off"
    >
      <!-- Sirena para escuchar control -->
      <ng-select
        [tabIndex]="-1"
        [items]="sirenas$ | async"
        [typeahead]="inputSirenas$"
        [loading]="loading"
        [minTermLength]="3"
        bindLabel="chipId"
        bindValue="chipId"
        formControlName="chipIdSirena"
        loadingText="Cargando..."
        typeToSearchText="Buscar por dirección o chipId"
        notFoundText="No se encontraron sirenas"
        appendTo="body"
        [placeholder]="'Sirena para escuchar el control'"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <span>{{ item.chipId }} </span>
          <span style="font-size: 1em">
            ({{ item.direccionGps || item.direccionManual }})</span
          >
        </ng-template>

        <ng-template
          ng-label-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <span>{{ item.chipId }} </span>
          <span style="font-size: 1em">
            ({{ item.direccionGps || item.direccionManual }})</span
          >
        </ng-template>
      </ng-select>

      <!-- Chip Id Control -->
      <mat-form-field>
        <mat-label>ID</mat-label>
        <input
          [tabIndex]="-1"
          matInput
          placeholder="ID del control"
          formControlName="chipId"
          required
        />
      </mat-form-field>

      <!-- Etiqueta -->
      <mat-form-field>
        <mat-label>Etiqueta</mat-label>
        <input
          matInput
          placeholder="Etiqueta del control"
          formControlName="etiqueta"
        />
      </mat-form-field>

      <mat-checkbox
        formControlName="incrementar"
        style="margin-bottom: 2em"
        [tabIndex]="-1"
      >
        Incrementar etiqueta automaticamente
      </mat-checkbox>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="close()" [tabIndex]="-1">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
