import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMantenimiento } from 'modelos/src';
import { take } from 'rxjs/operators';
import { MantenimientosService } from '../mantenimientos.service';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-detalles-mantenimiento',
  templateUrl: './detalles-mantenimiento.component.html',
  styleUrls: ['./detalles-mantenimiento.component.scss'],
})
export class DetallesMantenimientoComponent implements OnInit {
  public loading = true;

  public mantenimiento?: IMantenimiento;

  public id?: string | null;

  constructor(
    private route: ActivatedRoute,
    private service: MantenimientosService,
    public helper: HelperService
  ) {}

  //
  private async listar() {
    if (this.id) {
      this.mantenimiento = await this.service
        .getById(this.id)
        .pipe(take(1))
        .toPromise();
      console.log(this.mantenimiento);
    }
  }

  async ngOnInit() {
    this.loading = true;
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await Promise.all([this.listar()]);
    this.loading = false;
  }
}
