import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListarClientesComponent } from './listar-clientes/listar-clientes.component';
import { CrearEditarClienteComponent } from './crear-editar-cliente/crear-editar-cliente.component';
import { ClientesRoutingModule } from './clientes.routing.module';
import { UploadImageComponent } from './crear-editar-cliente/upload-image/upload-image.component';
import { ImportarComponent } from './crear-editar-cliente/importar/importar.component';

@NgModule({
  declarations: [
    ListarClientesComponent,
    CrearEditarClienteComponent,
    UploadImageComponent,
    ImportarComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, ClientesRoutingModule],
  exports: [UploadImageComponent],
})
export class ClientesModule {}
