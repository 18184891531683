<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Clientes </span></h2>

<div class="table-container mat-elevation-z2">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[estado, tipo]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" [routerLink]="'crear'">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna icono  -->
    <ng-container matColumnDef="icono">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Icono
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Icono:</span>
        <img [src]="row.imagenes.icono" alt="Icono" width="50" height="50" />
      </mat-cell>
    </ng-container>

    <!-- Columna nombre  -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nombre
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Nombre:</span>
        <div>
          <p style="margin: 0">
            {{ row.tipo }}
          </p>
          <p>
            <strong>{{ row.nombre }}</strong>
          </p>
          <p style="margin: 0">
            App Mobile:
            <span *ngIf="row.nombreAppMobile">
              {{ row.nombreAppMobile }}
            </span>
            <span *ngIf="!row.nombreAppMobile" class="no-info">
              Sin configurar
            </span>
          </p>
          <p style="margin: 0">
            Twitter:
            <span *ngIf="row.twitter">
              {{ row.twitter }}
            </span>
            <span *ngIf="!row.twitter" class="no-info"> Sin configurar </span>
          </p>
          <p style="margin: 0">Edad > {{ row.edadMinima }}</p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Config Boton  -->
    <ng-container matColumnDef="configBoton">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Config. App Botón
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Config. App Botón:</span>
        <div>
          <p
            [class.label-green]="row.configuracion?.verContactos"
            [class.label-red]="!row.configuracion?.verContactos"
          >
            Contactos
          </p>
          <p
            [class.label-green]="row.configuracion?.verReclamos"
            [class.label-red]="!row.configuracion?.verReclamos"
          >
            Reclamos
          </p>
          <p
            [class.label-green]="row.configuracion?.verEventos"
            [class.label-red]="!row.configuracion?.verEventos"
          >
            Eventos
          </p>
          <p
            [class.label-green]="row.configuracion?.verTurnos"
            [class.label-red]="!row.configuracion?.verTurnos"
          >
            Turnos
          </p>
          <p
            [class.label-green]="row.configuracion?.verEstacionamientoMedido"
            [class.label-red]="!row.configuracion?.verEstacionamientoMedido"
          >
            Estacionamiento Medido
          </p>
          <p
            style="margin: 0"
            [class.label-green]="row.configuracion?.mostrarMapa"
            [class.label-red]="!row.configuracion?.mostrarMapa"
          >
            Mapa Cobertura
          </p>
          <p
            style="margin: 0"
            [class.label-green]="row.configuracion?.mostrarEstadoSirenas"
            [class.label-red]="!row.configuracion?.mostrarEstadoSirenas"
          >
            Estado Sirenas
          </p>
          <p
            style="margin: 0"
            [class.label-green]="row.configuracion?.mostrarSirenasOffline"
            [class.label-red]="!row.configuracion?.mostrarSirenasOffline"
          >
            Estado Sirenas Offline
          </p>
          <p
            style="margin: 0"
            [class.label-green]="row.configuracion?.notificacionesSirenas"
            [class.label-red]="!row.configuracion?.notificacionesSirenas"
          >
            Notificacion uso de sirenas
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Config Monitoreo  -->
    <ng-container matColumnDef="configMonitoreo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Config. Monitoreo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Config. App Botón:</span>
        <div>
          <p>
            <strong>
              {{ row.configuracion?.nombreAppMonit || "COM " + row.nombre }}
            </strong>
          </p>
          <p
            [class.label-green]="row.configuracion?.verActualizaciones"
            [class.label-red]="!row.configuracion?.verActualizaciones"
          >
            Ver actualizaciones de firmware
          </p>
          <p
            [class.label-green]="row.configuracion?.verSirenasOnline"
            [class.label-red]="!row.configuracion?.verSirenasOnline"
          >
            Ver Sirenas offline como online
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna tipo  -->
    <ng-container matColumnDef="tipo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tipo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Tipo:</span>
        {{ row.tipo }}
      </mat-cell>
    </ng-container>

    <!-- Columna edadMinima  -->
    <ng-container matColumnDef="edadMinima">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Edad Mínima
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Edad Mínima:</span>
        {{ row.edadMinima }}
      </mat-cell>
    </ng-container>

    <!-- Columna twitter  -->
    <ng-container matColumnDef="twitter">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Twitter
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Twitter:</span>
        {{ row.twitter }}
      </mat-cell>
    </ng-container>

    <!-- Columna admin  -->
    <ng-container matColumnDef="admin">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Admin
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Admin:</span>
        <mat-icon *ngIf="row.admin" color="accent">check</mat-icon>
        <mat-icon *ngIf="!row.admin" color="warn">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna sirenas  -->
    <ng-container matColumnDef="sirenas">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Sirenas </mat-header-cell
      >>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Sirenas:</span>
        <div>
          <p [class.label-green]="row.sirenas" [class.label-red]="!row.sirenas">
            Tiene Sirenas
          </p>
          <p
            [class.label-green]="row.configuracion?.actualizacionesFirmware"
            [class.label-red]="!row.configuracion?.actualizacionesFirmware"
          >
            Actualizacion automática de firmware
          </p>
          <p style="margin: 0">
            Radio de acción:
            {{ row.configuracion?.distanciaCobertura || 200 }} mts
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna categoria default  -->
    <ng-container matColumnDef="categoriaDefault">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Categoría por Defecto
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Categoría por Defecto :</span>
        {{ row.categoriaDefault?.nombre }}
        {{
          row.categoriaDefault?.variante
            ? "(" + row.categoriaDefault?.variante + ")"
            : ""
        }}
      </mat-cell>
    </ng-container>

    <!-- Columna categorias  -->
    <ng-container matColumnDef="categorias">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Otras Categorías
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Otras Categorías:</span>
        <div class="p-2">
          <p>
            <strong>
              {{ row.categoriaDefault?.nombre }}
              {{
                row.categoriaDefault?.variante
                  ? "(" + row.categoriaDefault?.variante + ")"
                  : ""
              }}
            </strong>
          </p>
          <p
            class="m-0"
            *ngFor="let categoria of row.categorias; let i = index"
          >
            <ng-container
              *ngIf="
                row.categoriaDefault?.nombre !== categoria.categoria?.nombre
              "
            >
              {{ categoria.categoria?.nombre }}
              {{
                categoria.categoria?.variante
                  ? "(" + categoria.categoria?.variante + ")"
                  : ""
              }}
            </ng-container>
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>>
      <mat-cell *matCellDef="let row" class="nowrap">
        <span class="mobile-label">Acciones:</span>
        <div *ngIf="!row.admin">
          <button
            class="mx-1 no-shadow"
            mat-mini-fab
            color="accent"
            matTooltip="Editar"
            [routerLink]="['editar', row._id]"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="row.activo"
            class="mx-1"
            mat-mini-fab
            matTooltip="Desactivar"
            color="warn"
            (click)="desactivar(row)"
          >
            <mat-icon>cancel_schedule_send</mat-icon>
          </button>
          <button
            *ngIf="!row.activo"
            class="mx-1"
            mat-mini-fab
            matTooltip="Activar"
            color="primary"
            (click)="activar(row)"
          >
            <mat-icon>send</mat-icon>
          </button>
          <button
            class="mx-1 no-shadow"
            mat-mini-fab
            color="warn"
            matTooltip="Eliminar"
            (click)="eliminar(row)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
