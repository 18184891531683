/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GeoJSONType, ICliente, ICoordenadas } from 'modelos/src';

import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { TextareaComponent } from 'src/app/auxiliares/textarea/textarea/textarea.component';
import { AuthService } from 'src/app/entidades/login/auth.service';
import { VecinosService } from 'src/app/entidades/vecinos/vecinos.service';

@Component({
  selector: 'app-importar',
  templateUrl: './importar.component.html',
  styleUrls: ['./importar.component.scss'],
})
export class ImportarComponent implements OnInit {
  @ViewChild('textarea') textarea?: TextareaComponent;

  public loading = false;
  public form?: UntypedFormGroup;
  public cliente?: ICliente;
  public vecinos?: string[];
  public archivoCsv: any = [];
  public primerLineaEncabezado: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ImportarComponent>,
    private helper: HelperService,
    private auth: AuthService,
    private vecinosService: VecinosService,
  ) {}

  //

  private createForm(): void {
    this.form = this.fb.group({
      geojson: ['', [Validators.required]],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //
  private getData() {
    const geojson = JSON.parse(this.form?.value.geojson) as IGeoJSON;
    const data: Geometry = {
      type: 'MultiPolygon',
      coordinates: geojson.geometries![0].coordinates!,
    };
    // Convierto a GOOGLE COORDINATES
    const poligono = this.convertMultiPolygonToGoogleCoords(data);

    return poligono;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      this.dialogRef.close(data);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.cliente = this.auth.getUsuario().cliente;
    console.log(this.cliente);
    this.createForm();
    this.loading = false;
  }

  async cargarInput(event: any) {
    const file = event.target.files[0];
    const texto = await this.readFileContent(file);
    this.form?.patchValue({
      geojson: texto,
    });
  }

  async readFileContent(file: any): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target?.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  public convertMultiPolygonToGoogleCoords(
    multiPolygon: Geometry,
  ): ICoordenadas[][] {
    const poligonos: ICoordenadas[][] = [];

    multiPolygon.coordinates![0].forEach((ringCoords) => {
      const poligono: ICoordenadas[] = [];

      ringCoords.forEach((coordPair) => {
        const latLng = { lat: coordPair[1], lng: coordPair[0] };
        poligono.push(latLng);
      });

      poligonos.push(poligono);
    });

    return poligonos;
  }
}

export interface IGeoJSON {
  type?: string;
  geometries?: Geometry[];
}

export interface Geometry {
  type?: GeoJSONType;
  coordinates?: [number, number][][][];
}
