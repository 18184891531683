<div class="mx-3 mat-elevation-z2">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="1em"
    style="padding: 0.5em 1em"
  >
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start end"
      fxLayoutGap="1em"
      style="padding: 0 1em"
    >
      <!-- Select Cliente -->
      <ng-select
        style="width: 200px"
        [items]="clientes"
        [(ngModel)]="idCliente"
        [hideSelected]="true"
        placeholder="Cliente"
        [searchable]="true"
        bindLabel="nombre"
        bindValue="_id"
        (change)="listar()"
      >
      </ng-select>

      <!-- Firmware -->
      <mat-form-field style="width: 200px">
        <mat-label>Versión de Firmware</mat-label>
        <input
          #firmwareSearch
          matInput
          placeholder="1.10"
          [(ngModel)]="firmware"
        />
        <button
          *ngIf="firmware"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="$event.stopPropagation(); firmware = undefined; listar()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Estado -->
      <mat-form-field style="width: 200px">
        <mat-label>Estado</mat-label>
        <mat-select (selectionChange)="listar()" [(ngModel)]="estado">
          <mat-option [value]="true">Online</mat-option>
          <mat-option [value]="false">Offline</mat-option>
        </mat-select>
        <button
          *ngIf="estado || estado === false"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="$event.stopPropagation(); estado = undefined; listar()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <h2 style="margin: 0">Total Sirenas: {{ totalCount }}</h2>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-around center">
    <!-- Sirenas por cliente -->
    <div *ngIf="chartOptionsCliente">
      <app-chart
        [(options)]="chartOptionsCliente"
        style="
          width: 350px;
          max-width: 90%;
          height: 400px;
          display: block;
          margin-top: 30px;
          margin: auto;
        "
      ></app-chart>
    </div>

    <!-- Grafico sirenas por firmware -->
    <div *ngIf="chartOptionsFirmware">
      <app-chart
        [(options)]="chartOptionsFirmware"
        style="
          width: 350px;
          max-width: 90%;
          height: 400px;
          display: block;
          margin-top: 30px;
          margin: auto;
        "
      ></app-chart>
    </div>

    <!-- Sirenas por estado online -->
    <div *ngIf="chartOptionsOnline">
      <app-chart
        [(options)]="chartOptionsOnline"
        style="
          width: 350px;
          max-width: 90%;
          height: 400px;
          display: block;
          margin-top: 30px;
          margin: auto;
        "
      ></app-chart>
    </div>
  </div>
</div>
