import { Injectable } from '@angular/core';
import {
  ICreateCategoria,
  IListado,
  IQueryParam,
  IUpdateCategoria,
  ICategoria,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriasService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ICategoria>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/categorias`, { params });
  }

  public listarPorId(id: string): Observable<ICategoria> {
    return this.http.get(`/categorias/${id}`);
  }

  public crear(dato: ICreateCategoria): Observable<ICategoria> {
    return this.http.post(`/categorias`, dato);
  }

  public editar(id: string, dato: IUpdateCategoria): Observable<ICategoria> {
    return this.http.put(`/categorias/${id}`, dato);
  }

  public eliminar(id: string): Observable<ICategoria> {
    return this.http.delete(`/categorias/${id}`);
  }
}
