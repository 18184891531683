import { Injectable } from '@angular/core';
import {
  IListado,
  IQueryParam,
  IPortico,
  ICreatePortico,
  IUpdatePortico,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class PorticosService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
    // eslint-disable-next-line prettier/prettier
  ) { }

  public listar(queryParams?: IQueryParam): Observable<IListado<IPortico>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/porticos`, { params });
  }

  public listarPorId(id: string): Observable<IPortico> {
    return this.http.get(`/porticos/${id}`);
  }

  public crear(dato: ICreatePortico): Observable<IPortico> {
    return this.http.post(`/porticos`, dato);
  }

  public editar(id: string, dato: IUpdatePortico): Observable<IPortico> {
    return this.http.put(`/porticos/${id}`, dato);
  }

  public eliminar(id: string): Observable<IPortico> {
    return this.http.delete(`/porticos/${id}`);
  }

  public subirImagen(
    file: File,
    nombreCLiente: string,
    nombreImagen: string,
    nombreCategoria?: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('nombreImagen', nombreImagen);
    formData.append('nombreCliente', nombreCLiente);
    if (nombreCategoria) {
      formData.append('nombreCategoria', nombreCategoria);
    }

    return this.http.post(`/clientes/cargarimagen`, formData);
  }

  public subirImagenPublica(
    file: File,
    fileName: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return this.http.post(`/clientes/subirImagen`, formData);
  }
}
