import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  public idle$ = new BehaviorSubject(false);
  public wake$ = new BehaviorSubject(true);

  private snackRef?: MatSnackBarRef<TextOnlySnackBar>;

  isIdle = false;
  private idleAfterSeconds = 60;
  private countDown?: NodeJS.Timeout;

  constructor(private snackBar: MatSnackBar) {
    // Setup events
    fromEvent(document, 'mousemove').subscribe(() => this.onInteraction());
    fromEvent(document, 'touchstart').subscribe(() => this.onInteraction());
    fromEvent(document, 'keydown').subscribe(() => this.onInteraction());
    //
    this.onInteraction();
  }

  public onInteraction() {
    // Is idle and interacting, emit Wake
    if (this.isIdle) {
      this.isIdle = false;
      console.log('Wake');
      this.hideIdle();
      this.wake$.next(true);
    }

    // User interaction, reset start-idle-timer
    clearTimeout(this.countDown!);
    this.countDown = setTimeout(() => {
      // Countdown done without interaction - emit Idle
      this.isIdle = true;
      console.log('Idle');
      this.idle$.next(true);
      this.showIdle();
    }, this.idleAfterSeconds * 1_000);
  }

  private showIdle(): void {
    this.snackRef = this.snackBar.open('Inactivo', 'Cerrar', {
      // duration: 3000,
      panelClass: ['green-snackbar'],
    });
  }

  private hideIdle(): void {
    this.snackRef?.dismiss();
  }
}
