<div
  *ngIf="formGroup && controlName"
  [formGroup]="formGroup"
  class="mb-3"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
>
  <button
    mat-raised-button
    type="button"
    class="mb-3"
    color="primary"
    (click)="fileInput.click()"
    [disabled]="disabled"
    style="width: 170px"
  >
    <mat-icon>upload</mat-icon>
    <span>{{ label }}</span>
  </button>

  <input
    #fileInput
    matInput
    type="file"
    style="position: fixed; top: -20000px"
    accept="image/*"
    (change)="setFile($event, controlName)"
  />

  <mat-form-field fxFlex>
    <mat-label>URL {{ label }}</mat-label>
    <input #urlInput matInput [formControlName]="controlName" />
  </mat-form-field>

  <img
    [src]="imagenes.get(controlName)?.value"
    alt="Sin Imagen"
    [style.max-width]="'100px'"
  />
</div>
