import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnSelectService } from './column-select.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColumnSelectComponent } from './column-select/column-select.component';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [ColumnSelectComponent],
  imports: [CommonModule, MaterialModule, DragDropModule],
  providers: [ColumnSelectService],
  exports: [ColumnSelectComponent],
})
export class ColumnSelectModule {}
