import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrearEditarCategoriasComponent } from './crear-editar-categorias/crear-editar-categorias.component';
import { ListarCategoriasComponent } from './listar-categorias/listar-categorias.component';

const routes: Routes = [
  { path: '', component: ListarCategoriasComponent },
  { path: 'crear', component: CrearEditarCategoriasComponent, canActivate: [] },
  {
    path: 'editar/:id',
    component: CrearEditarCategoriasComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoriasRoutingModule {}
