import { Injectable } from '@angular/core';
import { ICliente, IToken, IUsuario } from 'modelos/src';
import { LoginService } from './login.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private loginService: LoginService) {}

  public isLogged() {
    if (sessionStorage.getItem('token')) {
      return true;
    }
    return false;
  }

  public getToken() {
    return sessionStorage.getItem('token') || '';
  }

  public getCliente() {
    const clienteStr = sessionStorage.getItem('cliente');
    return clienteStr ? JSON.parse(clienteStr) : null;
  }

  public setCliente(cliente?: ICliente) {
    return sessionStorage.setItem('cliente', JSON.stringify(cliente));
  }

  public getRefreshToken() {
    return sessionStorage.getItem('refreshToken') || '';
  }

  public getUsuario() {
    const usuarioStr = sessionStorage.getItem('usuario');
    if (usuarioStr) {
      const usuario: IUsuario = JSON.parse(usuarioStr);
      return usuario;
    }
    throw new Error('No se encontro el usuario');
  }

  public rolesPermitidos(roles: string[]): boolean {
    const usuario = this.getUsuario();
    return !!usuario?.roles?.some((rol) => roles.includes(rol));
  }

  public logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('usuario');
  }

  private setTokenSessionStorage(token: IToken) {
    console.log('info login', token);
    sessionStorage.setItem('token', token.accessToken!);
    sessionStorage.setItem('refreshToken', token.refreshToken || '');
  }

  private setUsuarioSessionStorage(usuario: IUsuario) {
    console.log('info login', usuario);
    sessionStorage.setItem('usuario', JSON.stringify(usuario));
    this.setCliente(usuario.cliente);
  }

  public async login(username: string, password: string): Promise<void> {
    const resp = await firstValueFrom(
      this.loginService.login(username, password)
    );

    this.setTokenSessionStorage(resp);

    const usuario = await firstValueFrom(
      this.loginService.getUsuarioPropio(resp.accessToken!)
    );

    this.setUsuarioSessionStorage(usuario);
  }

  public async refreshToken() {
    const refresh_token = this.getRefreshToken();
    const resp = await firstValueFrom(
      this.loginService.refreshToken(refresh_token)
    );

    this.setTokenSessionStorage(resp);

    const usuario = await firstValueFrom(
      this.loginService.getUsuarioPropio(resp.accessToken!)
    );

    this.setUsuarioSessionStorage(usuario);
    return resp;
  }
}
