import { Injectable } from '@angular/core';
import {
  IListado,
  IQueryParam,
  IBoton,
  ICreateBoton,
  IUpdateBoton,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class BotonesService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IBoton>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/botones`, { params });
  }

  public listarPorId(id: string): Observable<IBoton> {
    return this.http.get(`/botones/${id}`);
  }

  public listarPoridCliente(idCliente: string): Observable<IListado<IBoton>> {
    return this.http.get(`/botones/cliente/${idCliente}`);
  }

  public crear(dato: ICreateBoton): Observable<IBoton> {
    return this.http.post(`/botones`, dato);
  }

  public editar(id: string, dato: IUpdateBoton): Observable<IBoton> {
    return this.http.put(`/botones/${id}`, dato);
  }

  public eliminar(id: string): Observable<IBoton> {
    return this.http.delete(`/botones/${id}`);
  }

  public subirImagen(
    file: File,
    nombreCLiente: string,
    nombreImagen: string,
    nombreCategoria?: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('nombreImagen', nombreImagen);
    formData.append('nombreCliente', nombreCLiente);
    if (nombreCategoria) {
      formData.append('nombreCategoria', nombreCategoria);
    }

    return this.http.post(`/clientes/cargarimagen`, formData);
  }

  public subirImagenPublica(
    file: File,
    fileName: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return this.http.post(`/clientes/subirImagen`, formData);
  }
}
