<button mat-button [matMenuTriggerFor]="columnMenu">
  <mat-icon>{{ icon }}</mat-icon>
  <span>{{ !mobileQuery.matches ? text : "" }}</span>
</button>

<mat-menu class="acciones" #columnMenu="matMenu">
  <div cdkDropList class="list" (click)="$event.stopPropagation()">
    <div
      class="box"
      *ngFor="let column of columnInfo; let i = index"
      cdkDrag
      [cdkDragData]="{ columnIndex: i, columnTitle: column.id }"
      (cdkDragDropped)="columnMenuDropped($event)"
      [cdkDragDisabled]="!drag"
    >
      <div
        style="display: flex; align-items: center"
        fxLayout="row"
        fxFill
        fxLayoutGap="12px"
      >
        <div class="custom-placeholder" *cdkDragPlaceholder></div>
        <mat-icon
          *ngIf="drag"
          cdkDragHandle
          color="primary"
          [ngClass]="{ 'cursor-move': drag }"
        >
          drag_indicator
        </mat-icon>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="toggleSelectedColumn(column.id)"
          [checked]="!column.hidden"
        >
          {{ column.name }}
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-menu>
