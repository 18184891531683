import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  IBoton,
  ICategoria,
  ICreateCategoria,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { CategoriasService } from '../categorias.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { CrearEditarCategoriasComponent } from '../crear-editar-categorias/crear-editar-categorias.component';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';

@Component({
  selector: 'app-listar-categorias',
  templateUrl: './listar-categorias.component.html',
  styleUrls: ['./listar-categorias.component.scss'],
})
export class ListarCategoriasComponent implements OnInit, OnDestroy {
  public search: IRegExpSearch = {
    fields: ['nombre', 'variante'],
  };

  public loading = true;
  public columnas = [
    'nombre',
    'nombreTemplate',
    'variante',
    'prioridad',
    'color',
    'twitter',
    'btnsPrincipales',
    'btnsSecundarios',
    'otrosBotones',
    'botonAlertaControl',
    'botonesRf',
    'acciones',
  ];
  public nombreColumnas = [
    'Nombre',
    'Nombre Template',
    'Variante',
    'Prioridad',
    'Color',
    'Twitter',
    'Btns Principales',
    'Btns Secundarios',
    'Otros Botones',
    'Control RF',
    'Botones RF',
    'Acciones',
  ];
  public saveColumnas = 'tabla-categorias';
  public dataSource = new MatTableDataSource<ICategoria>([]);
  // FILTRO BUSQUEDA Y PAGINACION
  private populate = [
    {
      path: 'btnsPrincipales',
      select: 'nombre variante',
    },
    {
      path: 'btnsSecundarios',
      select: 'nombre variante',
    },
    {
      path: 'otrosBotones',
      select: 'nombre variante',
    },
    {
      path: 'botonAlertaControl',
      select: 'nombre variante',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
  };
  public totalCount = 0;
  // Listado Continuo
  public listarContinuo = true;
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //
  public botones: IBoton[] = [];
  private botones$?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: CategoriasService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }
  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }
  public async cambioFiltro(filters: IFilter<ICategoria>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
    };
    this.matDialog.open(CrearEditarCategoriasComponent, config);
  }

  public async editar(data: ICategoria) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(CrearEditarCategoriasComponent, config);
  }

  public async eliminar(dato: ICategoria): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la categoría ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        this.loading = true;
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  public async clonar(data: ICategoria) {
    const response = await this.dialogService.prompt(
      'Confirme la acción',
      `¿Clonar la categoría <strong>${data.nombre} ${
        data.variante ? data.variante : ''
      }</strong>?`,
      ['Nombre', 'Variante'],
      [data.nombre!, data.variante!],
    );
    const nombre = response ? response[0] : '';
    const variante = response ? response[1] : '';
    if (nombre) {
      const create: ICreateCategoria = {
        nombre,
        variante,
        nombreTemplate: data.nombreTemplate,
        prioridad: data.prioridad,
        color: data.color,
        editable: data.editable,
        idBotonAlertaControl: data.idBotonAlertaControl,
        idsBtnsPrincipales: data.idsBtnsPrincipales,
        idsBtnsSecundarios: data.idsBtnsSecundarios,
        idsOtrosBotones: data.idsOtrosBotones,
        tercerBoton: data.tercerBoton,
        twitter: data.twitter,
      };

      try {
        await firstValueFrom(this.service.crear(create));
        this.helper.notifSuccess(
          `Categoría clonada correctamente con el nombre ${nombre}`,
        );
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
      }
    }
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ICategoria>>('categorias', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(
          new Date().toLocaleString(),
          `listado de categorias`,
          data,
        );
      });
    await this.listadosService.getLastValue('categorias', this.queryParams);
  }

  private async listarBotones(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      limit: 0,
    };
    this.botones$?.unsubscribe();
    this.botones$ = this.listadosService
      .subscribe<IListado<IBoton>>('botones', query)
      .subscribe((data) => {
        this.botones = data.datos;
        console.debug(new Date().toLocaleString(), `listado de botones`, data);
      });
    await this.listadosService.getLastValue('botones', query);
  }

  public parseBotonRf(b: string): string {
    if (b === 'Reflector') return 'Reflector';
    if (b === 'Sirena') return 'Sirena';
    const boton = this.botones.find((btn) => btn._id === b);
    if (boton?.nombre) {
      return boton.nombre;
    } else {
      return 'Sin Configurar';
    }
  }

  //

  async ngOnInit(): Promise<void> {
    await Promise.all([this.actualizar(), this.listarBotones()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
  }
}
