import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { EnviarMensajeComponent } from './enviar-mensajes/enviar-mensajes.component';
import { MensajesRoutingModule } from './mensajes.routing.module';

@NgModule({
  declarations: [EnviarMensajeComponent],
  imports: [CommonModule, AuxiliaresModule, MensajesRoutingModule],
})
export class MensajesModule {}
