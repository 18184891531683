import { Injectable } from '@angular/core';
import {
  IApiKey,
  ICreateApiKey,
  IQueryParam,
  IUpdateApiKey,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiKeysService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<{ totalCount: number; datos: IApiKey[] }> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/apikeys`, { params });
  }

  public listarPorId(id: string): Observable<IApiKey> {
    return this.http.get(`/apikeys/${id}`);
  }

  public listarPorApiKey(apikey: string): Observable<IApiKey> {
    return this.http.get(`/apikeys/apikey/${apikey}`);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/apikeys/${id}`);
  }

  public crear(dato: ICreateApiKey): Observable<IApiKey> {
    return this.http.post(`/apikeys`, dato);
  }

  public editar(id: string, dato: IUpdateApiKey): Observable<IApiKey> {
    return this.http.put(`/apikeys/${id}`, dato);
  }
}
