import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoginRoutingModule } from './login.routing.module';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    LoginRoutingModule,
  ],
  providers: [
    AuthService,
    LoginService,
  ],
})
export class LoginModule { }
