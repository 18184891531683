import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ICliente } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { TextareaComponent } from '../../../auxiliares/textarea/textarea/textarea.component';
import {
  Mensaje,
  MensajeWhatsAppTemplate,
  MensajesService,
} from '../mensajes.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-enviar-mensajes',
  templateUrl: './enviar-mensajes.component.html',
  styleUrls: ['./enviar-mensajes.component.scss'],
})
export class EnviarMensajeComponent implements OnInit {
  @ViewChild('textarea') textarea?: TextareaComponent;

  public loading = false;
  public form?: UntypedFormGroup;
  public cliente?: ICliente;
  public categorias?: string[];
  public tags?: string[];
  private validadorCodigoPais = /(\+)\d{2}/;
  private validadorTelefono =
    /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
  public servicios = ['SMS', 'WHATSAPP'];

  constructor(
    private fb: UntypedFormBuilder,
    private helper: HelperService,
    private service: MensajesService,
  ) {}

  public getErrorMessage(): void {
    if (this.form?.get('csv')?.hasError('error')) {
      return this.form?.get('csv')?.getError('error');
    }
    return;
  }

  private separarEnLineas(input: string): string[] | void {
    input = input.trim();
    const returnArray = [];
    if (input) {
      let inputArray: string[] = input.split('\n');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim,
      );
      for (const linea of inputArray) {
        if (linea) {
          returnArray.push(linea);
        }
      }
      if (returnArray.length) {
        return returnArray;
      }
    }
    return;
  }

  private parsearLinea(input: string): string[] | void {
    input = input.trim();
    if (input) {
      let inputArray: string[] = input.split(';');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim,
      );
      return inputArray;
    }
    return;
  }

  private validarTextarea(): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      const input: string = control.value;
      const lineas = this.separarEnLineas(input);
      if (lineas) {
        for (let i = 0; i < lineas.length; i++) {
          const linea = lineas[i];
          const lineaParseada = this.parsearLinea(linea);
          if (!lineaParseada) {
            return { error: `Linea ${i + 1}. Formato incorrecto` };
          }
          if (lineaParseada.length !== 2 && lineaParseada.length !== 1) {
            return {
              error: `Linea ${i + 1}. Cantidad de parametros ${
                lineaParseada.length
              } incorrecta. Se requieren 1 o 2`,
            };
          }
          const telefono = lineaParseada[0];
          if (!this.validarTelefono(telefono)) {
            return {
              error: `Linea ${i + 1}. Teléfono inválido.`,
            };
          }
        }
      }
      return null;
    };
  }

  //

  private createForm(): void {
    this.form = this.fb.group({
      csv: ['', [Validators.required, this.validarTextarea()]],
      servicio: ['SMS'],
      mensaje: [],
      messagingServiceSid: [],
      contentSid: [],
    });
  }

  //
  private getCreateData() {
    const servicio = this.form?.get('servicio')?.value;
    const mensaje = this.form?.get('mensaje')?.value || '';
    const contentSid = this.form?.get('contentSid')?.value || '';
    const messagingServiceSid =
      this.form?.get('messagingServiceSid')?.value || '';
    //
    if (servicio === 'WHATSAPP') {
      if (!contentSid) {
        this.helper.notifWarn(`Debe ingresar un Content Sid`);
        return;
      }
      if (!messagingServiceSid) {
        this.helper.notifWarn(`Debe ingresar un Messaging Service Sid`);
        return;
      }
      const data: MensajeWhatsAppTemplate = {
        messagingServiceSid,
        contentSid,
        servicio,
      };
      return data;
    }
    //
    if (servicio === 'SMS') {
      if (!mensaje) {
        this.helper.notifWarn(`Debe ingresar un mensaje`);
        return;
      }
      const data: Mensaje = {
        mensaje,
        servicio,
        masivo: true,
      };
      return data;
    }
    return;
  }

  private agregarTelefono(
    data: Mensaje | MensajeWhatsAppTemplate,
    linea: string,
  ) {
    const lineaParseada = this.parsearLinea(linea);
    // Teléfono* ; País ; Mensaje
    const telefono = lineaParseada?.[0] || '';
    const pais = lineaParseada?.[1] || '+54';

    data.pais = pais;
    data.telefono = telefono;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const textArea = this.form?.get('csv')?.value;
      const lineas = this.separarEnLineas(textArea);
      const data = this.getCreateData();
      if (!data) {
        this.loading = false;
        return;
      }
      //
      let enviados = 0;
      if (lineas?.length) {
        this.textarea?.scrollTop();
        for (const linea of lineas) {
          try {
            this.agregarTelefono(data, linea);
            if (data.servicio === 'SMS') {
              await firstValueFrom(this.service.enviarMensaje(data as Mensaje));
            }
            if (data.servicio === 'WHATSAPP') {
              await firstValueFrom(
                this.service.enviarMensajeWhatsappTemaplte(
                  data as MensajeWhatsAppTemplate,
                ),
              );
            }
            await this.esperar(100);
            this.textarea?.eliminarLineaDelTextArea(linea);
            enviados++;
          } catch (err) {
            console.error(`Error al enviar el mensaje: ${linea}`);
            console.error(err);
          }
        }
        if (enviados) {
          if (enviados === lineas.length) {
            this.helper.notifSuccess(
              `Se enviaron ${enviados} de ${lineas?.length} mensajes`,
            );
          } else {
            this.helper.notifWarn(
              `Se enviaron ${enviados} de ${lineas?.length} mensajes`,
            );
          }
        } else {
          this.helper.notifError('No se crearon eventos');
        }
      }
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.loading = false;
  }

  public toggleForm(value: string): void {
    const form = this.form?.get('mensaje');
    if (form) {
      if (value.toLowerCase() === 'sms') {
        form.enable();
      } else {
        form.disable();
      }
    }
  }

  // Private
  private validarTelefono(tel: string): boolean {
    return this.validadorTelefono.test(tel);
  }

  private async esperar(tiempo: number): Promise<boolean> {
    return new Promise((res) => {
      setTimeout(() => {
        res(true);
      }, tiempo);
    });
  }

  // private validarMensaje(mensaje: string): boolean {
  //   for (const template of this.templates) {
  //     if (template.validador?.test(mensaje)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  private validarCodigoPais(codigo: string): boolean {
    return this.validadorCodigoPais.test(codigo) ? true : false;
  }
}
