<div class="table-container mat-elevation-z2 mx-1 mt-4">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [datos]="[evento, fecha]"
      [search]="search"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
  </div>

  <!-- Pagination -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha  -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha de encendido
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha de encendido:</span>
        {{ row.fecha | date : "short" }}
      </mat-cell>
    </ng-container>

    <!-- Columna evento  -->
    <ng-container matColumnDef="evento">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Evento
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Evento:</span>
        {{ row.evento }}
      </mat-cell>
    </ng-container>

    <!-- Columna mensaje  -->
    <ng-container matColumnDef="mensaje">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Mensaje
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Mensaje:</span>
        <span style="text-overflow: ellipsis; max-width: 100%">
          {{ row.mensaje }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna origen  -->
    <ng-container matColumnDef="origen">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Origen
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Origen:</span>
        {{ row.origen }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>

  <!-- Pagination -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>
</div>
