import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { IFilter, IQueryParam } from 'modelos/src';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { APPEARANCE } from '../../../../environments/contantes';
import { ListadosService } from '../../servicios/listados.service';

export interface IFiltroTabla {
  // Campos comunes
  filter: IFieldFilter;
  label: string; // Etiqueta para mostrar
  tipo:
    | 'select'
    | 'input'
    | 'dateRange'
    | 'ngselect-async'
    | 'ngselect'
    | 'mongo'
    | 'regexp'; // Tipo de campo a mostrar
  // Para select
  elementos?: any[]; // Arreglo de elementos (para el select)
  selectValue?: string; // Valor a seleccionar de los elementos en el select (para select)
  selectLabel?: string; // Valor a mostrar de los elementos en el select (para select)
  multiple?: boolean; // Para select multiple
  // Para dateRange
  desde?: string;
  hasta?: string;
  // Para ngselect-async
  asyncFunction?: string;
  searchOn?: string[];
  populate?: string;
}

export interface IRegExpSearch {
  fields: string[];
  value?: string;
}

export interface IFieldFilter {
  field: string;
  value?: any;
}

@Component({
  selector: 'app-filtro-tabla',
  templateUrl: './filtro-tabla.component.html',
  styleUrls: ['./filtro-tabla.component.scss'],
})
export class FiltroTablaComponent implements OnInit {
  @ViewChildren('inputSearch') inputSearch?: ElementRef[];
  @Input() datos: IFiltroTabla[] = [];
  @Input() search?: IRegExpSearch;
  @Output() onFilterChange: EventEmitter<IFilter<any>> = new EventEmitter<
    IFilter<any>
  >();

  public appearance = APPEARANCE;

  public asyncData$?: Observable<any[]>[] = [];
  public asyncInput$?: Subject<string>[] = [];

  public filtroActivo: boolean = false;

  public placeholder = JSON.stringify({ field: { $ne: 'value' } });

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private listadosService: ListadosService,
    private breakpointObserver: BreakpointObserver
  ) {}

  public clear() {}

  private crearQuery(): IFilter<any> {
    const filters: IFilter<any> = {
      $and: [],
    };
    this.datos.forEach((dato) => {
      if (
        dato.tipo === 'input' ||
        dato.tipo === 'select' ||
        dato.tipo === 'ngselect-async' ||
        dato.tipo === 'ngselect'
      ) {
        if (typeof dato.filter.value !== 'undefined' && dato.filter.field) {
          filters['$and']!.push({ [dato.filter.field]: dato.filter.value });
        }
      } else if (dato.tipo === 'dateRange') {
        if (dato.filter.field && (dato.desde || dato.hasta)) {
          const filtroFecha: { $and: object[] } = {
            $and: [],
          };
          if (dato.desde) {
            const date = new Date(dato.desde);
            date.setHours(0, 0, 0, 0);
            filtroFecha.$and.push({
              [dato.filter.field]: { $gte: date.toISOString() },
            });
          }
          if (dato.hasta) {
            const date = new Date(dato.hasta);
            date.setHours(23, 59, 59, 999);
            filtroFecha.$and.push({
              [dato.filter.field]: { $lte: date.toISOString() },
            });
          }
          filters['$and']!.push(filtroFecha as any);
        }
      } else if (dato.tipo === 'regexp') {
        if (dato.filter.field && dato.filter.value) {
          filters['$and']!.push({
            [dato.filter.field]: { $regex: dato.filter.value, $options: 'i' },
          });
        }
      } else if (dato.tipo === 'mongo' && dato.filter.value) {
        try {
          const value = JSON.parse(dato.filter.value);
          if (value) {
            filters['$and']!.push(value);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
    if (this.search?.value) {
      const search: any = {
        $or: [],
      };
      for (const field of this.search.fields) {
        search.$or.push({
          [field]: { $regex: this.search.value, $options: 'i' },
        });
      }
      filters['$and']!.push(search);
    }
    if (filters['$and']!.length === 0) {
      delete filters['$and'];
    }
    return filters;
  }

  public updatefiltroActivo() {
    const selected = this.datos.find(
      (dato) => dato.filter.value || dato.desde || dato.hasta
    );
    this.filtroActivo = selected ? true : false;
  }
  public async cambioFiltro() {
    this.updatefiltroActivo();
    const query = this.crearQuery();
    this.onFilterChange.emit(query);
  }

  public async limpiarFiltros() {
    this.datos.forEach((dato) => {
      dato.filter.value = undefined;
      dato.desde = undefined;
      dato.hasta = undefined;
    });
    this.cambioFiltro();
  }

  private initSearchChange(): void {
    if (this.inputSearch?.length) {
      this.inputSearch.forEach((input, index) => {
        fromEvent(input.nativeElement, 'keyup')
          .pipe(
            map((e: any) => e.target.value),
            debounceTime(500),
            distinctUntilChanged()
          )
          .subscribe((text: string) => {
            this.cambioFiltro();
          });
      });
    }
  }

  private crearFiltro(search: string, i: number): IQueryParam {
    const filtro: IFilter<any> = {};
    if (search && this.datos?.[i]?.searchOn?.length) {
      filtro.$or = [];

      for (const field of this.datos[i].searchOn!) {
        filtro.$or.push({
          [field]: { $regex: search, $options: 'i' },
        });
      }
    }
    const query: IQueryParam = {
      filter: JSON.stringify(filtro),
      limit: 10,
      populate: this.datos[i].populate,
    };
    return query;
  }

  private onSearch(i: number) {
    this.asyncData$![i] = of([]);
    this.asyncInput$![i] = new Subject<string>();
    this.asyncInput$![i].pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return (this.listadosService as any)[this.datos[i].asyncFunction!](
          this.crearFiltro(term, i)
        );
      })
    ).subscribe((data: any) => {
      // this.data = data;
      this.asyncData$![i] = of(data);
    });
  }

  ngOnInit(): void {
    this.datos.forEach((e, index) => {
      if (e.tipo === 'ngselect-async') {
        this.onSearch(index);
      }
    });
  }

  ngAfterViewInit() {
    this.initSearchChange();
  }
}
