<div class="menu-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary">
    <!-- Menu -->
    <button class="ml-3" mat-icon-button (click)="snav.toggle()">
      <mat-icon style="color: white">menu</mat-icon>
    </button>

    <!-- Texto -->
    <span>Admin Panel</span>
    <!-- <h1 style="color: white">Admin Panel</h1> -->

    <span class="spacer"></span>

    <!-- Instalar app -->
    <button
      *ngIf="this.pwa.promptEvent"
      class="mr-3"
      mat-mini-fab
      color="accent"
      matTooltip="Instalar APP"
      matTooltipPosition="left"
      (click)="this.pwa.installPwa()"
    >
      <mat-icon style="color: white">get_app</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav
      #snav
      [opened]="mobileQuery.matches ? false : true"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <!-- <img class="side-image" [src]="side" alt=""> -->
      <mat-nav-list fxLayout="column" fxFill>
        <!-- Avatar -->
        <div class="user" fxLayout="column" fxLayoutGap="10px">
          <div class="email">{{ usuario }}</div>

          <div class="avatar-container">
            <button mat-fab color="accent" [matMenuTriggerFor]="menu">
              <span class="iniciales">{{ iniciales }}</span>
            </button>
            <mat-menu #menu="matMenu">
              <div
                class="boton-menu mt-2"
                fxLayoutAlign="space-between center"
                (click)="salir()"
              >
                <span>Salir </span>
                <mat-icon style="vertical-align: middle; margin-left: 10px"
                  >exit_to_app</mat-icon
                >
              </div>
            </mat-menu>
          </div>

          <div class="rol">{{ roles }}</div>
        </div>

        <!-- Elementos Menu -->
        <div fxLayout="column" fxLayoutAlign="space-between" fxFill fxFlex>
          <div class="navbar-content">
            <br />

            <!-- Menu  -->
            <ng-container>
              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['dashboard']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>dashboard</mat-icon>
                <span>Dashboard</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['clientes']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>group</mat-icon>
                <span>Clientes</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['usuarios']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>groups</mat-icon>
                <span>Usuarios</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['vecinos']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>groups</mat-icon>
                <span>Vecinos</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['botones']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>radio_button_checked</mat-icon>
                <span>Botones</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['categorias']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>category</mat-icon>
                <span>Categorías</span>
              </p>

              <p
                *ngIf="
                  helper.tieneAlgunRol(['admin', 'Administrador de sirenas'])
                "
                class="cursor-pointer"
                [routerLink]="['sirenas']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>volume_up</mat-icon>
                <span>Sirenas</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['apikeys']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>key</mat-icon>
                <span>Api Keys</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['actualizacionfirmwares']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>memory</mat-icon>
                <span>Act firm</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['mapa']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>map</mat-icon>
                <span>Mapa</span>
              </p>

              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['controles']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>settings_remote</mat-icon>
                <span>Controles</span>
              </p>
              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['mensajes']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>email</mat-icon>
                <span>Mensajes</span>
              </p>
              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['exportar']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>file_download</mat-icon>
                <span>Exportar</span>
              </p>
              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['mantenimiento']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>engineering</mat-icon>
                <span>Mantenimiento</span>
              </p>
              <p
                *ngIf="helper.tieneAlgunRol(['admin'])"
                class="cursor-pointer"
                [routerLink]="['porticos']"
                routerLinkActive="active"
                (click)="mobileQuery.matches ? snav.toggle() : null"
              >
                <mat-icon>camera</mat-icon>
                <span>Pórticos</span>
              </p>
            </ng-container>

            <!-- Menu para crear controler -->
            <ng-container *ngIf="roles?.includes('Crear Controles')">
              <p
                class="cursor-pointer"
                (click)="
                  mobileQuery.matches ? snav.toggle() : null; crearControl()
                "
              >
                <mat-icon>settings_remote</mat-icon>
                <span>Controles</span>
              </p>
            </ng-container>
            <!--  -->
          </div>
        </div>

        <!-- Version -->
        <div class="menuLateralContainer">
          <p style="cursor: pointer" (click)="abrirChangelog()">Changelog</p>
          <p style="text-align: center">
            Versión {{ version }}
            <span *ngIf="env !== 'prod'">{{ env }}</span>
          </p>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- <div class="footer">
        <img class="logo-footer" src="" alt="">
        2021 © xxx S.A.
    </div> -->
</div>
