<input
  #inputDireccion
  class="direccion"
  type="text"
  placeholder="Avenida Siempre Viva 742 / Escuela San Benito"
  [(ngModel)]="direccion"
  [ngModelOptions]="{ standalone: true }"
  [matAutocomplete]="auto"
/>
<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let p of preddiciones" [value]="p" (click)="onClick()">
    {{ p }}
  </mat-option>
</mat-autocomplete>
