<h2 class="m-4"><span class="titulo">Enviar Mensajes </span></h2>

<mat-card>
  <!-- <mat-card-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    Importar Eventos
  </mat-card-title> -->
  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Servicio -->
      <ng-select
        [items]="servicios"
        formControlName="servicio"
        [hideSelected]="true"
        placeholder="Servicio de Mensajería"
        [searchable]="false"
        required
      >
      </ng-select>

      <!-- Mensaje SMS-->
      @if (this.form?.get("servicio")?.value === "SMS") {
        <mat-form-field>
          <mat-label>Mensaje</mat-label>
          <textarea
            matInput
            type="text"
            matInput
            formControlName="mensaje"
            rows="12"
          ></textarea>

          <mat-hint *ngIf="this.form?.get('servicio')?.value === 'WHATSAPP'">
            Los templates de Whatsapp no deben ser editados, ya que el templete
            no admite partes variables.
          </mat-hint>
        </mat-form-field>
      }

      <!-- Mensaje Whatsapp -->
      @if (this.form?.get("servicio")?.value === "WHATSAPP") {
        <!-- Placeholder example: MGeefd60c8b61d7a907cd4708d4ff214e3 -->
        <mat-form-field>
          <mat-label>Messaging Service SID</mat-label>
          <input matInput formControlName="messagingServiceSid" required />
        </mat-form-field>

        <!-- Placeholder example: HX359d857340b6b97634c21b2ba95bedd3 -->
        <mat-form-field>
          <mat-label>Template SID</mat-label>
          <input matInput formControlName="contentSid" required />
        </mat-form-field>
      }

      <!-- TELEFONOS -->
      <app-textarea
        #textarea
        [form]="form"
        [controlName]="'csv'"
        [ejemplo]="'Ej. 2222222222 ; +54 ;'"
        [formato]="'Teléfono*; País'"
      ></app-textarea>
    </form>
  </mat-card-content>

  <mat-divider inset></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      ENVIAR
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
