import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { MantenimientosRoutingModule } from './mantenimientos.routing.module';
import { MantenimientosComponent } from './listado/mantenimientos.component';
import { CrearEditarMantenimientoComponent } from './crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { DetallesMantenimientoComponent } from './detalles-mantenimiento/detalles-mantenimiento.component';

@NgModule({
  declarations: [
    MantenimientosComponent,
    CrearEditarMantenimientoComponent,
    DetallesMantenimientoComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, MantenimientosRoutingModule],
})
export class MantenimientosModule {}
