<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<div class="table-container mat-elevation-z2">
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Tabla -->
  <mat-table
    class="responsive"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha  -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Fecha:</span>
        {{ row.fecha | date : "short" }}
      </mat-cell>
    </ng-container>

    <!-- Columna tags  -->
    <ng-container matColumnDef="tags">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tags
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Tags:</span>
        <div class="tags">
          <p
            *ngFor="let tag of row.tags"
            [style.background-color]="helper.stringToColour(tag)"
          >
            {{ tag }}
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna chipId  -->
    <ng-container matColumnDef="chipId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        ChipID
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">ChipID:</span>
        <span>
          {{ row.sirena?.chipId }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna usuario  -->
    <ng-container matColumnDef="usuario">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Usuario
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="mobile-label">Usuario:</span>
        {{ row.usuario.nombre }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
</div>
