import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ICategoria } from 'modelos/src';
import { first } from 'rxjs/operators';
import { ClientesService } from '../../clientes.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit, OnDestroy {
  @Input() public disabled: boolean = false;
  @Input() public formulario?: UntypedFormGroup;
  @Input() public formGroup?: UntypedFormGroup;
  @Input() public formGroupName?: string;
  @Input() public controlName?: string;
  @Input() public label?: string;
  @Input() public categorias?: ICategoria[];

  get imagenes(): UntypedFormGroup {
    return this.formulario?.get('imagenes') as UntypedFormGroup;
  }
  get categoriasCliente(): UntypedFormArray {
    return this.formulario?.get('categorias') as UntypedFormArray;
  }

  constructor(private service: ClientesService) {}

  public async setFile(
    event: Event,
    formControlName: string,
    idCategoria?: string,
    index?: number
  ): Promise<void> {
    // this.loading = true;
    const file = (event as any).target?.files?.item(0);
    const nombreCliente = this.formulario?.get('nombre')?.value;
    const categoria = this.categorias?.find((c) => {
      return c._id === idCategoria;
    });
    const res = await firstValueFrom(
      this.service.subirImagen(
        file,
        nombreCliente,
        formControlName,
        categoria?.nombre
      )
    );

    const val = res.url + `?${(Math.random() * 1000).toFixed(0)}`;
    if (index || index === 0) {
      const control = this.categoriasCliente.at(index);
      control.patchValue({ [formControlName]: val });
    } else {
      const control = this.imagenes;
      control.patchValue({ [formControlName]: val });
    }
    // this.loading = false;
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
