import { Injectable } from '@angular/core';
import { IResumenClientes } from 'modelos/src';
import { Observable } from 'rxjs';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpApiAdminService) {}

  public resumenClientes(): Observable<IResumenClientes[]> {
    return this.http.get(`/clientes/resumen`);
  }
}
