import { Component, OnInit } from '@angular/core';
import {
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IVecino,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/servicios/listados.service';
import { Options, SeriesColumnOptions } from 'highcharts';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-graficos-vecinos',
  templateUrl: './graficos-vecinos.component.html',
  styleUrls: ['./graficos-vecinos.component.scss'],
})
export class GraficosVecinosComponent implements OnInit {
  public loading = true;
  public titulo = `Sirenas`;

  public dias = 30;

  public registradosHoy = 0;
  public promedioDiario = 0;
  public totalCount = 0;
  public vecinos?: IVecino[];
  public clientes: ICliente[] = [];

  public agrupacion: 'Modo de Registro' | 'Cliente' = 'Modo de Registro';

  public idCliente?: string;
  public modoRegistro = {
    $or: [{ importado: false }, { importado: { $exists: false } }],
  };
  public modosRegistro = [
    {
      value: { $or: [{ importado: true }] },
      viewValue: 'Importados',
    },
    {
      value: { $or: [{ importado: false }, { importado: { $exists: false } }] },
      viewValue: 'No Importados',
    },
    {
      value: {
        $and: [
          { creadoPorAdmin: true },
          {
            $or: [{ importado: false }, { importado: { $exists: false } }],
          },
        ],
      },
      viewValue: 'Creados por admin',
    },
    {
      value: { $and: [{ creadoPorAdmin: false }] },
      viewValue: 'Creados por vecino (Todos)',
    },
    {
      value: { $and: [{ creadoPorAdmin: false }, { dniEscaneado: true }] },
      viewValue: 'Creados por vecino (DNI)',
    },
    {
      value: { $and: [{ creadoPorAdmin: false }, { dniEscaneado: false }] },
      viewValue: 'Creados por vecino (Manual)',
    },
  ];

  public datos$?: Subscription;
  public clientes$?: Subscription;

  public chartOptionsFechaCreacion?: Options;
  public chartOptionsHoraCreacion?: Options;
  public chartOptionsHoraHoy?: Options;
  public chartOptionsEdad?: Options;

  constructor(
    private listadosService: ListadosService,
    private helperService: HelperService
  ) {}

  public async listar(): Promise<void> {
    this.loading = true;

    const HaceXDias = new Date();
    HaceXDias.setDate(HaceXDias.getDate() - this.dias);
    HaceXDias.setHours(0, 0, 0, 0);

    const filter: IFilter<IVecino> = {
      fechaCreacion: {
        $gte: new Date(HaceXDias).toISOString(),
      },
    };

    if (this.idCliente) {
      filter.idsCliente = [this.idCliente];
    } else {
      delete filter.idsCliente;
    }

    if (this.modoRegistro) {
      filter.$and = [this.modoRegistro];
    }

    const queryParams: IQueryParam = {
      select:
        'idsCliente fechaCreacion dniEscaneado creadoPorAdmin importado fechaNacimiento sexo',
      filter: JSON.stringify(filter),
      sort: 'fechaCreacion',
    };

    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IVecino>>('vecinos', queryParams, ['delete', 'post'])
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.vecinos = data.datos;
        this.titulo = `Vecinos Totales: ${this.totalCount}`;
        console.debug(new Date().toLocaleString(), `listado de vecinos`, data);
        this.hacerGraficoFechaCreacion();
        this.hacerGraficoHoraCreacion();
        this.hacerGraficoHoraCreacionHoy();
        this.hacerGraficoEdad();
        this.loading = false;
      });
    await this.listadosService.getLastValue('vecinos', queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  // Crear Graficos

  private columnChart(title: string, series: SeriesColumnOptions[]) {
    const chartOptions: Options = {
      title: {
        text: title,
      },
      plotOptions: {
        column: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.1f}',
          },
          showInLegend: true,
          minPointLength: 3,
          stacking: 'normal',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: { text: 'Cantidad' },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            fontSize: '14px',
            color:
              // theme
              (Highcharts.defaultOptions.title?.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      series,
      // legend: {
      //   enabled: false,
      //   labelFormat: '<b>{name}</b> ({y})',
      //   maxHeight: 100,
      // },
      legend: {
        align: 'left',
        x: 70,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend?.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
    };
    return chartOptions;
  }

  private columnChartHoraPromedio(
    title: string,
    series: SeriesColumnOptions[]
  ) {
    const chartOptions: Options = {
      title: {
        text: title,
      },
      plotOptions: {
        column: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.1f}',
          },
          showInLegend: true,
          minPointLength: 3,
          stacking: 'normal',
        },
      },
      xAxis: {
        type: 'category',
        categories: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
        ],
      },
      yAxis: {
        title: { text: 'Cantidad' },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            fontSize: '14px',
            color:
              // theme
              (Highcharts.defaultOptions.title?.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      series,
      // legend: {
      //   enabled: false,
      //   labelFormat: '<b>{name}</b> ({y})',
      //   maxHeight: 100,
      // },
      legend: {
        align: 'left',
        x: 70,
        verticalAlign: 'top',
        y: 20,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend?.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
    };
    return chartOptions;
  }

  private columnChartHoraHoy(title: string, series: SeriesColumnOptions[]) {
    const chartOptions: Options = {
      chart: {
        type: 'column',
        // options3d: {
        //   enabled: true,
        //   alpha: 15,
        //   beta: 15,
        //   viewDistance: 25,
        //   depth: 80,
        // },
      },
      title: {
        text: title,
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          grouping: false,
          borderWidth: 0,
          // stacking: 'normal',
        } as any,
      },
      xAxis: {
        type: 'category',
        categories: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
        ],
        labels: {
          skew3d: true,
          style: {
            fontSize: '16px',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Cantidad',
          skew3d: true,
          style: {
            fontSize: '16px',
          },
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            fontSize: '14px',
            color:
              // theme
              (Highcharts.defaultOptions.title?.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      series,
      legend: {
        align: 'left',
        x: 70,
        verticalAlign: 'top',
        y: 20,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend?.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        shared: true,
        headerFormat:
          '<span style="font-size: 15px">' + '{series.x}' + '</span><br/>',
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> ' +
          '{series.name}: <b>{point.y} registros</b><br/>',
      },
    };
    return chartOptions;
  }

  private columnChart3(title: string, series: SeriesColumnOptions[]) {
    const chartOptions: Options = {
      title: {
        text: title,
      },
      plotOptions: {
        column: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.1f}',
          },
          showInLegend: true,
          minPointLength: 3,
          stacking: 'normal',
        },
      },
      // xAxis: {

      // },
      yAxis: {
        title: { text: 'Cantidad' },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            fontSize: '14px',
            color:
              // theme
              (Highcharts.defaultOptions.title?.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      series,
      // legend: {
      //   enabled: false,
      //   labelFormat: '<b>{name}</b> ({y})',
      //   maxHeight: 100,
      // },
      legend: {
        align: 'left',
        x: 70,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend?.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
    };
    return chartOptions;
  }

  // Grafico por fecha de registro

  private dataAgrupadoPorModoRegistroFecha() {
    // const data: PointOptionsObject[] = [];
    const dataDNI: { [time: number]: number } = {};
    const dataManual: { [time: number]: number } = {};
    const dataImportados: { [time: number]: number } = {};
    const dataCreadosPorAdmin: { [time: number]: number } = {};

    this.vecinos?.forEach((vecino) => {
      const fecha = new Date(vecino.fechaCreacion!);
      fecha.setHours(0, 0, 0, 0);
      const time = fecha.getTime();

      if (vecino.creadoPorAdmin) {
        if (vecino.importado) {
          if (dataImportados[time]) {
            dataImportados[time] += 1;
          } else {
            dataImportados[time] = 1;
          }
        } else {
          if (dataCreadosPorAdmin[time]) {
            dataCreadosPorAdmin[time] += 1;
          } else {
            dataCreadosPorAdmin[time] = 1;
          }
        }
      } else {
        if (vecino.dniEscaneado) {
          if (dataDNI[time]) {
            dataDNI[time] += 1;
          } else {
            dataDNI[time] = 1;
          }
        } else {
          if (dataManual[time]) {
            dataManual[time] += 1;
          } else {
            dataManual[time] = 1;
          }
        }
      }
    });

    // agregar los dias que no tienen datos en 0
    const actualTime = Date.now();
    const fechaDNI = +Object.keys(dataDNI)[0] || Date.now();
    const fechaManual = +Object.keys(dataManual)[0] || Date.now();
    const fechaImportados = +Object.keys(dataImportados)[0] || Date.now();
    const fechaCreadosPorAdmin =
      +Object.keys(dataCreadosPorAdmin)[0] || Date.now();

    const HaceXDias = Math.min(
      fechaDNI,
      fechaManual,
      fechaImportados,
      fechaCreadosPorAdmin
    );

    for (let i = HaceXDias; i <= actualTime; i += 24 * 60 * 60 * 1000) {
      if (!dataDNI[i]) {
        dataDNI[i] = null as any;
      }
      if (!dataManual[i]) {
        dataManual[i] = null as any;
      }
      if (!dataImportados[i]) {
        dataImportados[i] = null as any;
      }
      if (!dataCreadosPorAdmin[i]) {
        dataCreadosPorAdmin[i] = null as any;
      }
    }

    const dataDNI1: [number, number][] = [];
    Object.keys(dataDNI).forEach((key) => {
      dataDNI1.push([+key, dataDNI[+key]]);
    });
    const dataManual1: [number, number][] = [];
    Object.keys(dataManual).forEach((key) => {
      dataManual1.push([+key, dataManual[+key]]);
    });
    const dataImportados1: [number, number][] = [];
    Object.keys(dataImportados).forEach((key) => {
      dataImportados1.push([+key, dataImportados[+key]]);
    });
    const dataCreadosPorAdmin1: [number, number][] = [];
    Object.keys(dataCreadosPorAdmin).forEach((key) => {
      dataCreadosPorAdmin1.push([+key, dataCreadosPorAdmin[+key]]);
    });

    const series: SeriesColumnOptions[] = [
      {
        type: 'column',
        name: 'DNI',
        data: dataDNI1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Manual',
        data: dataManual1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Importados',
        data: dataImportados1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Admin',
        data: dataCreadosPorAdmin1,
        color: undefined,
      },
    ];
    return series;
  }

  private dataAgrupadoPorClienteFecha() {
    let primeraFecha = Date.now();

    const data: { [cliente: string]: { [time: number]: number } } = {};
    this.vecinos?.forEach((vecino) => {
      const fecha = new Date(vecino.fechaCreacion!);
      fecha.setHours(0, 0, 0, 0);
      const time = fecha.getTime();
      if (time < primeraFecha) {
        primeraFecha = time;
      }
      const cliente =
        this.clientes.find((c) => {
          return c._id === vecino.idsCliente?.[0];
        })?.nombre || 'Sin cliente';

      if (data[cliente]) {
        if (data[cliente][time]) {
          data[cliente][time] += 1;
        } else {
          data[cliente][time] = 1;
        }
      } else {
        data[cliente] = {};
        data[cliente][time] = 1;
      }
    });

    const series: SeriesColumnOptions[] = [];
    const actualTime = Date.now();
    for (const cliente in data) {
      const serie: SeriesColumnOptions = {
        type: 'column',
        name: cliente,
        data: [],
        color: this.helperService.stringToColour(cliente),
      };
      for (
        let time = primeraFecha;
        time <= actualTime;
        time += 24 * 60 * 60 * 1000
      ) {
        if (data[cliente][time]) {
          serie.data?.push([time, data[cliente][time]]);
        } else {
          serie.data?.push([time, null as any]);
        }
      }
      series.push(serie);
    }

    series.sort((a, b) => {
      const sumaA = a.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      const sumaB = b.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      return (sumaA as number) - (sumaB as number);
    });

    return series;
  }

  public hacerGraficoFechaCreacion(): void {
    // Calcula el promedio diario
    const dias = 30;
    this.promedioDiario = Math.trunc(this.totalCount / dias);
    this.registradosHoy =
      this.vecinos?.filter((vecino) => {
        const fecha = new Date(vecino.fechaCreacion!);
        fecha.setHours(0, 0, 0, 0);
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        return fecha.getTime() === hoy.getTime();
      }).length || 0;

    const title = 'Cantidad de registros por día';

    let series;
    if (this.agrupacion === 'Modo de Registro') {
      series = this.dataAgrupadoPorModoRegistroFecha();
    } else if (this.agrupacion === 'Cliente') {
      series = this.dataAgrupadoPorClienteFecha();
    }

    if (!series) {
      return;
    }

    this.chartOptionsFechaCreacion = this.columnChart(title, series);
  }

  // Grafico por horas de registro

  private dataAgrupadoPorModoRegistroHora() {
    // const data: PointOptionsObject[] = [];
    const dataDNI: { [hora: string]: number } = {};
    const dataManual: { [hora: string]: number } = {};
    const dataImportados: { [hora: string]: number } = {};
    const dataCreadosPorAdmin: { [hora: string]: number } = {};

    this.vecinos?.forEach((vecino) => {
      const fecha = new Date(vecino.fechaCreacion!);
      const hora = `0${fecha.getHours()}`.slice(-2);

      if (vecino.creadoPorAdmin) {
        if (vecino.importado) {
          if (dataImportados[hora]) {
            dataImportados[hora] += 1;
          } else {
            dataImportados[hora] = 1;
          }
        } else {
          if (dataCreadosPorAdmin[hora]) {
            dataCreadosPorAdmin[hora] += 1;
          } else {
            dataCreadosPorAdmin[hora] = 1;
          }
        }
      } else {
        if (vecino.dniEscaneado) {
          if (dataDNI[hora]) {
            dataDNI[hora] += 1;
          } else {
            dataDNI[hora] = 1;
          }
        } else {
          if (dataManual[hora]) {
            dataManual[hora] += 1;
          } else {
            dataManual[hora] = 1;
          }
        }
      }
    });

    // Saca el promedio diario y pone en 0 los que no tienen datos
    for (let hora = 0; hora != 24; hora += 1) {
      const horaString = `0${hora}`.slice(-2);
      if (!dataDNI[horaString]) {
        dataDNI[horaString] = null as any;
      } else {
        dataDNI[horaString] = Math.trunc(dataDNI[horaString] / 30);
      }
      if (!dataManual[horaString]) {
        dataManual[horaString] = null as any;
      } else {
        dataManual[horaString] = Math.trunc(dataManual[horaString] / 30);
      }
      if (!dataImportados[horaString]) {
        dataImportados[horaString] = null as any;
      } else {
        dataImportados[horaString] = Math.trunc(
          dataImportados[horaString] / 30
        );
      }
      if (!dataCreadosPorAdmin[horaString]) {
        dataCreadosPorAdmin[horaString] = null as any;
      } else {
        dataCreadosPorAdmin[horaString] = Math.trunc(
          dataCreadosPorAdmin[horaString] / 30
        );
      }
    }

    // Crea los arrays para el grafico
    const dataDNI1: [string, number][] = [];
    Object.keys(dataDNI).forEach((key) => {
      const hora = `0${key}`.slice(-2);
      dataDNI1.push([hora, dataDNI[key]]);
    });
    const dataManual1: [string, number][] = [];
    Object.keys(dataManual).forEach((key) => {
      const hora = `0${key}`.slice(-2);
      dataManual1.push([hora, dataManual[key]]);
    });
    const dataImportados1: [string, number][] = [];
    Object.keys(dataImportados).forEach((key) => {
      const hora = `0${key}`.slice(-2);
      dataImportados1.push([hora, dataImportados[key]]);
    });
    const dataCreadosPorAdmin1: [string, number][] = [];
    Object.keys(dataCreadosPorAdmin).forEach((key) => {
      const hora = `0${key}`.slice(-2);
      dataCreadosPorAdmin1.push([hora, dataCreadosPorAdmin[key]]);
    });

    // Ordena los arrays
    dataDNI1.sort((a, b) => {
      const horaA = parseInt(a[0]);
      const horaB = parseInt(b[0]);
      return horaA - horaB;
    });
    dataManual1.sort((a, b) => {
      const horaA = parseInt(a[0]);
      const horaB = parseInt(b[0]);
      return horaA - horaB;
    });
    dataImportados1.sort((a, b) => {
      const horaA = parseInt(a[0]);
      const horaB = parseInt(b[0]);
      return horaA - horaB;
    });
    dataCreadosPorAdmin1.sort((a, b) => {
      const horaA = parseInt(a[0]);
      const horaB = parseInt(b[0]);
      return horaA - horaB;
    });

    const series: SeriesColumnOptions[] = [
      {
        type: 'column',
        name: 'DNI',
        data: dataDNI1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Manual',
        data: dataManual1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Importados',
        data: dataImportados1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Admin',
        data: dataCreadosPorAdmin1,
        color: undefined,
      },
    ];

    return series;
  }

  private dataAgrupadoPorClienteHora() {
    let primeraFecha = Date.now();

    const data: { [cliente: string]: { [hora: string]: number } } = {};
    this.vecinos?.forEach((vecino) => {
      const fecha = new Date(vecino.fechaCreacion!);
      const hora = `0${fecha.getHours()}`.slice(-2);

      const cliente =
        this.clientes.find((c) => {
          return c._id === vecino.idsCliente?.[0];
        })?.nombre || 'Sin cliente';
      if (data[cliente]) {
        if (data[cliente][hora]) {
          data[cliente][hora] += 1;
        } else {
          data[cliente][hora] = 1;
        }
      } else {
        data[cliente] = {};
        data[cliente][hora] = 1;
      }
    });

    const series: SeriesColumnOptions[] = [];
    for (const cliente in data) {
      const serie: SeriesColumnOptions = {
        type: 'column',
        name: cliente,
        data: [],
        color: this.helperService.stringToColour(cliente),
      };
      for (let hora = 0; hora != 24; hora += 1) {
        const horaString = `0${hora}`.slice(-2);
        if (data[cliente][horaString]) {
          serie.data?.push([horaString, data[cliente][horaString]]);
        } else {
          serie.data?.push([horaString, null as any]);
        }
      }
      series.push(serie);
    }

    series.sort((a, b) => {
      const sumaA = a.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      const sumaB = b.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      return (sumaA as number) - (sumaB as number);
    });

    return series;
  }

  public hacerGraficoHoraCreacion(): void {
    const title = 'Promedio de registros por hora';

    let series;
    if (this.agrupacion === 'Modo de Registro') {
      series = this.dataAgrupadoPorModoRegistroHora();
    } else if (this.agrupacion === 'Cliente') {
      series = this.dataAgrupadoPorClienteHora();
    }

    if (!series) {
      return;
    }

    this.chartOptionsHoraCreacion = this.columnChartHoraPromedio(title, series);
  }

  // Grafico cantidad por horas de registro

  private dataAgrupadoPorModoRegistroHoraHoy() {
    // const data: PointOptionsObject[] = [];
    const dataHoy: { [hora: string]: number } = {};
    const dataPromedio: { [hora: string]: number } = {};

    this.vecinos?.forEach((vecino) => {
      const fechaRegistro = new Date(vecino.fechaCreacion!);
      fechaRegistro.setHours(0, 0, 0, 0);
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0);

      const fecha = new Date(vecino.fechaCreacion!);
      const hora = `0${fecha.getHours()}`.slice(-2);

      if (fechaRegistro.getTime() === hoy.getTime()) {
        if (dataHoy[hora]) {
          dataHoy[hora] += 1;
        } else {
          dataHoy[hora] = 1;
        }
      }
      if (dataPromedio[hora]) {
        dataPromedio[hora] += 1;
      } else {
        dataPromedio[hora] = 1;
      }
    });

    // pone en 0 los que no tienen datos
    for (let hora = 0; hora != 24; hora += 1) {
      const horaString = `0${hora}`.slice(-2);
      if (!dataPromedio[horaString]) {
        dataPromedio[horaString] = null as any;
      } else {
        dataPromedio[horaString] = Math.trunc(dataPromedio[horaString] / 30);
      }
      if (!dataHoy[horaString]) {
        dataHoy[horaString] = null as any;
      } else {
        dataHoy[horaString] = Math.trunc(dataHoy[horaString]);
      }
    }

    // Crea los arrays para el grafico
    const dataPromedio1: [string, number][] = [];
    Object.keys(dataPromedio).forEach((key) => {
      const hora = `0${key}`.slice(-2);
      dataPromedio1.push([hora, dataPromedio[key]]);
    });
    const dataHoy1: [string, number][] = [];
    Object.keys(dataHoy).forEach((key) => {
      const hora = `0${key}`.slice(-2);
      dataHoy1.push([hora, dataHoy[key]]);
    });

    // Ordena los arrays
    dataPromedio1.sort((a, b) => {
      const horaA = parseInt(a[0]);
      const horaB = parseInt(b[0]);
      return horaA - horaB;
    });
    dataHoy1.sort((a, b) => {
      const horaA = parseInt(a[0]);
      const horaB = parseInt(b[0]);
      return horaA - horaB;
    });

    const series: SeriesColumnOptions[] = [
      // Promedio
      {
        type: 'column',
        name: 'Promedio',
        data: dataPromedio1,
        color: 'rgba(158, 159, 163, 0.5)',
        pointPlacement: -0.2,
        // linkedTo: 'reg',
      },
      // Hoy
      {
        type: 'column',
        name: 'Hoy',
        data: dataHoy1,
        color: undefined,
        // id: 'reg',
      },
    ];

    return series;
  }

  private dataAgrupadoPorClienteHoraHoy() {
    let primeraFecha = Date.now();

    const data: { [cliente: string]: { [hora: string]: number } } = {};
    this.vecinos?.forEach((vecino) => {
      const fechaRegistro = new Date(vecino.fechaCreacion!);
      fechaRegistro.setHours(0, 0, 0, 0);
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0);

      if (fechaRegistro.getTime() !== hoy.getTime()) {
        return;
      }

      const fecha = new Date(vecino.fechaCreacion!);
      const hora = `0${fecha.getHours()}`.slice(-2);

      const cliente =
        this.clientes.find((c) => {
          return c._id === vecino.idsCliente?.[0];
        })?.nombre || 'Sin cliente';
      if (data[cliente]) {
        if (data[cliente][hora]) {
          data[cliente][hora] += 1;
        } else {
          data[cliente][hora] = 1;
        }
      } else {
        data[cliente] = {};
        data[cliente][hora] = 1;
      }
    });

    const series: SeriesColumnOptions[] = [];
    for (const cliente in data) {
      const serie: SeriesColumnOptions = {
        type: 'column',
        name: cliente,
        data: [],
        color: this.helperService.stringToColour(cliente),
      };
      for (let hora = 0; hora != 24; hora += 1) {
        const horaString = `0${hora}`.slice(-2);
        if (data[cliente][horaString]) {
          serie.data?.push([horaString, data[cliente][horaString]]);
        } else {
          serie.data?.push([horaString, null as any]);
        }
      }
      series.push(serie);
    }

    series.sort((a, b) => {
      const sumaA = a.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      const sumaB = b.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      return (sumaA as number) - (sumaB as number);
    });

    return series;
  }

  public hacerGraficoHoraCreacionHoy(): void {
    const title = 'Cantidad de registros por hora';

    let series;
    if (this.agrupacion === 'Modo de Registro') {
      series = this.dataAgrupadoPorModoRegistroHoraHoy();
    } else if (this.agrupacion === 'Cliente') {
      series = this.dataAgrupadoPorClienteHoraHoy();
    }

    if (!series) {
      return;
    }

    this.chartOptionsHoraHoy = this.columnChartHoraHoy(title, series);
  }

  // Grafico promedio por horas de registro

  private dataAgrupadoPorModoRegistroEdad() {
    // const data: PointOptionsObject[] = [];
    const dataDNI: { [edad: number]: number } = {};
    const dataManual: { [edad: number]: number } = {};
    const dataImportados: { [edad: number]: number } = {};
    const dataCreadosPorAdmin: { [edad: number]: number } = {};

    this.vecinos?.forEach((vecino) => {
      const edad = HelperService.calcularEdad(vecino.fechaNacimiento!);

      // if (edad < 10 || edad > 100) {
      //   console.log(`vecino`, vecino);
      // }

      if (edad > 0 && edad < 100) {
        if (vecino.creadoPorAdmin) {
          if (vecino.importado) {
            if (dataImportados[edad]) {
              dataImportados[edad] += 1;
            } else {
              dataImportados[edad] = 1;
            }
          } else {
            if (dataCreadosPorAdmin[edad]) {
              dataCreadosPorAdmin[edad] += 1;
            } else {
              dataCreadosPorAdmin[edad] = 1;
            }
          }
        } else {
          if (vecino.dniEscaneado) {
            if (dataDNI[edad]) {
              dataDNI[edad] += 1;
            } else {
              dataDNI[edad] = 1;
            }
          } else {
            if (dataManual[edad]) {
              dataManual[edad] += 1;
            } else {
              dataManual[edad] = 1;
            }
          }
        }
      }
    });

    // Busca la menor edad
    let menorEdad = 100;
    for (const edad in dataDNI) {
      if (parseInt(edad) < menorEdad) {
        menorEdad = parseInt(edad);
      }
    }
    for (const edad in dataManual) {
      if (parseInt(edad) < menorEdad) {
        menorEdad = parseInt(edad);
      }
    }
    for (const edad in dataImportados) {
      if (parseInt(edad) < menorEdad) {
        menorEdad = parseInt(edad);
      }
    }
    for (const edad in dataCreadosPorAdmin) {
      if (parseInt(edad) < menorEdad) {
        menorEdad = parseInt(edad);
      }
    }
    // Busca la mayor edad
    let mayorEdad = 0;
    for (const edad in dataDNI) {
      if (parseInt(edad) > mayorEdad) {
        mayorEdad = parseInt(edad);
      }
    }
    for (const edad in dataManual) {
      if (parseInt(edad) > mayorEdad) {
        mayorEdad = parseInt(edad);
      }
    }
    for (const edad in dataImportados) {
      if (parseInt(edad) > mayorEdad) {
        mayorEdad = parseInt(edad);
      }
    }
    for (const edad in dataCreadosPorAdmin) {
      if (parseInt(edad) > mayorEdad) {
        mayorEdad = parseInt(edad);
      }
    }

    // Saca el promedio diario y pone en 0 los que no tienen datos
    for (let edad = menorEdad; edad <= mayorEdad; edad++) {
      if (!dataDNI[edad]) {
        dataDNI[edad] = null as any;
      } else {
        dataDNI[edad] = Math.trunc(dataDNI[edad]);
      }
      if (!dataManual[edad]) {
        dataManual[edad] = null as any;
      } else {
        dataManual[edad] = Math.trunc(dataManual[edad]);
      }
      if (!dataImportados[edad]) {
        dataImportados[edad] = null as any;
      } else {
        dataImportados[edad] = Math.trunc(dataImportados[edad]);
      }
      if (!dataCreadosPorAdmin[edad]) {
        dataCreadosPorAdmin[edad] = null as any;
      } else {
        dataCreadosPorAdmin[edad] = Math.trunc(dataCreadosPorAdmin[edad]);
      }
    }

    // Crea los arrays para el grafico
    const dataDNI1: [number, number][] = [];
    Object.keys(dataDNI).forEach((key) => {
      dataDNI1.push([+key, dataDNI[+key]]);
    });
    const dataManual1: [number, number][] = [];
    Object.keys(dataManual).forEach((key) => {
      dataManual1.push([+key, dataManual[+key]]);
    });
    const dataImportados1: [number, number][] = [];
    Object.keys(dataImportados).forEach((key) => {
      dataImportados1.push([+key, dataImportados[+key]]);
    });
    const dataCreadosPorAdmin1: [number, number][] = [];
    Object.keys(dataCreadosPorAdmin).forEach((key) => {
      dataCreadosPorAdmin1.push([+key, dataCreadosPorAdmin[+key]]);
    });

    // Ordena los arrays
    dataDNI1.sort((a, b) => a[0] - b[0]);
    dataManual1.sort((a, b) => a[0] - b[0]);
    dataImportados1.sort((a, b) => a[0] - b[0]);
    dataCreadosPorAdmin1.sort((a, b) => a[0] - b[0]);

    const series: SeriesColumnOptions[] = [
      {
        type: 'column',
        name: 'DNI',
        data: dataDNI1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Manual',
        data: dataManual1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Importados',
        data: dataImportados1,
        color: undefined,
      },
      {
        type: 'column',
        name: 'Admin',
        data: dataCreadosPorAdmin1,
        color: undefined,
      },
    ];

    return series;
  }

  private dataAgrupadoPorClienteEdad() {
    let primeraFecha = Date.now();

    const data: { [cliente: string]: { [edad: number]: number } } = {};
    this.vecinos?.forEach((vecino) => {
      const fecha = new Date(vecino.fechaCreacion!);

      const edad = HelperService.calcularEdad(vecino.fechaNacimiento!);

      if (edad > 0 && edad < 100) {
        const cliente =
          this.clientes.find((c) => {
            return c._id === vecino.idsCliente?.[0];
          })?.nombre || 'Sin cliente';
        if (data[cliente]) {
          if (data[cliente][edad]) {
            data[cliente][edad] += 1;
          } else {
            data[cliente][edad] = 1;
          }
        } else {
          data[cliente] = {};
          data[cliente][edad] = 1;
        }
      }
    });

    // Busca la menor edad
    let menorEdad = 100;
    for (const cliente in data) {
      for (const edad of Object.keys(data[cliente])) {
        if (parseInt(edad) < menorEdad) {
          menorEdad = parseInt(edad);
        }
      }
    }
    // Busca la mayor edad
    let mayorEdad = 0;
    for (const cliente in data) {
      for (const edad of Object.keys(data[cliente])) {
        if (parseInt(edad) > mayorEdad) {
          mayorEdad = parseInt(edad);
        }
      }
    }

    const series: SeriesColumnOptions[] = [];
    for (const cliente in data) {
      const serie: SeriesColumnOptions = {
        type: 'column',
        name: cliente,
        data: [],
        color: this.helperService.stringToColour(cliente),
      };
      for (let edad = menorEdad; edad <= mayorEdad; edad++) {
        if (data[cliente][edad]) {
          serie.data?.push([edad, data[cliente][edad]]);
        } else {
          serie.data?.push([edad, null as any]);
        }
      }
      series.push(serie);
    }

    series.sort((a, b) => {
      const sumaA = a.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      const sumaB = b.data?.reduce((acc, cur) => {
        const actual = ((cur as number[])?.[1] as number) || 0;
        return (acc as number) + actual || 0;
      }, 0);
      return (sumaA as number) - (sumaB as number);
    });

    return series;
  }

  public hacerGraficoEdad(): void {
    const title = 'Cantidad de registros por edad';

    let series;
    if (this.agrupacion === 'Modo de Registro') {
      series = this.dataAgrupadoPorModoRegistroEdad();
    } else if (this.agrupacion === 'Cliente') {
      series = this.dataAgrupadoPorClienteEdad();
    }

    if (!series) {
      return;
    }

    this.chartOptionsEdad = this.columnChart3(title, series);
  }

  //

  async ngOnInit(): Promise<void> {
    await Promise.all([this.listar(), this.listarClientes()]);
  }
}
