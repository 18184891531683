<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4"><span class="titulo">Controles </span></h2>

<mat-tab-group class="mx-3">
  <mat-tab label="Controles">
    <ng-template matTabContent>
      <app-listado-controles [(loading)]="loading"></app-listado-controles>
    </ng-template>
  </mat-tab>
  <!-- <mat-tab label="Asignacion Controles-Sirenas">
    <ng-template matTabContent>
      <app-listado-controles-sirenas
        [(loading)]="loading"
      ></app-listado-controles-sirenas>
    </ng-template>
  </mat-tab> -->
</mat-tab-group>
