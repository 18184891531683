import { Injectable } from '@angular/core';
import {
  IFirmwareSirena,
  IListado,
  INuevoFirmwareSirena,
  IQueryParam,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class FirmwareSirenasService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<IFirmwareSirena>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/firmwareSirenas`, { params });
  }

  public listarPorId(id: string): Observable<IFirmwareSirena> {
    return this.http.get(`/firmwareSirenas/${id}`);
  }

  public crear(
    dato: INuevoFirmwareSirena,
    file: File
  ): Observable<IFirmwareSirena> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('version', dato.version);

    return this.http.post(`/firmwareSirenas`, formData);
  }

  public eliminar(id: string): Observable<IFirmwareSirena> {
    return this.http.delete(`/firmwareSirenas/${id}`);
  }
}
