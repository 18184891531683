<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="form"
      [formGroup]="formulario"
      (ngSubmit)="enviar()"
      fxLayout="column"
    >
      <mat-form-field>
        <mat-label>Nombre de la API</mat-label>
        <input matInput formControlName="apiName" />
      </mat-form-field>

      <mat-form-field *ngIf="!this.data?._id">
        <mat-label>Fecha de Creación</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          formControlName="fechaCreacion"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Fecha de Expiración</mat-label>
        <input
          matInput
          [matDatepicker]="picker2"
          formControlName="fechaExpiracion"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="!this.data?._id">
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="idCliente">
          <mat-option *ngFor="let c of clientes" [value]="c._id">
            {{ c.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Roles</mat-label>
        <mat-select formControlName="roles" multiple>
          <mat-option *ngFor="let r of roles" [value]="r">
            {{ r | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario.valid || loading"
      form="form"
    >
      <div
        class="loadSpinnerButton"
        [style.display]="loading ? 'flex' : 'none'"
      >
        <mat-spinner
          class="mx-2"
          [diameter]="25"
          mode="indeterminate"
          color="warn"
        ></mat-spinner>
      </div>
      <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
    </button>

    <button mat-raised-button type="button" (click)="close()">Volver</button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
