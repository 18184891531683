import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ISirena,
  IFilter,
  IQueryParam,
  IUpdateSirena,
  IListado,
  ICliente,
} from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { SirenasService } from '../sirenas.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { EditarSirenasComponent } from '../editar-sirenas/editar-sirenas.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { ActivatedRoute, Params } from '@angular/router';
import { QueryFilterService } from '../../../auxiliares/servicios/queryFilter.service';
import { CrearEditarMantenimientoComponent } from '../../mantenimientos/crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { ImportarChinasComponent } from '../importar-chinas/importar-chinas.component';

declare type mainType = ISirena;

@Component({
  selector: 'app-listado-sirenas',
  templateUrl: './listado-sirenas.component.html',
  styleUrls: ['./listado-sirenas.component.scss'],
})
export class ListadoSirenasComponent implements OnInit, OnDestroy {
  /// Cosos neuvos de angular
  readonly dialog = inject(MatDialog);
  // Filtros
  public filter: IFilter<ISirena> = {};
  public sortActive = '';
  public sortDirection: 'asc' | 'desc' = 'asc';
  //

  public search: IRegExpSearch = {
    fields: [
      'chipId',
      'direccionGps',
      'imei',
      'iccidSim',
      'versionFirmware',
      'datosHw.numeroNac',
    ],
  };

  public sinCliente: IFiltroTabla = {
    elementos: [
      {
        nombre: 'Sin Cliente',
        _id: [{ idCliente: { $exists: false } }, { idCliente: { $eq: null } }],
      },
      {
        nombre: 'Con Cliente',
        _id: [{ idCliente: { $exists: true } }, { idCliente: { $ne: null } }],
      },
    ],
    filter: {
      field: '$or',
    },
    label: 'Sin Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public online: IFiltroTabla = {
    elementos: [
      { nombre: 'Online', _id: 'true' },
      { nombre: 'Offline', _id: 'false' },
    ],
    filter: {
      field: 'online',
    },
    label: 'Online / Offline',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public sirenaEncendida: IFiltroTabla = {
    elementos: [
      { nombre: 'Sirena Encendida', _id: 'true' },
      { nombre: 'Sirena Apagada', _id: 'false' },
    ],
    filter: {
      field: 'sonidoEncendido',
    },
    label: 'Estado Sirena',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public reflectorEncendido: IFiltroTabla = {
    elementos: [
      { nombre: 'Reflector Encendido', _id: 'true' },
      { nombre: 'Reflector Apagado', _id: 'false' },
    ],
    filter: {
      field: 'luzEncendida',
    },
    label: 'Estado Reflector',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public senal: IFiltroTabla = {
    elementos: [
      { nombre: 'Excelente', _id: { $gte: -73 } },
      { nombre: 'Buena', _id: { $gte: -83, $lt: -73 } },
      { nombre: 'Regular', _id: { $gte: -93, $lt: -83 } },
      { nombre: 'Mala', _id: { $lt: -93 } },
    ],
    filter: {
      field: 'rssi',
    },
    label: 'Señal',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public errorSd: IFiltroTabla = {
    elementos: [
      { nombre: 'Con Error', value: { $exists: true, $ne: null } },
      // {
      //   nombre: 'Sin Error',
      //   value: { $or: [{ $exist: false }, { $eq: null }] },
      // },
    ],
    filter: {
      field: 'errorSd',
    },
    label: 'Error SD',
    selectLabel: 'nombre',
    selectValue: 'value',
    tipo: 'select',
  };
  public errorActualizacion: IFiltroTabla = {
    elementos: [
      { nombre: 'Con Error', value: true },
      // { nombre: 'Sin Error', value: false },
    ],
    filter: {
      field: 'errorActualizacion',
    },
    label: 'Error Actualización',
    selectLabel: 'nombre',
    selectValue: 'value',
    tipo: 'select',
  };

  public activa: IFiltroTabla = {
    elementos: [
      { nombre: 'Activa', _id: 'true' },
      { nombre: 'Inactiva', _id: 'false' },
    ],
    filter: {
      field: 'activa',
    },
    label: 'Activa / Inactiva',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public tipo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: '$or',
    },
    label: 'Tipo de Sirena',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };

  public firmware: IFiltroTabla = {
    filter: {
      field: 'versionFirmware',
    },
    label: 'Firmware',
    tipo: 'regexp',
  };

  public actualizando: IFiltroTabla = {
    elementos: [
      { id: 'Actualizando', value: 'true' },
      { id: 'Actualizada', value: 'false' },
    ],
    filter: {
      field: 'actualizando',
    },
    label: 'Actualizando',
    selectLabel: 'id',
    selectValue: 'value',
    tipo: 'select',
  };

  public mongo: IFiltroTabla = {
    label: 'Filtro Personalizado',
    tipo: 'mongo',
    filter: {
      field: 'mongo',
      value: '',
    },
  };

  @Input() loading?: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();

  public columnas = [
    'chipId',
    'versionFirmware',
    'wakeup',
    // 'activa',
    'online',
    'fechaOnline',
    'fechaOffline',
    'direccionGps',
    'idCliente',
    // 'luzEncendida',
    // 'sonidoEncendido',
    'acumuladoOnline',
    'acumuladoOffline',
    'uptime',
    'rssi',
    'errorActualizacion',
    'acciones',
  ];
  public nombreColumnas = [
    'Chip ID',
    'Firmware',
    'Wakeup',
    // 'Activa',
    'Estado',
    'Fecha Online',
    'Fecha Offline',
    'Dirección',
    'Cliente',
    // 'Luz Encendida',
    // 'Sonido Encendido',
    'Tiempo Online',
    'Tiempo Offline',
    'Uptime',
    'SIM',
    'Error Actualización',
    'Acciones',
  ];
  public saveColumnas = 'tabla-sirenas3';
  public dataSource = new MatTableDataSource<mainType>([]);
  // FILTRO BUSQUEDA Y PAGINACION
  private populate = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'sim',
      select: 'imsi numero operador',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'chipId',
    populate: JSON.stringify(this.populate),
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  private clientes$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialogService: DialogService,
    private service: SirenasService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private activatedRoute: ActivatedRoute,
    private queryFilterService: QueryFilterService,
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
  }

  private onLoadingChange(loading: boolean): void {
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public async pageEvent(event: PageEvent): Promise<void> {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.actualizar();
  }

  public async sortChange(event: Sort): Promise<void> {
    this.onLoadingChange(true);
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;

    await this.actualizar();
    this.onLoadingChange(false);
  }

  public async cambioFiltro(filters: IFilter<ISirena>): Promise<void> {
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
  }

  // ACCIONES
  public async editar(data: mainType): Promise<void> {
    const config: MatDialogConfig = {
      data,
      width: '700px',
    };
    this.matDialog.open(EditarSirenasComponent, config);
  }
  public async eliminar(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la sirena?`,
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service.eliminar(dato._id!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }

  public async mantenimiento(idSirenaSeleccionada: string): Promise<void> {
    const config: MatDialogConfig = {
      data: { idSirenaSeleccionada },
      width: '800px',
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  public async borrarErrorSd(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el error de SD de la sirena?`,
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        const update: IUpdateSirena = { errorSd: null };
        await this.service.editar(dato._id!, update).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Eliminación de error correcta');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }
  public async actualizarFirmware(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Enviar comando de actualización de firmware?`,
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service
          .actualizarFirmware(dato.chipId!)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Comando enviado');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }
  public async reset(dato: mainType): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Enviar comando de reset?`,
    );
    if (confirm) {
      try {
        this.onLoadingChange(true);
        await this.service.reset(dato.chipId!).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Comando enviado');
        this.onLoadingChange(false);
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.onLoadingChange(false);
      }
    }
  }

  public async exportar(): Promise<void> {
    const mensaje = `¿Desea exportar las ${this.totalCount} sirenas?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      try {
        this.loading = true;
        this.queryParams.limit = 0;
        delete this.queryParams.page;
        await this.service.exportar(this.queryParams);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.helper.notifError(error);
        this.loading = false;
      }
    }
  }

  public async importarChinas() {
    this.dialog.open(ImportarChinasComponent, {});
  }

  // Listar
  public async actualizar(): Promise<void> {
    this.onLoadingChange(true);
    await this.listar();
    this.onLoadingChange(false);
  }

  private async listar(): Promise<void> {
    this.updateParams();
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ISirena>>('sirenas', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.debug(new Date().toLocaleString(), `listado de sirenas`, data);
      });
    await this.listadosService.getLastValue('sirenas', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    if (!this.helper.puedeListarClientes()) return;

    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  // Parametros de ruta para filtro

  private updateParams(): void {
    this.queryFilterService.setQuery(
      this.activatedRoute,
      'sirenas',
      this.queryParams,
    );
  }

  private updateQuery(qp: Params): void {
    const response = this.queryFilterService.getQuery(qp);
    this.filter = response.filter || {};
    this.sortActive = response.sortActive || '';
    this.sortDirection = response.sortDirection || 'asc';
    if (response.queryParams) {
      this.queryParams = response.queryParams;
      this.queryParams.populate = JSON.stringify(this.populate);
    }
  }

  private getQueryParams(): void {
    const qp = this.activatedRoute.snapshot.queryParams;
    if (qp.queryParams) {
      this.updateQuery(qp);
    } else {
      const queryParams = this.queryFilterService.getQueryParam('sirenas');
      if (queryParams) {
        this.updateQuery(queryParams);
      }
    }
  }

  private setFiltroTipoSierenas() {
    this.tipo.elementos = [];
    const tipos = this.helper.getTipoSirenas();
    if (tipos?.length) {
      for (const tipo of tipos) {
        if (tipo.toLowerCase() === 'sirena') {
          const elem = {
            label: tipo,
            value: [
              { tipo },
              { tipo: 'sirena' },
              { tipo: { $exists: false } },
              { tipo: null },
              { tipo: '' },
            ],
          };
          this.tipo.elementos.push(elem);
        } else {
          const elem = {
            label: tipo,
            value: [{ tipo }],
          };
          this.tipo.elementos.push(elem);
        }
      }
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.getQueryParams();
    this.setFiltroTipoSierenas();
    await Promise.all([this.actualizar(), this.listarClientes()]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges(),
    );
    this.datos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
