import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IFilter,
  IListado,
  ILogSirena,
  IQueryParam,
  ISocketMessage,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';

@Component({
  selector: 'app-listado-logs',
  templateUrl: './listado-logs.component.html',
  styleUrls: ['./listado-logs.component.scss'],
})
export class ListadoLogsComponent implements OnInit {
  public loading = false;
  @Input() chipId?: string | null;

  //
  public search: IRegExpSearch = {
    fields: ['mensaje'],
  };

  public evento: IFiltroTabla = {
    elementos: [
      { nombre: 'Conexión Abierta', _id: 'Conexion abierta' },
      { nombre: 'Conexión Cerrada', _id: 'Conexion cerrada' },
      { nombre: 'Actualizando Firmware', _id: 'Actualizando Firmware' },
      { nombre: 'Autenticación', _id: 'AUTH_SIRENA' },
      { nombre: 'Ubicación', _id: 'UBICACION_SIRENA' },
      { nombre: 'Baliza Encendida', _id: 'BALIZA_ENCENDIDA' },
      { nombre: 'Baliza Apagada', _id: 'BALIZA_APAGADA' },
      { nombre: 'Sirena Encendida', _id: 'SONIDO_ENCENDIDO' },
      { nombre: 'Sirena Apagada', _id: 'SONIDO_APAGADO' },
      { nombre: 'Control Desconocido', _id: 'CONTROL_NO_CONOCIDO' },
    ],
    filter: {
      field: 'evento',
    },
    label: 'Evento',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public fecha: IFiltroTabla = {
    label: 'Rango de Fechas',
    filter: {
      field: 'fecha',
    },
    tipo: 'dateRange',
  };

  public columnas = ['fecha', 'evento', 'mensaje', 'origen'];
  public nombreColumnas = ['Fecha', 'Evento', 'Mensaje', 'Origen'];
  public saveColumnas = 'tabla-logs';
  public dataSource = new MatTableDataSource<ILogSirena>([]);
  // private datos: ILogSirena[] = [];
  // FILTRO BUSQUEDA Y PAGINACION
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    filter: JSON.stringify([]),
  };
  public totalCount = 0;
  // Listado Continuo
  private datos$?: Subscription;
  // Mobile query
  public mobileQuery: MediaQueryList;
  //

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    // private dialogService: DialogService,
    // private service: EventosBalizasService,
    // public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  // ################################################################################
  // ################################# TABLA 1 ######################################
  // ################################################################################
  public pageEvent(event: PageEvent): void {
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    this.actualizar();
  }

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  public sortChange(event: Sort): void {
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    this.actualizar();
  }

  public async cambioFiltro(filters: IFilter<any>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.actualizar();
    this.loading = false;
  }

  // Listar
  private async listar(): Promise<void> {
    // Update Filtro
    const filter: IFilter<ILogSirena> = {
      chipId: this.chipId,
    };
    // const filtros = JSON.parse(this.queryParams.filter!);
    // filtros.push(filter);
    this.queryParams.filter = JSON.stringify(filter);

    // Query
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ILogSirena>>('logsSirenas', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de logsSirenas`,
          data
        );
      });
    await this.listadosService.getLastValue('logsSirenas', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
    this.datos$?.unsubscribe();
  }
}
