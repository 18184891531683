<h2 mat-dialog-title class="modal-header">{{ title }}</h2>

<mat-dialog-content>
  <form
    *ngIf="formulario"
    id="form"
    [formGroup]="formulario"
    (ngSubmit)="enviar()"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Número</mat-label>
      <input matInput placeholder="Número" formControlName="numero" required />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button
    class="botonSpinner"
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!formulario?.valid || loading"
    form="form"
  >
    <div class="loadSpinnerButton" [style.display]="loading ? 'flex' : 'none'">
      <mat-spinner
        class="mx-2"
        [diameter]="25"
        mode="indeterminate"
        color="warn"
      ></mat-spinner>
    </div>
    <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
  </button>

  <button mat-raised-button type="button" (click)="close()">Volver</button>
</mat-dialog-actions>
