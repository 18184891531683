<div class="centerRow">
  <div style="width: 25%">
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="fileInput.click()"
      style="width: 90%"
    >
      <mat-icon>upload</mat-icon>
    </button>
  </div>

  <div style="width: 75%">
    <input
      #fileInput
      matInput
      type="file"
      style="position: fixed; top: -20000px"
      accept="image/*"
      (change)="setFile($event)"
    />

    <mat-form-field>
      <mat-label>URL</mat-label>
      <input
        #urlInput
        matInput
        [value]="this.url || 'Cargando...'"
        [disabled]="true"
        style="width: 75%"
      />
    </mat-form-field>
  </div>
  <div class="centerRow">
    <img
      *ngIf="this.url"
      [src]="this.url"
      alt="Sin Imagen"
      [style.max-width]="'100px'"
    />
  </div>
</div>
