import { Injectable } from '@angular/core';
import {
  ICrearSim,
  IListado,
  IQueryParam,
  ISim,
  IUpdateSim,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiAdminService } from '../../auxiliares/servicios/http-api.service';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class SimsService {
  constructor(
    private http: HttpApiAdminService,
    private helper: HelperService
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ISim>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/sims`, { params });
  }

  public listarPorId(id: string): Observable<ISim> {
    return this.http.get(`/sims/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve, reject) => {
      const params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/sims/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'export.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          }
        );
    });
  }

  public crear(dato: ICrearSim): Observable<ISim> {
    return this.http.post(`/sims`, dato);
  }

  public editar(id: string, dato: IUpdateSim): Observable<ISim> {
    return this.http.put(`/sims/${id}`, dato);
  }

  public eliminar(id: string): Observable<ISim> {
    return this.http.delete(`/sims/${id}`);
  }
}
