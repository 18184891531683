import { Component, OnInit } from '@angular/core';
import { IUsuario } from 'modelos/src';
import { AuthService } from '../../login/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public user?: IUsuario;

  constructor(private authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.user = this.authService.getUsuario();
  }
}
