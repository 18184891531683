import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { INuevoFirmwareSirena } from 'modelos/src';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { FirmwareSirenasService } from '../firmware-sirenas.service';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-crear-firmware',
  templateUrl: './crear-firmware.component.html',
  styleUrls: ['./crear-firmware.component.scss'],
})
export class CrearFirmwareComponent implements OnInit {
  public formulario: UntypedFormGroup;
  public loading = false;
  public file?: File | null;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearFirmwareComponent>,
    private service: FirmwareSirenasService,
    private helper: HelperService
  ) {
    this.formulario = this.fb.group({
      file: [],
      version: [],
    });
  }

  public async setFile(event: Event) {
    this.file = (event as HTMLInputEvent).target?.files?.item(0);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      if (this.file) {
        const data: INuevoFirmwareSirena = {
          file: this.file,
          version: this.formulario?.value.version,
        };
        await this.service.crear(data, this.file).pipe(take(1)).toPromise();
        this.helper.notifSuccess('Creado correctamente');
        this.dialogRef.close(true);
      } else {
        this.helper.notifWarn('Debe seleccionar un archivo');
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {}
}
