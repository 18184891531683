import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ICliente,
  ICrearFirmwareCliente,
  IFirmwareSirena,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { FirmwareClientesService } from '../firmware-clientes.service';

@Component({
  selector: 'app-crear-firmware-cliente',
  templateUrl: './crear-firmware-cliente.component.html',
  styleUrls: ['./crear-firmware-cliente.component.scss'],
})
export class CrearFirmwareClienteComponent implements OnInit {
  formulario?: UntypedFormGroup;
  loading = false;

  clientes: ICliente[] = [];
  firmwares: IFirmwareSirena[] = [];

  // Listado Continuo
  private firmwares$?: Subscription;
  private clientes$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearFirmwareClienteComponent>,
    private service: FirmwareClientesService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  private crearFormulario(): void {
    this.formulario = this.fb.group({
      idCliente: [],
      version: [],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data: ICrearFirmwareCliente = {
        idCliente: `${this.formulario?.get('idCliente')?.value}`,
        version: this.formulario?.get('version')?.value,
      };
      await this.service.crear(data).pipe(take(1)).toPromise();
      this.helper.notifSuccess('Creado correctamente');
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  //

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarFirmwareSirenas(): Promise<void> {
    // Query
    this.firmwares$?.unsubscribe();
    this.firmwares$ = this.listadosService
      .subscribe<IListado<IFirmwareSirena>>('firmwareSirenas', {})
      .subscribe((data) => {
        this.firmwares = data.datos;
        console.log(
          new Date().toLocaleString(),
          `listado de firmwareSirenas`,
          data
        );
      });
    await this.listadosService.getLastValue('firmwareSirenas', {});
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.crearFormulario();
    await Promise.all([this.listarClientes(), this.listarFirmwareSirenas()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.firmwares$?.unsubscribe();
  }
}
