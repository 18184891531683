import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-controles',
  templateUrl: './controles.component.html',
  styleUrls: ['./controles.component.scss'],
})
export class ControlesComponent implements OnInit {
  public loading = true;

  constructor() {}

  ngOnInit(): void {}
}
