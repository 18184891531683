import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../entidades/login/auth.service';
import { NOMBRE_APP, VERSION } from '../../../environments/environment';

export interface IHttpRequestOptions {
  headers?: HttpHeaders;
  context?: HttpContext;
  observe?: 'body' | 'events' | 'response' | any;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | any;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  public get(ruta: string, options?: IHttpRequestOptions): Observable<any> {
    return new Observable((observer) => {
      this._get(ruta, options)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
        })
        .catch((err) => {
          if (err.status === 401 && this.auth.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._get(ruta, options)
                  .then((resp) => {
                    observer.next(resp);
                    observer.complete();
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public post(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions
  ): Observable<any> {
    return new Observable((observer) => {
      this._post(ruta, body, options)
        .then((resp) => {
          observer.next(resp);
        })
        .catch((err) => {
          if (err.status === 401 && this.auth.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._post(ruta, body, options)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public put(ruta: string, body: any): Observable<any> {
    return new Observable((observer) => {
      this._put(ruta, body)
        .then((resp) => {
          observer.next(resp);
        })
        .catch((err) => {
          if (err.status === 401 && this.auth.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._put(ruta, body)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public delete(ruta: string): Observable<any> {
    return new Observable((observer) => {
      this._delete(ruta)
        .then((resp) => {
          observer.next(resp);
        })
        .catch((err) => {
          if (err.status === 401 && this.auth.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._delete(ruta)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  private _get<T>(ruta: string, options?: IHttpRequestOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      // Seteo el header de autorizacion
      const token = this.auth.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      options = options || {};
      options.headers = options.headers || new HttpHeaders();

      for (const key of headers.keys()) {
        options.headers = options.headers.append(key, headers.get(key)!);
      }

      // Request
      this.http.get<T>(ruta, options).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _post(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // Seteo el header de autorizacion
      const token = this.auth.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      options = options || {};
      options.headers = options.headers || new HttpHeaders();

      for (const key of headers.keys()) {
        options.headers = options.headers.append(key, headers.get(key)!);
      }

      const req = this.http.post<any>(ruta, body, options);
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _put(ruta: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.auth.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      const req = this.http.put<any>(ruta, body, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private _delete(ruta: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.auth.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      const req = this.http.delete<any>(ruta, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public get2(
    ruta: string,
    options?: IHttpRequestOptions,
    noCache = false
  ): Observable<any> {
    return new Observable((observer) => {
      this._get(ruta, options)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
        })
        .catch(async (err) => {
          if (
            err.error?.message === 'Invalid token: access token has expired'
          ) {
            const token = await this.auth.refreshToken();
            if (token) {
              this._get(ruta, options)
                .then((resp) => {
                  observer.next(resp);
                  observer.complete();
                })
                .catch((refreshErr) => {
                  observer.error(refreshErr);
                });
            }
          } else {
            observer.error(err);
          }
        });
    });
  }
}
