import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICliente,
  ICreateUsuario,
  IQueryParam,
  IUpdateUsuario,
  IUsuario,
  Rol,
} from 'modelos/src';
import { take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ClientesService } from '../../clientes/clientes.service';
import { UsuariosService } from '../usuarios.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-crear-editar-usuario',
  templateUrl: './crear-editar-usuario.component.html',
  styleUrls: ['./crear-editar-usuario.component.scss'],
})
export class CrearEditarUsuarioComponent implements OnInit {
  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;
  hide = true;

  public clientes: ICliente[] = [];

  public roles: Rol[] = [
    'admin',
    'operador',
    'Crear Controles',
    'veedor',
    'Enviar Notificaciones',
    'Administrador de sirenas',
  ];

  public phoneCodes = [
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: '---------------',
      dial_code: '',
      code: 'AR',
      disabled: true,
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL',
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO',
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC',
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX',
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE',
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY',
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      dial_code: '+58',
      code: 'VE',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarUsuarioComponent>,
    private service: UsuariosService,
    private helper: HelperService,
    private clientesService: ClientesService,
  ) {}

  private crearFormulario(): void {
    this.title = this.data?._id ? 'Editar Usuario' : 'Crear Usuario';
    this.formulario = this.fb.group({
      idCliente: [this.data?.idCliente],
      usuario: [this.data?.usuario, Validators.required],
      clave: [''],
      nombre: [this.data?.nombre, Validators.required],
      email: [this.data?.email, Validators.email],
      pais: [this.data?.pais],
      telefono: [this.data?.telefono],
      roles: [this.data?.roles],
      sirenasOnline: [this.data?.sirenasOnline],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const clave = this.formulario?.get('clave')?.value;

      if (this.data) {
        const data: IUpdateUsuario = {
          usuario: `${this.formulario?.get('usuario')?.value}`,
          nombre: this.formulario?.get('nombre')?.value,
          email: this.formulario?.get('email')?.value,
          pais: this.formulario?.get('pais')?.value,
          telefono: this.formulario?.get('telefono')?.value,
          roles: this.formulario?.get('roles')?.value,
          sirenasOnline: this.formulario?.get('sirenasOnline')?.value,
        };
        if (clave) data.clave = clave;
        await this.service
          .editar(this.data._id!, data)
          .pipe(take(1))
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data: ICreateUsuario = {
          usuario: this.formulario?.get('usuario')?.value,
          clave: clave,
          idCliente: this.formulario?.get('idCliente')?.value,
          nombre: this.formulario?.get('nombre')?.value,
          email: this.formulario?.get('email')?.value,
          pais: this.formulario?.get('pais')?.value,
          telefono: this.formulario?.get('telefono')?.value,
          roles: this.formulario?.get('roles')?.value,
          sirenasOnline: this.formulario?.get('sirenasOnline')?.value,
        };
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  private async listarClientes() {
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre',
    };
    this.clientes = (
      await firstValueFrom(this.clientesService.listar(query))
    ).datos;
    console.log('Listado de clientes', this.clientes);
  }

  async ngOnInit(): Promise<void> {
    this.crearFormulario();
    await this.listarClientes();
    console.log(this.roles);
  }
}
